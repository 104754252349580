import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';

import { Grid, Container, IconButton, ListSubheader } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { SttButton, SttSelect, SttMenuItem, SttModal, SttLoading } from '@stt-componentes/core';
import Fieldset from '../../../components/Fieldset/Fieldset';
import PivottableWrapper from '../../../components/Pivottable/PivottableWrapper';

import { ADICIONAR_RELATORIO, GET_RELATORIO, GET_META_ATRIBUTOS, GET_CUBES } from '../../../requests/graphql';
// styles
import useStyles from '../../../themes/styles';
import '@stt-analise/pivottable/pivottable.css';

import { TabelaDadosRules, FiltroUtil, MSGUtil, XLSXUtil, PDFUtil } from '@stt-analise/util';
import { useFormik } from 'formik';
import { FiltroSecundario } from '../../../components/Filter/FiltroSecundario';
import { SttTranslateHook } from '@stt-componentes/core';
import ExportContainer from '../../../components/ExportContainer';

import ModalSelecaoDeTabelas from '../../../components/ModalSelecaoDeTabelas';
import MaterialReactTable from '../../../components/Dashboard/Table/MaterialReactTable';
import CheckboxTreeSelect from '../../../components/CheckboxTreeSelect/CheckboxTreeSelect';
import { MSGBuilder, MSGCode } from '@stt-analise/util';
import TitleCube from '../../../components/Dashboard/Title/TitleCube';
import useReqExportar from 'src/requests/rest/useReqExportar';
import useReqPesquisar from 'src/requests/rest/useReqPesquisar';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';
import { relatorioAssinadoSignal, pesquisaSignal } from 'src/signals/tabelaDados/tabelaDadosSignal';
import { emitirAlerta } from 'src/signals/alertaSignal';
import useReqPOSTRelatorio from 'src/requests/rest/inicio/useReqPOSTRelatorio';
import TabelaDadosModalSalvarRelatorio from './TabelaDadosModalSalvarRelatorio';

const getParams = (location) => {
    const titulo = new URLSearchParams(location.search).get('titulo');
    return { titulo };
};

const normalizePayload = ({ cubo, atributos, filtros, agrupado }, metaAtributos) => {
    const nFiltros = FiltroUtil.normalizeFiltros(filtros);
    const [dimensoes, medidas] = TabelaDadosRules.normalizeDimensoesAndMedidas(cubo, atributos, metaAtributos, agrupado);
    return {
        cubo,
        atributos: dimensoes,
        filtros: nFiltros,
        medidas,
        agrupado,
    };
};

function TabelaDados() {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const location = useLocation();
    const { titulo } = getParams(location);

    const [carregarDataset, setCarregarDataset] = useState(0);
    const [pivotState, setPivotState] = useState(null);

    const [openExportModal, setOpenExportModal] = useState(false);

    const [openModalTable, setOpenModalTable] = useState(false);
    const [disabledCheckboxAgrupamento, setDisabledCheckboxAgrupamento] = useState(false);

    const [loadingExpDados, setLoadingExpDados] = useState(false);
    const referenciaTabelaNormal = useRef(null);

    // Formulário
    const formik = useFormik({
        initialValues: {
            cubo: '',
            atributosSelecionados: [],
            filtros_secundarios: [], // key, atributo, valor, tipoComponente
            tipo: TabelaDadosRules.TipoTabela.TABELA_DINAMICA,
            habilitarNumerosDeLinha: false,
            habilitarAgrupamento: false,
        },
        onSubmit: (values) => {
            const payload = normalizePayload(
                {
                    cubo: values.cubo,
                    atributos: values.atributosSelecionados,
                    filtros: values.filtros_secundarios,
                    agrupado: values.habilitarAgrupamento,
                },
                metaAtributos
            );

            executarConsulta(payload);
        },
    });
    
    // Request via graphql
    const { loading, error, data: dataGetCubes } = useQuery(GET_CUBES, { fetchPolicy: 'network-only' });
    const [requestMetaAtributos, { loading: loadingMetaAtributos, data: resultMetaAtributos }] = useLazyQuery(GET_META_ATRIBUTOS);
    const [requestRelatorio, { data: dataRelatorio, loading: loadingRelatorio }] = useLazyQuery(GET_RELATORIO, { fetchPolicy: 'network-only' });
    const [insertRelatorio, { loading: loadingInsertRelatorio }] = useMutation(ADICIONAR_RELATORIO);
    const loadedMetaAtributos = resultMetaAtributos === undefined || loadingMetaAtributos;
    const metaAtributos = resultMetaAtributos ? resultMetaAtributos['iMetaAtributosPivottable'] : [];
    const metaAtributosSelecionados = metaAtributos.filter((option) => formik.values.atributosSelecionados.includes(option.atributo));
    const hasAnexo = () => dataRelatorio && dataRelatorio.iRelatorio && dataRelatorio.iRelatorio.anexo;
    const { loading: loadingExportar, reqExportar } = useReqExportar();
    const { loading: loadingEnvioPdf, reqPostRelatorio } = useReqPOSTRelatorio();
    const { data: datasetServer, reqPesquisar, loading: loadingPesquisar, resetDataset } = useReqPesquisar();
    const possuiDados = datasetServer.length > 1;

    // Verificar se o datasetServer mudou
    const datasetServerRef = useRef(datasetServer);
    const formikValuesRef = useRef(formik.values);

    const executarConsulta = async (payload) => {
        const successCb = async () => {
            if (TabelaDadosRules.validarExecutarConsulta(payload.cubo)) {
                resetDataset();
                reqPesquisar({ payload, emitirAlerta, metaAtributos, strings });
            } else {
                const msgAlert = new MSGBuilder(MSGCode.ERR001, strings).buildMsgAlert();
                emitirAlerta(msgAlert);
            }
        };

        // Exibir mensagem de alerta para usuário que não colocou filtro.
        if (TabelaDadosRules.naoExisteFiltros(payload.filtros)) {
            const msgAlert = new MSGBuilder(MSGCode.CON001, strings).buildMsgAlert();
            emitirAlerta(msgAlert, successCb);
        } else {
            successCb();
        }
    };

    const sendRelatorio = async (htmlTable, tipo) => {
        reqPostRelatorio({
            payload: {
                cubo: formikValuesRef.current.cubo,
                titulo: pesquisaSignal.value.titulo,
                metadados: {
                    idPesquisa: pesquisaSignal.value.idPesquisa,
                    tituloImpressao: TabelaDadosRules.recuperarNomeImpressaoCubo(formikValuesRef.current.cubo),
                    nomeRelatorio: pesquisaSignal.value.titulo,
                    tipo,
                    dtUltimoProcessamento: relatorioAssinadoSignal.value.dtUltimoProcessamento,
                    dtPrincipalRotulo: relatorioAssinadoSignal.value.dtPrincipalRotulo,
                    dtPrincipalInicial: relatorioAssinadoSignal.value.dtPrincipalInicial,
                    dtPrincipalFinal: relatorioAssinadoSignal.value.dtPrincipalFinal,
                },
                htmlTable,
                versao: global.gConfig.modulo_versao,
                modulo: global.gConfig.modulo_nome,
                basename: global.gConfig.basename !== '' ? global.gConfig.basename : global.gConfig.config_id,
            },
            emitirAlerta,
            strings,
        });
    };

    const downloadFile = async (tipo) => {
        // Fluxo 1: Só é possivel exportar PDF se foi executado uma consulta
        if (tipo === 'pdf' && possuiDados) {
            if (pesquisaSignal.value.isNew) {
                emitirAlerta(new MSGBuilder(MSGCode.ERR005, strings).buildMsgAlert());
                pesquisaSignal.value = { ...pesquisaSignal.value, openModalSalvarRelatorio: true };
                return;
            }

            setLoadingExpDados(true);
            if (formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_DINAMICA) {
                // PDFUtil.gerarPDFTabelaDinamicaFrontend();
                const htmlTable = PDFUtil.gerarHTMLTabelaDinamica();
                sendRelatorio(htmlTable, 'TABELA_DINAMICA');
            } else {
                enviarEventoExportarPDF();
            }
            setLoadingExpDados(false);
            return;
        }

        // Fluxo 2: verificar a exportação via XLSX do que está sendo visualizado na Tabela Normal.
        if (tipo === 'xlsx' && possuiDados && formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_NORMAL) {
            setLoadingExpDados(true);
            enviarEventoExportarXLSX();
            setLoadingExpDados(false);
            return;
        }

        // Fluxo 3: xlsx e do tipo dinâmico, imprimir o que está na Tabela Dinâmica
        if (tipo === 'xlsx' && possuiDados && formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_DINAMICA) {
            setLoadingExpDados(true);
            XLSXUtil.exportarTabelaDinamicaParaXLSX(pesquisaSignal.value.titulo || formik.values.cubo);
            setLoadingExpDados(false);
            return;
        }

        // Fluxo 4: O resto exporta direto do backend, sem precisar de uma consulta.
        const payload = normalizePayload(
            {
                cubo: formik.values.cubo,
                atributos: formik.values.atributosSelecionados,
                filtros: formik.values.filtros_secundarios,
                agrupado: formik.values.habilitarAgrupamento,
            },
            metaAtributos
        );

        const successCb = () => {
            if (TabelaDadosRules.validarExecutarConsulta(payload.cubo)) {
                const nomeArquivo = `${pesquisaSignal.value.titulo || payload.cubo}`;
                reqExportar({ nomeArquivo, tipoArquivo: tipo, payload });
            }
        };

        // Exibir mensagem de alerta para usuário que não colocou filtro.
        if (TabelaDadosRules.naoExisteFiltros(payload.filtros)) {
            const msgAlert = new MSGBuilder(MSGCode.CON001, strings).buildMsgAlert();
            emitirAlerta(msgAlert, successCb);
        } else {
            successCb();
        }
    };

    useEffect(() => {
        formikValuesRef.current = formik.values;
    }, [formik.values]);

    /** TABELA NORMAL: POST MESSAGE */
    useEffect(() => {
        const handleMessage = (e) => {
            if (!e.origin.includes(window.location.host)) return;

            if (e.data?.action === 'ENVIAR_PDF') {
                console.log('INICIANDO O ENVIO DO PDF PARA O BACKEND');
                const _columns = e.data?.colums;
                const _rows = e.data?.rows;
                // PDFUtil.gerarPDFTabelaNormalFrontend(_columns, _rows);
                const htmlTable = PDFUtil.gerarHTMLTabelaNormal(_columns, _rows);
                sendRelatorio(htmlTable, 'TABELA_NORMAL');
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    /** RELATORIO: Carregar os metadados de um relatório salvo */
    useEffect(() => {
        if (hasAnexo()) {
            pesquisaSignal.value = {
                ...pesquisaSignal.value,
                idPesquisa: dataRelatorio.iRelatorio?._id,
                titulo: dataRelatorio.iRelatorio.titulo,
                login: dataRelatorio.iRelatorio.username,
            };
            formik.setValues({
                ...formik.values,
                _id: dataRelatorio.iRelatorio?._id || formik.values._id,
                cubo: dataRelatorio.iRelatorio.anexo.cubo || '',
                tipo: dataRelatorio.iRelatorio.tipo || TabelaDadosRules.TipoTabela.TABELA_DINAMICA,
                atributosSelecionados: dataRelatorio.iRelatorio.anexo.atributos,
                filtros_secundarios: (dataRelatorio.iRelatorio.anexo.filtros_secundarios || []).map((filtro, index) => ({ ...filtro, key: index })), // Inserir uma key: index, para todos os filtros_secundarios;
                habilitarNumerosDeLinha: dataRelatorio.iRelatorio.anexo.habilitarNumerosDeLinha,
                habilitarAgrupamento: dataRelatorio.iRelatorio.anexo.habilitarAgrupamento,
            });
            requestMetaAtributos({ variables: { cubo: dataRelatorio.iRelatorio.anexo.cubo || '' }, fetchPolicy: 'network-only' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRelatorio]);

    useEffect(() => {
        if (titulo) {
            requestRelatorio({ variables: { username: usuarioSignal.value.login, titulo }, fetchPolicy: 'network-only' });
            pesquisaSignal.value.isNew = false;
        } else {
            pesquisaSignal.value.isNew = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titulo]);

    useEffect(() => {
        if (formik.values.cubo && TabelaDadosRules.isSempreAgrupado(formik.values.cubo)) {
            formik.setValues({ ...formik.values, habilitarAgrupamento: true });
            setDisabledCheckboxAgrupamento(true);
        } else if (formik.values.cubo && !TabelaDadosRules.isSempreAgrupado(formik.values.cubo)) {
            setDisabledCheckboxAgrupamento(false);
        }
    }, [formik.values.cubo]);

    // Validar pivottable
    useEffect(() => {
        if (datasetServer === datasetServerRef.current) return;

        if (possuiDados) {
            if (hasAnexo()) {
                const { rendererName, rows, rowOrder, cols, colOrder, aggregatorList } = dataRelatorio.iRelatorio.anexo;
                let pivotStateExtend = {};
                if (rowOrder) {
                    pivotStateExtend['rowOrder'] = rowOrder;
                }
                if (colOrder) {
                    pivotStateExtend['colOrder'] = colOrder;
                }
                const pivotState = { ...pivotStateExtend, rendererName, rows: rows || [], cols: cols || [], aggregatorList: aggregatorList || [] };
                setPivotState(pivotState);
            } else {
                const { pivotState } = TabelaDadosRules.getPropsUI(formik.values.cubo);
                setPivotState({ ...pivotState });
            }
            setCarregarDataset(Date.now());
        } else {
            setCarregarDataset(0);
        }
    }, [datasetServer]);

    useEffect(() => {
        // Procurar todos os atributos que são do tipo DATE
        const [_atributo, dtInicial, dtFinal] = FiltroUtil.recuperarDataPrincipalFormatada(formik.values.filtros_secundarios);
        const _metaAtributo = metaAtributos.find((_meta) => _atributo.includes(_meta.atributo));
        relatorioAssinadoSignal.value = {
            dtPrincipalRotulo: _metaAtributo?.rotulo || _atributo,
            dtPrincipalInicial: dtInicial,
            dtPrincipalFinal: dtFinal,
        };
    }, [formik.values.filtros_secundarios, metaAtributos]);

    function handleOpen() {
        pesquisaSignal.value = { ...pesquisaSignal.value, openModalSalvarRelatorio: true };
    }

    function handleExportClose() {
        setOpenExportModal(false);
    }

    function handleTabelaClose() {
        setOpenModalTable(false);
    }

    const handleChangeCube = async (event) => {
        const cubeName = event.target.value;
        // Verificar se está mudando por outro cubo
        if (formik.values.cubo === cubeName || cubeName === dataRelatorio?.iRelatorio?.anexo?.cubo) return;
        if (cubeName === '') return;

        const { pivotState } = TabelaDadosRules.getPropsUI(cubeName);

        const filtros_secundarios = TabelaDadosRules.getDefaultFiltros(cubeName);
        formik.setValues({ ...formik.values, atributosSelecionados: [], filtros_secundarios, cubo: event.target.value });

        requestMetaAtributos({ variables: { cubo: event.target.value }, fetchPolicy: 'network-only' });
        resetDataset();

        if (pivotState) {
            setPivotState({ ...pivotState });
        } else {
            setPivotState(null);
        }
    };

    const adicionarFiltro = async () => {
        formik.setValues({
            ...formik.values,
            filtros_secundarios: [
                ...formik.values.filtros_secundarios,
                {
                    key: new Date().getTime(),
                    atributo: '',
                    disabled: false,
                    tipoComponente: '',
                    valor: '',
                },
            ],
        });
    };

    const removerFiltro = async () => {
        const novoFiltros = [...formik.values.filtros_secundarios];
        novoFiltros.pop();
        formik.setValues({
            ...formik.values,
            filtros_secundarios: novoFiltros,
        });
    };

    const handleSalvarRelatorio = async (nomeRelatorio) => {
        if (nomeRelatorio) {
            let anexo = {
                cubo: formik.values.cubo,
                atributos: formik.values.atributosSelecionados,
                filtros_secundarios: formik.values.filtros_secundarios,
                habilitarNumerosDeLinha: formik.values.habilitarNumerosDeLinha,
                habilitarAgrupamento: formik.values.habilitarAgrupamento,
            };
            if (pivotState)
                anexo = {
                    ...anexo,
                    rows: pivotState.rows,
                    rowOrder: pivotState.rowOrder,
                    cols: pivotState.cols,
                    colOrder: pivotState.colOrder,
                    rendererName: pivotState.rendererName,
                    aggregatorList: pivotState.aggregatorList?.map((agg) => ({ aggTitle: agg.aggTitle, vals: agg.vals, aggregatorName: agg.aggregatorName })),
                };

            try {
                const { data: result } = await requestRelatorio({
                    variables: { username: usuarioSignal.value.login, titulo: nomeRelatorio },
                    fetchPolicy: 'network-only',
                });
                const existeRelatorio = result['iRelatorio'] != null;

                const msgAlert = new MSGBuilder(MSGCode.CON003, strings).buildMsgAlert();
                const yesOrNo = await MSGUtil.confirmationFuncWithRule(msgAlert, !existeRelatorio, emitirAlerta);
                if (!yesOrNo) return;

                const { data: novoRelatorio } = await insertRelatorio({
                    variables: {
                        _id: dataRelatorio?.iRelatorio?._id,
                        titulo: nomeRelatorio,
                        username: usuarioSignal.value.login,
                        tipo: formik.values.tipo,
                        anexo,
                    },
                });
                emitirAlerta(new MSGBuilder(MSGCode.SUC001, strings).buildMsgAlert());
                pesquisaSignal.value = { 
                    ...pesquisaSignal.value,
                    idPesquisa: novoRelatorio?.insertRelatorio?._id,
                    titulo: nomeRelatorio, 
                    openModalSalvarRelatorio: false, 
                    isNew: false,
                };
            } catch ({ graphQLErrors, message }) {
                const msgCode = graphQLErrors[0]?.extensions.msgCode || MSGCode.ERR001;
                const msgAlert = new MSGBuilder(msgCode, strings).buildMsgAlert();
                emitirAlerta(msgAlert);
            }
        }
    };

    const selectItemsCubos = useMemo(() => {
        // 1 - RN: Cubos permitidos
        const cubos = (dataGetCubes?.iCubos && TabelaDadosRules.filtrarCubosPermitidos(dataGetCubes.iCubos, usuarioSignal.value.rbac)) || [];

        // 2 - Preparar agrupado para renderizar no Select
        const items = [];
        const agruparCubos = cubos.reduce((acc, current) => {
            if (!acc[current.grupo || strings.TabelaDados.outrosCubos]) acc[current.grupo || strings.TabelaDados.outrosCubos] = [];
            acc[current.grupo || strings.TabelaDados.outrosCubos].push(current);
            return acc;
        }, {});
        Object.keys(agruparCubos).forEach((key) => {
            items.push({ divider: true, grupo: key });
            agruparCubos[key].forEach(({ dataset, rotulo }) => {
                items.push({ divider: false, dataset, rotulo });
            });
        });

        return items;
    }, [dataGetCubes]);

    const enviarEventoExportarPDF = () => {
        if (referenciaTabelaNormal.current) {
            referenciaTabelaNormal.current.enviarEventoExportarPDF();
        }
    };

    const enviarEventoExportarXLSX = () => {
        if (referenciaTabelaNormal.current) {
            referenciaTabelaNormal.current.enviarEventoExportarXLSX();
        }
    };

    const handleClick = () => {
        setOpenExportModal(true);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TitleCube title={pesquisaSignal.value.titulo} cubo={formik.values.cubo} />
                </Grid>

                <Fieldset title={strings.Comuns.selecioneCubo} />
                <Grid item xs={12} sm={12} md={12}>
                    {!loading && !error && (
                        <SttSelect label="Cubo" value={formik?.values?.cubo || ''} onChange={handleChangeCube}>
                            {selectItemsCubos.map(({ divider, grupo, dataset, rotulo }, index) => {
                                return divider ? (
                                    <ListSubheader key={index} style={{ paddingLeft: '10px', lineHeight: '15px', paddingTop: '10px' }}>
                                        {grupo}
                                    </ListSubheader>
                                ) : (
                                    <SttMenuItem key={dataset} value={dataset}>
                                        {rotulo || dataset}
                                    </SttMenuItem>
                                );
                            })}
                        </SttSelect>
                    )}
                    {loading && <span>Carregando!</span>}
                    {error && <span>Erro!</span>}
                </Grid>

                <Fieldset title={strings.Comuns.adicioneFiltros} />
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FiltroSecundario
                        filtros={formik.values.filtros_secundarios}
                        cubo={formik.values.cubo}
                        metaAtributos={metaAtributos}
                        loadingMetaAtributos={loadingMetaAtributos}
                        onChangeFilter={(filtroAlterado) => {
                            const novoFiltrosSecundarios = formik.values.filtros_secundarios.map((filtro) => {
                                if (filtro.key == filtroAlterado.key) {
                                    return filtroAlterado;
                                }
                                return filtro;
                            });
                            formik.setValues({
                                ...formik.values,
                                filtros_secundarios: novoFiltrosSecundarios,
                            });
                        }}
                    />
                </Grid>
                <Grid container>
                    <IconButton
                        onClick={adicionarFiltro}
                        color="primary"
                        aria-label={strings.Comuns.adicionarFiltro}
                        disabled={TabelaDadosRules.disabledFilter(formik.values.cubo)}
                        component="span"
                    >
                        <AddCircle fontSize="large" />
                    </IconButton>
                    <IconButton
                        onClick={removerFiltro}
                        color="primary"
                        aria-label={strings.Comuns.removerFiltro}
                        disabled={TabelaDadosRules.disabledFilter(formik.values.cubo, formik.values.filtros_secundarios)}
                        component="span"
                    >
                        <RemoveCircle fontSize="large" />
                    </IconButton>
                </Grid>

                <Fieldset title={strings.Comuns.selecioneVariaveis} />
                <Grid item xs={12}>
                    <CheckboxTreeSelect
                        disabled={loadedMetaAtributos}
                        atributos={metaAtributos}
                        atributosEstaticos={TabelaDadosRules.getPropsUI(formik.values.cubo)?.atributosEstaticos || []}
                        checked={metaAtributosSelecionados.map((attr) => attr.atributo)}
                        disabledPrefixGrupo={TabelaDadosRules.getPropsUI(formik.values.cubo)?.disabledPrefixGrupo || false}
                        setChecked={(checked_) => {
                            formik.setValues({
                                ...formik.values,
                                atributosSelecionados: checked_,
                            });
                        }}
                    />
                </Grid>

                {/* Botões */}
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                        <Grid item>
                            <SttButton
                                disabled={formik.values.atributosSelecionados.length === 0}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenModalTable(true);
                                }}
                            >
                                {strings.Comuns.executarConsulta}
                            </SttButton>
                        </Grid>
                        <Grid item>
                            <SttButton className={classes.button} variant="contained" color="primary" onClick={handleOpen}>
                                {strings.Comuns.salvarRelatorio}
                            </SttButton>
                        </Grid>
                        <Grid item>
                            <SttButton className={classes.button} variant="contained" color="primary" onClick={handleClick}>
                                {strings.Comuns.exportar}
                            </SttButton>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Tabela resultado */}
                {!!carregarDataset && (
                    <Grid item xs={12} className={classes.pivottable} key={carregarDataset}>
                        {formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_DINAMICA && (
                            <PivottableWrapper
                                datasetServer={datasetServer}
                                metaAtributos={metaAtributos}
                                habilitarNumerosDeLinha={formik.values.habilitarNumerosDeLinha}
                                pivotState={pivotState}
                                setPivotState={setPivotState}
                            />
                        )}
                        {formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_NORMAL && (
                            <MaterialReactTable
                                ref={referenciaTabelaNormal} // Adicione esta linha
                                data={datasetServer}
                                cubo={formik.values.cubo}
                                meta={metaAtributosSelecionados}
                                titulo={pesquisaSignal.value.titulo}
                                enableRowNumbers={formik.values.habilitarNumerosDeLinha}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
            <TabelaDadosModalSalvarRelatorio handleSalvarRelatorio={handleSalvarRelatorio} nomeRelatorioInicial={pesquisaSignal.value.titulo} />
            <SttModal title={strings.Comuns.exportar} open={openExportModal} iconClose={handleExportClose} outModalClose={handleExportClose}>
                <Container maxWidth="md">
                    <ExportContainer
                        onClickExport={(tipo) => {
                            downloadFile(tipo);
                            handleExportClose();
                        }}
                        possuiDados={possuiDados}
                        pdfPermitido={TabelaDadosRules.impressaoPermitida(formik.values.cubo)}
                    />
                </Container>
            </SttModal>
            <SttModal
                className={classes.dialogContent}
                title="Escolha o tipo de visualização"
                open={openModalTable}
                iconClose={handleTabelaClose}
                outModalClose={handleTabelaClose}
            >
                <Container maxWidth="md">
                    <ModalSelecaoDeTabelas
                        tipo={formik.values.tipo}
                        habilitarNumerosDeLinha={formik.values.habilitarNumerosDeLinha}
                        habilitarAgrupamento={formik.values.habilitarAgrupamento}
                        disabledCheckboxAgrupamento={disabledCheckboxAgrupamento}
                        onClickConfirmar={(tipo, habilitarNumerosDeLinha, habilitarAgrupamento) => {
                            formik.setFieldValue('tipo', tipo);
                            formik.setFieldValue('habilitarNumerosDeLinha', habilitarNumerosDeLinha);
                            formik.setFieldValue('habilitarAgrupamento', habilitarAgrupamento);
                            formik.handleSubmit();
                            handleTabelaClose();
                        }}
                        onClickCancelar={() => {
                            handleTabelaClose();
                        }}
                    />
                </Container>
            </SttModal>
            <SttLoading
                text={strings.Comuns.carregando}
                open={
                    loadingPesquisar ||
                    loadingMetaAtributos ||
                    loadingInsertRelatorio ||
                    loadingRelatorio ||
                    loadingExportar ||
                    loadingExpDados ||
                    loadingEnvioPdf
                }
            />
        </>
    );
}

export default TabelaDados;
