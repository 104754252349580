import React, { useEffect, useRef, useState } from 'react';

// styles
import useStyles from './styles';


const SERVICOS = {
    /* -- Administrativo -- */
    ADMINISTRATIVO_INVALIDACAOEXAME: 'Administrativo;Invalidação de exame',
    ADMINISTRATIVO_PRIORIZACAOEXAME: 'Administrativo;Priorização de exame',

    /* -- Telemedicina > Solicitação de exame -- */
    TELEMEDICINA_SOLICITACAOEXAME_ECG: 'Telemedicina;Solicitação de exame;ECG',
    TELEMEDICINA_SOLICITACAOEXAME_DERMATO: 'Telemedicina;Solicitação de exame;Dermatologia',
    TELEMEDICINA_SOLICITACAOEXAME_ELETROENCEFALO: 'Telemedicina;Solicitação de exame;EEG',
    TELEMEDICINA_SOLICITACAOEXAME_ESTOMATO: 'Telemedicina;Solicitação de exame;Estomatologia',
    TELEMEDICINA_SOLICITACAOEXAME_ESPIROMETRIA: 'Telemedicina;Solicitação de exame;Espirometria',
    TELEMEDICINA_SOLICITACAOEXAME_POLISSONO: 'Telemedicina;Solicitação de exame;Polissonografia',

    /* -- Telemedicina > Envio de exame -- */
    TELEMEDICINA_ENVIOEXAME_ECG: 'Telemedicina;Envio de exame;ECG',
    TELEMEDICINA_ENVIOEXAME_DERMATO: 'Telemedicina;Envio de exame;Dermatologia',
    TELEMEDICINA_ENVIOEXAME_ELETROENCEFALO: 'Telemedicina;Envio de exame;EEG',
    TELEMEDICINA_ENVIOEXAME_ESTOMATO: 'Telemedicina;Envio de exame;Estomatologia',
    TELEMEDICINA_ENVIOEXAME_ESPIROMETRIA: 'Telemedicina;Envio de exame;Espirometria',
    TELEMEDICINA_ENVIOEXAME_POLISSONO: 'Telemedicina;Envio de exame;Polissonografia',
    /* -- Telemedicina > Emissão de laudo -- */
    TELEMEDICINA_EMISSAOLAUDO_ECG: 'Telemedicina;Emissão de laudo;ECG',
    TELEMEDICINA_EMISSAOLAUDO_DERMATO: 'Telemedicina;Emissão de laudo;Dermatologia',
    TELEMEDICINA_EMISSAOLAUDO_ELETROENCEFALO: 'Telemedicina;Emissão de laudo;EEG',
    TELEMEDICINA_EMISSAOLAUDO_ESTOMATO: 'Telemedicina;Emissão de laudo;Estomatologia',
    TELEMEDICINA_EMISSAOLAUDO_ESPIROMETRIA: 'Telemedicina;Emissão de laudo;Espirometria',
    TELEMEDICINA_EMISSAOLAUDO_POLISSONO: 'Telemedicina;Emissão de laudo;Polissonografia',

    /* -- Telessaude > Solicitação -- */
    TELESSAUDE_TELECONSULTORIA_SOLICITACAO: 'Telessaúde;Teleconsultoria;Solicitação',
    /* -- Telessaude > Regulação -- */
    TELESSAUDE_TELECONSULTORIA_REGULACAO: 'Telessaúde;Teleconsultoria;Regulação',
    /* -- Telessaude > Resposta assíncrona -- */
    TELESSAUDE_TELECONSULTORIA_RESPOSTAASSINCRONA: 'Telessaúde;Teleconsultoria;Resposta Assíncrona',
    /* -- Telessaude > Resposta síncrona -- */
    TELESSAUDE_TELECONSULTORIA_RESPOSTASINCRONA: 'Telessaúde;Teleconsultoria;Resposta Síncrona',
    /* -- Telessaude > Avaliação -- */
    TELESSAUDE_TELECONSULTORIA_AVALIACAO: 'Telessaúde;Teleconsultoria;Avaliação',
};

const makeServicos = (servicos) => {
    let listaCategoria = [], listaLegendas = [];
    for (const key in servicos) {
        if (servicos.hasOwnProperty(key)) {
            const legenda = servicos[key];
            listaCategoria.push(key);
            listaLegendas.push(legenda);
        }
    }
    return { listaCategoria, listaLegendas };
};

function Map() {
    const classes = useStyles();
    const iFrame = useRef(null);
    const [src, setSrc] = useState(null);

    useEffect(() => {
        const { listaCategoria, listaLegendas } = makeServicos(SERVICOS);
        const options = {
            base_serverurl: global.gConfig.url_base_api_monitoracao,
            lista_de_categoria: listaCategoria,
            lista_de_legendas: listaLegendas,
            abrangencia: 'SANTA_CATARINA'
        }

        const baseURLEmbed = `/iframes/monitor-gis/index.html`;
        const stringifyOptions = JSON.stringify(options);
        const src_ = baseURLEmbed + `?version=${global.gConfig?.modulo_versao}&options=` + stringifyOptions;

        setSrc(src_);
    });

    return (
        <div className={classes.mapContainer}>
            <iframe allowFullScreen={true} ref={iFrame} referrerPolicy="unsafe-url" src={src} width="100%" height="100%" />
        </div>
    );
}

export default Map;
