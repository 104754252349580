import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AlofonoIndividualSpec } from '@stt-analise/util';
import { SttTranslateHook } from '@stt-componentes/core';
import Dashboard from '../../../components/Dashboard/Dashboard';
import DashboardResultContainer from '../../../components/Dashboard/DashboardResultContainer';

const configFiltroSecundario = AlofonoIndividualSpec;

/**
 * RN - Regra de negócio para Habilitar o botão
 */
const validarFiltros = (filtros) => {
    // Pelo menos um preenchido.
    const allow = ['paciente_nome', 'paciente_cpf'];
    const countPrenchidos = filtros.filter(f => allow.includes(f.atributo)).filter(
        (filtro) =>
            (filtro.valor != '') &&
            (filtro.valor != undefined) &&
            !(typeof filtro.valor === 'string' && filtro.valor.includes('null'))
    ).length;
    return countPrenchidos == 1;
};

function AlofonoDashboardIndividual() {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [resObjMemo, setResObjMemo] = useState(null);
    const [resObj, setResObj] = useState(null);
    const [filtrosSecundarios, setFiltrosSecundarios] = useState(configFiltroSecundario.filtros);
    const [disabledButton, setDisabledButton] = useState(true);

    useEffect(() => {
        // Toda vez que mudar o filtro, resetar o cache do setResObj.
        setResObj(null);
        const valid = validarFiltros(filtrosSecundarios);
        setDisabledButton(!valid);
    }, [filtrosSecundarios]);

    /**
     * RESULTADOS
     */
    useEffect(() => {
        if (resObjMemo) setResObj({ ...resObj, ...resObjMemo });
    }, [resObjMemo]);

    const resultCharts = useMemo(() => {
        let chartView = <span></span>;
        if (resObj != null) {
            chartView = <DashboardResultContainer resObj={resObj} resultCharts={configFiltroSecundario.resultCharts} />;
        }
        return chartView;
    }, [resObj]);

    return (
        <Dashboard
            title={strings.PaginaInicial.dashboardAlofonoIndividual}
            resultCharts={resultCharts}
            onResultObj={(res) => {
                setResObjMemo(res);
            }}
            configFiltroSecundario={configFiltroSecundario}
            disabledButton={disabledButton}
            filtrosSecundarios={filtrosSecundarios}
            setFiltrosSecundarios={setFiltrosSecundarios}
        />
    );
}

export default AlofonoDashboardIndividual;
