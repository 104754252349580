import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Chip } from '@material-ui/core';
import { SttModal } from '@stt-componentes/core';
import CheckboxTreePanel from './CheckboxPanel';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        minHeight: '40px',
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),

    },
    input: {
        display: 'inline',
        minHeight: '35px',
        paddingBottom: '5px',
        paddingTop: '5px',
        color: '#aaa',
        '& input': {
            display: 'none'
        },
    },
}));

const assembleTreeNodes = (atributos = [], atributosEstaticos = []) => {
    const treeNodes = {};
    const treeResult = [];
    atributos.forEach(attr => {
        if (!treeNodes[attr.grupo]) {
            treeNodes[attr.grupo] = [];
        }
        treeNodes[attr.grupo].push({
            value: attr.atributo,
            label: attr.rotulo,
            disabled: atributosEstaticos.includes(attr.atributo) ? true : false,
        });
    })
    for (const grupo in treeNodes) {
        const nodes = treeNodes[grupo] || [];
        const disabled = nodes.some(node => node.disabled);
        treeResult.push({
            value: grupo,
            label: grupo,
            children: nodes,
            disabled,
        });
    }
    return treeResult;
};

function ChipArrayTextField({ disabled = false, atributos = [], checked = [], setChecked, onClick, atributosEstaticos, disabledPrefixGrupo = false }) {
    const classes = useStyles();

    const handleDelete = (attr) => () => {
        setChecked([...checked.filter(attr_ => attr_ !== attr)]);
    };

    const metaAtributosSelecionadosOrd = checked.map(e => atributos.find(metaAttr => metaAttr.atributo == e));

    return (
        <TextField
            variant="outlined"
            label="Variáveis"
            size="small"
            fullWidth
            disabled={disabled}
            InputProps={{
                className: classes.input,
                maxRows: 3,
                startAdornment: metaAtributosSelecionadosOrd.map(({ grupo, rotulo, atributo }) => {
                    return (
                        <Chip
                            key={atributo}
                            size="small"
                            tabIndex={-1}
                            label={disabledPrefixGrupo ? rotulo : `${grupo}: ${rotulo}`}
                            className={classes.chip}
                            onDelete={atributosEstaticos.includes(atributo) ? null : handleDelete(atributo)} // Ignorar para atributos estáticos.
                        />
                    )
                }),
            }}
            onClick={onClick}
            onKeyDown={(e) => e.preventDefault()}
        />);
}

/**
 * @param atributos Lista de objetos{rotulo, atributo, grupo} com todos as variáveis
 * @param checked Lista de string, onde cada string representa um atributo selecionado.
 * @param onChange qualquer mudança nos atributos
 */
export default function CheckboxTreeSelect({ disabled = true, checked = [], setChecked, atributos, atributosEstaticos = [], disabledPrefixGrupo = false }) {
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (disabled) return;

        // Para checkar os atributos estáticos.
        if (checked.length === 0 && atributosEstaticos.length > 0) {
            setChecked([...atributosEstaticos]);
        }
    }, [checked, atributosEstaticos])

    function handleOpen() {
        if (disabled) return;
        setOpenModal(true);
    }

    function handleClose() {
        setOpenModal(false);
    }
    return (<>
        <ChipArrayTextField disabled={disabled} atributos={atributos} checked={checked} setChecked={setChecked} onClick={handleOpen} atributosEstaticos={atributosEstaticos} disabledPrefixGrupo={disabledPrefixGrupo} />
        {!disabled && <SttModal title={'Variáveis'} open={openModal} iconClose={handleClose} outModalClose={handleClose}>
            <CheckboxTreePanel nodes={assembleTreeNodes(atributos, atributosEstaticos)} checked={checked} setChecked={setChecked} confirmButtonListener={handleClose} />
        </SttModal>}
    </>);
};
