const moment = require('moment');
const {
  default: AggConstants
} = require('../../constants/AggConstants');
const FiltroConstants = require('../../constants/FiltroConstants').default;
module.exports = {
  cubo: 'cubo_itinerario_exames',
  primeiraConsultaAutomatica: false,
  atributosSelecionados: ['__time', 'nome_cidade', 'data_exame_mes', 'data_exame_ano', 'nome_estado', 'situacao_laudo', 'data_solicitacao', 'classificacao_risco', 'data_laudo', 'nome_laudador', 'laudo_dermatose', 'rede_telemedicina', 'valido', 'qtd_acesso_restrito_medico_solicitante', 'qtd_acesso_protocolo_paciente', 'tempo_medio_pos_laudo_tecnico', 'tempo_medio_pos_laudo_paciente', 'tempo_medio_pos_laudo_solicitante', 'exame_refeito', 'id_paciente',
  // 'satistacao_geral',
  'qnt' // Sempre colocar o último atributo como métrica.
  ],
  charts: [{
    title: 'Total de exames',
    type: 'CardAbsoluteChart',
    selectDimensions: ['qnt'],
    filters: [],
    filtersRemote: [],
    transformations: []
    // Contagem do número total de exames de dermatologia que estão válidos e registrados em banco de dados. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Total de exames sem laudo',
    type: 'CardAbsoluteChart',
    selectDimensions: ['situacao_laudo', 'qnt'],
    filters: [{
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    filtersRemote: [],
    having: "Sem laudo",
    transformations: []
    // Contagem do número total de exames de dermatologia que estão válidos e registrados em banco de dados, mas que ainda não foram laudados. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Exames sem laudo',
    type: 'CardPercentageChart',
    selectDimensions: ['situacao_laudo', 'qnt'],
    filters: [{
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    filtersRemote: [],
    having: "Sem laudo",
    transformations: []
    // Dado o número de exames de dermatologia válidos registrados, traz a porcentagem destes exames que ainda não foram laudados. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Total de exames válidos com laudo',
    type: 'CardAbsoluteChart',
    selectDimensions: ['situacao_laudo', 'qnt'],
    filters: [{
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    filtersRemote: [],
    having: "Com laudo",
    transformations: []
    // Contagem do número total de exames de dermatologia que estão válidos, registrados em banco de dados e que já foram laudados. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Exames com laudo',
    type: 'CardPercentageChart',
    selectDimensions: ['situacao_laudo', 'qnt'],
    filters: [{
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    filtersRemote: [],
    having: "Com laudo",
    transformations: []
    // Dado o número de exames de dermatologia válidos registrados, traz a porcentagem destes exames que já foram laudados 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Total de exames inválidos',
    type: 'CardAbsoluteChart',
    selectDimensions: ['qnt'],
    filters: [{
      attribute: 'valido',
      filter: value => value === 'Inválido'
    }],
    filtersRemote: [],
    transformations: []
    // Contagem do número total de exames de dermatologia que estão inválidos e registrados em banco de dados. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Exames inválidos',
    type: 'CardPercentageChart',
    selectDimensions: ['valido', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Inválido",
    transformations: []
    // Dado o número total de exames de dermatologia no sistema, traz a porcentagem destes exames que estão inválidos. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Exames inválidos refeitos',
    type: 'CardAbsoluteChart',
    selectDimensions: ['exame_refeito', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Refeito",
    transformations: []
    // Contagem do número total de exames de dermatologia que foram inválidos, mas que posteriormente o registro de solicitação associado a este exame foi reutilizado para o envio de um novo exame. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Número de visualizações de laudos pelo médico solicitante',
    type: 'CardAbsoluteChart',
    selectDimensions: ['qtd_acesso_restrito_medico_solicitante'],
    filters: [{
      attribute: 'qtd_acesso_restrito_medico_solicitante',
      filter: value => value !== 'Não preenchido'
    }],
    filtersRemote: [],
    transformations: []
    // Contagem do número total de exames laudados de dermatologia que foram visualizados pelos médicos que fez a solicitação deste exame. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Número de visualizações de laudos pelo paciente',
    type: 'CardAbsoluteChart',
    selectDimensions: ['qtd_acesso_protocolo_paciente'],
    filters: [{
      attribute: 'qtd_acesso_protocolo_paciente',
      filter: value => value !== 'Não preenchido'
    }],
    filtersRemote: [],
    transformations: []
    // Contagem do número total de exames laudados de dermatologia que foram visualizados pelos pacientes associados a este exame. Este acesso é feito externamente através do protocolo do exame.
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  },
  // {
  //     title: 'Tempo médio de acesso ao laudo pelo técnico (em minutos)',
  //     type: 'CardAverageChart',
  //     selectDimensions: ['tempo_medio_pos_laudo_tecnico'],
  //     filters: [
  //         {
  //             attribute: 'tempo_medio_pos_laudo_tecnico',
  //             filter: (value) => value !== 'Não preenchido',
  //         }
  //     ],
  //     filtersRemote: [],
  //     transformations: [],
  //     // Traz o tempo médio (em minutos) que os usuários com perfil "técnico" levam para acessar (a primeira vez) um exame de dermatologia laudado. 
  //     // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  // },
  {
    title: 'Tempo médio de acesso ao laudo pelo paciente (em minutos)',
    type: 'CardAverageChart',
    selectDimensions: ['tempo_medio_pos_laudo_paciente'],
    filters: [{
      attribute: 'tempo_medio_pos_laudo_paciente',
      filter: value => value !== 'Não preenchido'
    }],
    filtersRemote: [],
    transformations: []
    // Traz o tempo médio (em minutos) que os pacientes levam para acessar (a primeira vez) um exame de dermatologia laudado. Este acesso é feito externamente através do protocolo do exame.
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Tempo médio de acesso ao laudo pelo médico solicitante (em minutos)',
    type: 'CardAverageChart',
    selectDimensions: ['tempo_medio_pos_laudo_solicitante'],
    filters: [{
      attribute: 'tempo_medio_pos_laudo_solicitante',
      filter: value => value !== 'Não preenchido'
    }],
    filtersRemote: [],
    transformations: []
    // Traz o tempo médio (em minutos) que os usuários com perfil "médico solicitante" levam para acessar (a primeira vez) um exame de dermatologia laudado. 
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Situação do exame por ano',
    type: 'ApexColumnChart',
    w: 2,
    selectDimensions: ['situacao_laudo', 'data_exame_ano', 'qnt'],
    filters: [{
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    filtersRemote: [],
    transformations: [],
    uiConfig: {
      enableAnimation: true,
      totalDisplayState: "parts",
      orderDirection: 'asc',
      orderType: 'name' // 'name' | 'count'
    }
    // Traz um gráfico em colunas mostrando a situação dos exames de dermatologia por ano. Uma barra contém a contagem de exames "sem laudo" e a outra barra traz a contagem de exames "com laudo".
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Laudos por rede',
    type: 'ApexPieChart',
    groupByOp: 'sum',
    topGroupLimit: 10,
    topGroupTextOther: 'Outros',
    selectDimensions: ['rede_telemedicina', 'qnt'],
    filters: [{
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    filtersRemote: [],
    transformations: [],
    uiConfig: {
      enableAnimation: true
    }
    // Traz um gráfico em pizza mostrando a quantidade de laudos de exames de dermatologia  emitidos para cada uma das redes de telemedicina.
    // Este gráfico limita a exibição desta contagem em até 10 redes de telemedicina, exibindo as que mais possuem laudos emitidos. As demais são agrupados na categoria "outros".
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Laudos por dermatologista',
    type: 'ApexPieChart',
    groupByOp: 'sum',
    topGroupLimit: 10,
    topGroupTextOther: 'Outros',
    selectDimensions: ['nome_laudador', 'qnt'],
    filters: [{
      attribute: 'nome_laudador',
      filter: value => value !== 'Não preenchido'
    }, {
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    filtersRemote: [],
    transformations: [],
    uiConfig: {
      enableAnimation: true
    }
    // Traz um gráfico em pizza mostrando a quantidade de laudos de exames de dermatologia emitidos por cada um dos médicos laudadores do sistema.
    // Este gráfico limita a exibição desta contagem em até 10 laudadores, exibindo os que mais possuem laudos emitidos. Os demais médicos laudadores são agrupados na categoria "outros".
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Lesões laudadas por tipo de dermatose',
    type: 'ApexPieChart',
    groupByOp: 'sum',
    selectDimensions: ['laudo_dermatose', 'qnt'],
    filters: [{
      attribute: 'laudo_dermatose',
      filter: value => value !== 'Não preenchido'
    }, {
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    flatMap: {
      dimension: "laudo_dermatose",
      flatFn: laudo_dermatose => laudo_dermatose.split(', ')
    },
    filtersRemote: [],
    transformations: [],
    uiConfig: {
      enableAnimation: true
    }
    // Traz um gráfico em pizza mostrando a quantidade de lesões laudadas de exames de dermatologia emitidos para cada um dos diferentes tipos de dermatose.
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Lesões laudadas por paciente',
    type: 'ApexPieChart',
    filters: [{
      attribute: 'laudo_dermatose',
      filter: value => value !== 'Não preenchido'
    }, {
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    selectDimensions: ['laudo_dermatose', 'id_paciente'],
    flatMap: {
      dimension: "laudo_dermatose",
      flatFn: laudo_dermatose => laudo_dermatose.split(', ')
    },
    groupByV2: {
      alias: 'total_pacientes_lesoes',
      attribute: 'id_paciente',
      operation: 'distinct-count' // 'sum' | 'distinct-count' | max | min | avg
    },
    uiConfig: {
      enableAnimation: true
    }
  }, {
    title: 'Lesões laudadas por classificação de risco',
    type: 'ApexPieChart',
    filters: [{
      attribute: 'classificacao_risco',
      filter: value => value !== 'Não preenchido'
    }, {
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    flatMap: {
      dimension: "classificacao_risco",
      flatFn: classificacao_risco => classificacao_risco.split(', ')
    },
    selectDimensions: ['classificacao_risco', 'qnt'],
    groupByV2: {
      alias: 'total_exames',
      attribute: 'qnt',
      operation: 'sum' // 'sum' | 'distinct-count' | max | min | avg
    },
    uiConfig: {
      enableAnimation: true,
      colorLabel: labels => {
        return labels.map(l => {
          switch (l) {
            case 'Amarela':
              return '#fed819';
            case 'Vermelha':
              return '#d40f2b';
            case 'Azul':
              return '#008ffb';
            case 'Verde':
              return '#00c783';
            case 'Branca':
              return '#b0aeb5';
            default:
              return '#000';
          }
        });
      }
    }
    // Traz um gráfico em pizza mostrando a quantidade de laudos de exames de dermatologia emitidos para cada uma das diferentes classificações de risco (verde, vermelho, branca, azul, amarelo).
    // Este card pode ter seus dados filtrados pela variável "Data do exame" presente no dashboard.
  }, {
    title: 'Classificação de risco por paciente',
    type: 'ApexPieChart',
    filters: [{
      attribute: 'classificacao_risco',
      filter: value => value !== 'Não preenchido'
    }, {
      attribute: 'valido',
      filter: value => value === 'Válido'
    }],
    selectDimensions: ['classificacao_risco', 'id_paciente'],
    flatMap: {
      dimension: "classificacao_risco",
      flatFn: classificacao_risco => classificacao_risco.split(', ')
    },
    groupByV2: {
      alias: 'total_pacientes_classificacao_risco',
      attribute: 'id_paciente',
      operation: 'distinct-count' // 'sum' | 'distinct-count' | max | min | avg
    },
    uiConfig: {
      enableAnimation: true,
      colorLabel: labels => {
        return labels.map(l => {
          switch (l) {
            case 'Amarela':
              return '#fed819';
            case 'Vermelha':
              return '#d40f2b';
            case 'Azul':
              return '#008ffb';
            case 'Verde':
              return '#00c783';
            case 'Branca':
              return '#b0aeb5';
            default:
              return '#000';
          }
        });
      }
    }
  }
  // {
  //     title: 'Satisfação do solicitante',
  //     type: 'ApexPieChart',
  //     enableAnimation: true,
  //     selectDimensions: ['satistacao_geral', 'qnt'],
  //     groupByOp: 'sum',
  //     filters: [
  //         {
  //             attribute: 'satistacao_geral',
  //             filter: (value) => value !== 'Não preenchido',
  //         },
  //         {
  //             attribute: 'valido',
  //             filter: (value) => value === 'Válido',
  //         }
  //     ],
  //     filtersRemote: [],
  //     transformations: [],
  //     // Este gráfico mostra a satisfação do solicitante em forma de gráfico de pizza. Está baseado nas respostas dadas no encaminhamento.
  // },
  ],
  metaAtributos: [{
    atributo: '__time',
    grupo: '0.0 - Variáveis gerais',
    key: 'cubo_itinerario_exames_variaveis_gerais___time',
    rotulo: 'Data do exame',
    tipo: 'DATA_HORA'
  }, {
    atributo: 'data_laudo',
    grupo: '0.0 - Variáveis gerais',
    key: 'cubo_itinerario_exames_variaveis_gerais_data_laudo',
    rotulo: 'Data do laudo',
    tipo: 'TIMESTAMP_MS'
  }, {
    atributo: 'rede_telemedicina',
    grupo: '0.1 - Rede de telemedicina',
    key: 'cubo_itinerario_exames_variaveis_gerais_rede_telemedicina',
    rotulo: 'Rede de telemedicina',
    tipo: 'TEXTO'
  }],
  filtros: [{
    key: 1,
    disabled: false,
    atributosPermitidos: ['data_laudo', '__time'],
    atributo: '__time',
    tipoComponente: 'BETWEEN_INCLUSIVE_TIMESTAMP_MS',
    valor: () => {
      const end = moment().toDate();
      const start = moment().subtract(6, 'month').toDate();
      return [start, end];
    }
  }, {
    key: 2,
    disabled: true,
    atributo: 'rede_telemedicina',
    rotulo: 'Rede de telemedicina',
    tipoComponente: 'IN_SELECT',
    valor: ''
  }]
};