import _ from 'lodash';
const StringMask = require('string-mask');
const formatterNumber = new StringMask('#.##0', {
  reverse: true
});
const formatterDecimal = new StringMask('#.##0,00', {
  reverse: true
});
/**
 * Somatório de uma métrica de uma dimensão.
 *
 * @param datasetDruid Array de uma dimensão que possibilita a soma.
 * @returns Valor númerico com a soma dos dados.
 */
const sumOneDim = datasetDruid => {
  return datasetDruid.reduce((acc, ell) => Number(ell[0]) + acc, 0);
};
/**
 * Somatório de dados considerando a primeira dimensão o valor na qual o dado será filtrado.
 *
 * @param datasetDruid Array de duas dimensões
 * @param having valor do filtro implicito na soma ou porcentagem dos dados.
 * @returns somatório do valor filtrado (sum) e o somatório do valor total sem filtro (total).
 */
const sumTwoDim = function (datasetDruid, having) {
  let offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const strIndex = offset + 0;
  const metricIndex = offset + 1;
  let funcCompare = _str => _str === having;
  if (!Array.isArray(having) && having.substring(having.length - 1) === '%') {
    funcCompare = _str => _str.includes(having.replace('%', ''));
  } else if (Array.isArray(having)) {
    funcCompare = _str => having.includes(_str);
  } else if (!having) {
    // se não existe having, somar tudo.
    funcCompare = _str => true;
  }
  const sum = datasetDruid.reduce((acc, ell) => typeof ell[strIndex] === 'string' && funcCompare(ell[strIndex].toString()) ? Number(ell[metricIndex]) + acc : acc, 0);
  const total = datasetDruid.reduce((acc, ell) => Number(ell[metricIndex]) + acc, 0);
  return {
    sum,
    total
  };
};
/**
 * Aceita até duas dimensões (duas colunas). Sendo a última dimensão uma métrica.
 * 1 - dimensão texto
 * 2 - dimensão númerico (métrica)
 *
 * @param datasetDruid
 * @returns valor formato para ser exibido.
 */
const toValorAbsoluto = function (datasetDruid) {
  let having = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  const headers = datasetDruid.shift();
  if (headers && headers.length === 1) {
    const sum = sumOneDim(datasetDruid);
    if (sum === 0) {
      return '0';
    }
    return formatterNumber.apply(sum);
  } else if (headers && headers.length === 2) {
    const {
      sum
    } = sumTwoDim(datasetDruid, having);
    if (sum === 0) {
      return '0';
    }
    return formatterNumber.apply(sum);
  } else if (headers && headers.length === 3) {
    // Para 3 dimensões, utilizar as duas últimas dimensões
    const {
      sum
    } = sumTwoDim(datasetDruid, having, 1);
    if (sum === 0) {
      return '0';
    }
    return formatterNumber.apply(sum);
  } else {
    return new Error('Dataset não suportado.');
  }
};
const toPorcentagem = function (datasetDruid) {
  let having = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  const headers = datasetDruid.shift();
  if (headers && headers.length === 1) {
    return '100%';
  } else if (headers && headers.length === 2) {
    const {
      sum,
      total
    } = sumTwoDim(datasetDruid, having);
    const percentagem = sum / total * 100;
    return `${percentagem.toFixed(2)}%`.replace('.', ',');
  } else if (headers && headers.length === 3) {
    // Para 3 dimensões, utilizar as duas últimas dimensões
    const {
      sum,
      total
    } = sumTwoDim(datasetDruid, having, 1);
    const percentagem = sum / total * 100;
    return `${percentagem.toFixed(2)}%`.replace('.', ',');
  } else {
    return new Error('Dataset não suportado.');
  }
};
/**
 * Aceita até duas dimensões (duas colunas). Sendo a última dimensão uma métrica.
 * 1 - dimensão texto
 * 2 - dimensão númerico (métrica)
 *
 * @param datasetDruid
 * @returns valor formato para ser exibido.
 */
const toAverage = datasetDruid => {
  const headers = datasetDruid.shift();
  if (headers && headers.length === 1) {
    const div = sumOneDim(datasetDruid) / datasetDruid.length;
    if (isNaN(div)) {
      return 'Não disponível';
    }
    // Multipla por 100 pois o formatador precisa de número inteiros para funcionar corretamente
    const fixed = Number(div.toFixed(2)) * 100;
    if (fixed === 0) {
      return '0';
    }
    return formatterDecimal.apply(fixed.toFixed(0));
  } else {
    return new Error('Dataset não suportado.');
  }
};
/**
 * Aceita apenas uma dimensão. Não precisa ser métrica.
 *
 * @param datasetDruid
 * @returns valor formato para ser exibido.
 */
const toDistincCount = datasetDruid => {
  const headers = datasetDruid.shift();
  if (headers && headers.length === 1) {
    const normalizeDataset = datasetDruid.map(e => e[0]);
    if (normalizeDataset.length === 0) {
      return 0;
    }
    const distCount = _.uniq(normalizeDataset).length;
    return formatterNumber.apply(distCount);
  } else {
    return new Error('Dataset não suportado.');
  }
};
export default {
  toValorAbsoluto,
  toPorcentagem,
  toDistincCount,
  toAverage
};