'use strict';

const MdlRbac = require('../modelos/rbac');
const VINCULO_ESTADUAL = 'E';
const VINCULO_LOCAL = 'I';
const Rbac = {
  temPerfil: async (usuario, perfil) => {
    return usuario.perfisRBAC.some(p => {
      return p.identificador === perfil;
    });
  },
  temPermissao: async (usuario, permissao) => {
    if (usuario && usuario.permissoesRBAC) {
      return usuario.permissoesRBAC.includes(permissao);
    }
  },
  /**
   * Função que retorna se o usuário tem algum perfil a nível estadual relacionado ao módulo em questão (Teleconsultorias)
   * @param {*} perfis lista de perfis RBAC do usuário.
   */
  possuiVinculoEstadual: async function () {
    let perfis = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let modulosAcesso = arguments.length > 1 ? arguments[1] : undefined;
    let knex = arguments.length > 2 ? arguments[2] : undefined;
    let perfisEstaduais = perfis.filter(p => p.vinculo === VINCULO_ESTADUAL);
    if (perfisEstaduais.length === 0) {
      return false;
    }
    perfisEstaduais = perfisEstaduais.map(p => p.identificador);
    const resultadoContagem = await MdlRbac.contarPerfisVinculoModulo(perfisEstaduais, modulosAcesso, knex);
    const {
      count
    } = resultadoContagem[0];
    return parseInt(count) > 0;
  },
  buscarEstadosPerfisVinculoModulo: async (idUsuario, modulosAcesso, knex) => {
    return await MdlRbac.buscarEstadosPerfisVinculoModulo(idUsuario, modulosAcesso, knex);
  },
  /**
   * Função que retorna se o usuário tem algum perfil a nível estadual relacionado ao módulo em questão (Teleconsultorias)
   * @param {*} perfis lista de perfis RBAC do usuário.
   */
  possuiVinculoLocal: async function () {
    let perfis = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let modulosAcesso = arguments.length > 1 ? arguments[1] : undefined;
    let knex = arguments.length > 2 ? arguments[2] : undefined;
    let perfisLocais = perfis.filter(p => p.vinculo === VINCULO_LOCAL);
    if (perfisLocais.length === 0) {
      return false;
    }
    perfisLocais = perfisLocais.map(p => p.identificador);
    const resultadoContagem = await MdlRbac.contarPerfisVinculoModulo(perfisLocais, modulosAcesso, knex);
    const {
      count
    } = resultadoContagem[0];
    return parseInt(count) > 0;
  },
  buscarLocalPerfisVinculoModulo: async (idUsuario, modulosAcesso, knex) => {
    return await MdlRbac.buscarLocalPerfisVinculoModulo(idUsuario, modulosAcesso, knex);
  },
  buscarLocalPerfisVinculoModuloPerfil: async (idUsuario, modulosAcesso, identificadorPerfil, knex) => {
    return await MdlRbac.buscarLocalPerfisVinculoModuloPerfil(idUsuario, modulosAcesso, identificadorPerfil, knex);
  }
};
module.exports = Rbac;