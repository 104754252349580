import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import PDFUtil from './PDFUtil';
const gerarDocJSPDF = tableElement => {
  if (!tableElement) {
    throw new Error('Elemento da tabela não encontrado');
  }
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: 'a4'
  });
  const totalPagesExp = '{total_pages_count_string}';
  doc.setFont('helvetica');
  doc.setFontSize(9);
  doc.setDrawColor(50, 54, 57);
  const larguraPagina = doc.internal.pageSize.getWidth();
  const alturaPagina = doc.internal.pageSize.getHeight();
  const margem = 20;
  autoTable(doc, {
    html: tableElement,
    useCss: true,
    margin: {
      top: 160,
      right: margem,
      bottom: 40,
      left: margem
    },
    horizontalPageBreak: true,
    horizontalPageBreakRepeat: 0,
    didDrawPage: data => {
      // Rodapé
      doc.setFontSize(10);
      // Paginação
      let textoRodape = 'Página ' + data.pageNumber;
      if (typeof doc.putTotalPages === 'function') {
        textoRodape = textoRodape + ' de ' + totalPagesExp;
      }
      doc.text(textoRodape, larguraPagina - margem - 100, alturaPagina - 10);
    }
  });
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }
  return doc;
};
const exportarFrontend = () => {
  const tableHtml = document.querySelector('td.pvtOutput');
  const doc = gerarDocJSPDF(tableHtml);
  doc.save('tabela-de-dados.pdf');
};
const exportarBackend = htmlString => {
  const dom = new JSDOM(htmlString);
  global.window = dom.window;
  global.document = window.document;
  global.Node = window.Node;
  const tableElement = document.querySelector('table.pvtTable');
  const doc = gerarDocJSPDF(tableElement);
  return doc.output('arraybuffer');
};
export default {
  exportarFrontend,
  exportarBackend
};