import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import useStyles from './MaterialReactTableStyle';

const MaterialReactTable = forwardRef((props, ref) => {
    const { data, meta, enableRowNumbers, cubo, titulo } = props;
    const classes = useStyles();
    const iFrame = useRef(null);

    useEffect(() => {
        if (iFrame?.current?.contentWindow) {
            const header = Array.isArray(data) ? data[0] || [] : [];
            const nMeta = (meta || []).sort((a, b) => {
                return header.indexOf(a?.rotulo) - header.indexOf(b?.rotulo);
            });

            const metaJSON = JSON.stringify(nMeta);
            iFrame.current.contentWindow.PAYLOAD = {
                data,
                meta: metaJSON,
                enableRowNumbers,
                cubo: cubo,
                titulo: titulo,
            };
            iFrame.current.contentWindow.NODE_ENV = 'production';
        }
    }, [data, meta, enableRowNumbers, cubo, titulo]);

    useImperativeHandle(ref, () => ({
        enviarEventoExportarPDF: () => {
            const iFrameRef = iFrame.current;
            if (iFrameRef?.contentWindow) {
                iFrameRef.contentWindow.postMessage(
                    {
                        action: 'EXPORTAR_PDF',
                    },
                    '*'
                );
            }
        },

        enviarEventoExportarXLSX: () => {
            const iFrameRef = iFrame.current;
            if (iFrameRef?.contentWindow) {
                iFrameRef.contentWindow.postMessage(
                    {
                        action: 'EXPORTAR_XLSX',
                    },
                    '*'
                );
            }
        },
    }));

    const totalLinesStyle = {
        color: '#2a3f6f',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        lineHeight: '1.375rem',
        textAlign: 'right',
        paddingRight: '20px',
    };

    return (
        <div>
            <div style={totalLinesStyle}>Total de Linhas: {data.length - 1}</div>
            <iframe
                className={classes.iFrameMaterialReactTableContainer}
                allowFullScreen={true}
                ref={iFrame}
                referrerPolicy="unsafe-url"
                src={`/iframes/analise-table/index.html?version=${global.gConfig?.modulo_versao}`}
                width="100%"
                height="100%"
                id="iframeid"
            />
        </div>
    );
});

export default MaterialReactTable;
