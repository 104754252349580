import axios from 'axios';

/**
 * @deprecated Não será mais utilizado esse padrão de requisição. Adotar o padrão useReq<NomeRequisicao>.
 */
export default (config) => {
    const BACKEND_ALOFONO_LAMBDA_URL = config.url_base_alofono_lambda;

    const aplicarAlgoritmoTdr = async (data) => axios.post(`${BACKEND_ALOFONO_LAMBDA_URL}/tdr`, data);

    return { aplicarAlgoritmoTdr };
};
