/**
 * Função de referencia
 * https://www.researchgate.net/figure/A-Basic-sigmoid-function-with-two-parameters-c1-and-c2-as-commonly-used-for-subitizing_fig2_325868989
 * 
 * histograma: https://chart-studio.plotly.com/~Bennyweasl/6/distplot-with-normal-distribution/#plot
 * tickformattings: https://plotly.com/javascript/tick-formatting/
 * Custom axes: https://plotly.com/javascript/axes/
 */
import React, { useEffect, useRef, useState, useContext } from 'react';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import Request from '../../../requests/rest';
import { ConvertPsicometricaChart, FormatNumber } from '@stt-analise/util';
import { SttLoading } from '@stt-componentes/core';
import { SttTranslateHook } from '@stt-componentes/core';

const MIN = -20;
const MAX = 5.5;

const createFuncSigmoid = (c1, c2) => {
    return (x) => {
        return 1 / (1 + Math.exp(-c1 * (x - c2)));
    };
}

const mountData = (c1 = 1, c2 = -9) => {
    const x = _.range(MIN, MAX + 0.1, 0.1);

    const func = createFuncSigmoid(c1, c2);
    let y = [];
    for (let index = 0; index < x.length; index++) {
        y.push(func(x[index]) * 1);
    }
    return [x, y];
};

const [x_error, y_error] = mountData(0.75, -10); // y1 = 100/(1+exp(-0,75*(x+10)))
const [x_alert, y_alert] = mountData(0.75, -7); // y2 = 100/(1+exp(-0,75*(x+7)))

const dataBackgroud = [
    {
        x: [-20, MAX],
        y: [1, 1],
        type: 'scatter',
        mode: 'lines',
        fill: 'tonexty',
        fillcolor: 'rgba(0, 0, 0, 0)',
        marker: { color: 'rgba(0, 0, 0, 0)' },
        showlegend: false,
        name: 'Limiar de sucesso',
    },
    {
        x: x_error,
        y: y_error,
        type: 'scatter',
        mode: 'lines',
        fill: 'tonexty',
        fillcolor: 'rgba(0, 255, 0, 0.3)',
        marker: { color: 'rgba(0, 0, 0, 0)' },
        showlegend: false,
    },
    {
        x: x_alert,
        y: y_alert,
        type: 'scatter',
        mode: 'lines',
        fill: 'tonexty',
        fillcolor: 'rgba(231, 223, 26, 0.4)',
        marker: { color: 'rgba(0, 0, 0, 0)' },
        showlegend: false,
    },
    {
        x: [-20, MAX],
        y: [0, 0],
        type: 'scatter',
        mode: 'lines',
        fill: 'tonexty',
        fillcolor: 'rgba(191, 95, 63, 0.8)',
        marker: { color: 'rgba(0, 0, 0, 0)' },
        showlegend: false,
    },
];

const PsicometricaChart = ({ dataset, showLegend = false, agg, onResult = () => { } }) => {
    const ref = useRef(null);
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(dataBackgroud);
    const [annotations, setAnnotattions] = useState([]);
    const [width, setWidth] = useState(700);

    // Responsividade ao componente SVG.
    useEffect(() => {
        function handleResize() {
            if (ref.current.parentElement) {
                setWidth(ref.current.parentElement.offsetWidth)
            }
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (dataset.length <= 1) return;
        setLoading(true);

        // https://axios-http.com/docs/post_example multiple Promises
        async function fetchAll() {
            const { dataInner } = await ConvertPsicometricaChart.toAll(dataset, Request(global.gConfig).aplicarAlgoritmoTdr);
            setData([...data, ...dataInner]);
        }

        async function fetchGroups() {
            const { dataInner } = await ConvertPsicometricaChart.toGroups(dataset, Request(global.gConfig).aplicarAlgoritmoTdr);
            setData([...data, ...dataInner]);
        }

        async function fetchOne() {
            const { dataInner, dataRes, r_dataRes } = await ConvertPsicometricaChart.toOne(dataset, Request(global.gConfig).aplicarAlgoritmoTdr);
            setData([...data, ...dataInner]);
            const _annotations = [];
            let minThreshold = dataRes["threshold"];
            _annotations.push({
                x: dataRes["xThreshold"],
                y: dataRes["yThreshold"],
                xref: 'x',
                yref: 'y',
                text: `${FormatNumber.formatDouble(dataRes["threshold"])} dB`,
                showarrow: true,
                arrowhead: 7,
                ax: 0,
                ay: -30
            });
            if (r_dataRes) {
                _annotations.push({
                    x: r_dataRes["xThreshold"],
                    y: r_dataRes["yThreshold"],
                    xref: 'x',
                    yref: 'y',
                    text: `${FormatNumber.formatDouble(r_dataRes["threshold"])} dB`,
                    showarrow: true,
                    arrowhead: 7,
                    ax: 0,
                    ay: -70
                });
                minThreshold = dataRes["threshold"] < r_dataRes["threshold"] ? r_dataRes["threshold"] : dataRes["threshold"];
            }
            setAnnotattions(_annotations);
            onResult(minThreshold);
        }

        const finallyFn = () => setLoading(false);
        if (agg == 'sum') {
            fetchOne().finally(finallyFn);
        } else if (agg == 'group') {
            fetchGroups().finally(finallyFn);
        } else {
            fetchAll().finally(finallyFn);
        }

        return () => {
            finallyFn();
        };
    }, [dataset]);

    return (<div ref={ref} style={{ width: "100%", height: "100%" }} >
        <Plot

            data={data}
            layout={{
                editable: false, margin: {
                    // l: 0,
                    // r: 0,
                    b: 50,
                    t: 25,
                    // pad: 0
                }, annotations, displayModeBar: false, width, height: 400, title: false, showlegend: showLegend, yaxis: { title: 'Inteligibilidade [%]', tickformat: ",.0%" }, xaxis: { zeroline: false, range: [-20, 4], domain: [-20, 4], title: 'SNR [dB]', tick0: 0, dtick: 2, tickformat: ".2f" }
            }}
        />
        <SttLoading text={strings.Comuns.carregando} open={loading} />
    </div>)
};

export default PsicometricaChart;
