import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    MaterialReactTableContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: -theme.spacing(1) * 3,
        color: '#2a3f6f',
    },
    iFrameMaterialReactTableContainer: {
        border: 0,
        minHeight: '29vw',
    },
}));
