import _ from 'lodash';
import removeAccents from 'remove-accents';
const fromSnakeCaseToCamelCase = value => {
  return _.camelCase(value);
};
/**
 * Formatar texto para busca por palavras dentro do componente TreeSelect.
 */
const formatTreeSelect = value => {
  if (!value) return false;
  return removeAccents(value).trim().toLocaleLowerCase();
};
export default {
  fromSnakeCaseToCamelCase,
  formatTreeSelect
};