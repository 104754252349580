'use strict';

const LogAplicacao = {
  tabela: 'stt.log_aplicacao',
  inserir: async function (dbClient) {
    let transactionHandler = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let dados = arguments.length > 2 ? arguments[2] : undefined;
    const {
      codigo,
      evento,
      perfis = null,
      url,
      prioridade,
      arquivo,
      linha
    } = dados;
    let insert = dbClient.insert({
      codigo: codigo,
      evento: evento,
      lista_perfis: perfis,
      url: url,
      prioridade: prioridade,
      arquivo: arquivo,
      linha: linha
    }).into(LogAplicacao.tabela);
    if (transactionHandler) {
      insert = insert.transacting(transactionHandler);
    }
    await insert;
  }
};
module.exports = LogAplicacao;