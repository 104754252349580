import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TabelaDadosRules } from '@stt-analise/util';
import { Grid, Button, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel, Checkbox } from '@material-ui/core';
import { SttTranslateHook } from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
    button: {
        margin: theme.spacing(1),
        textTransform: 'none',
    },
    title: {
        zIndex: 1,
    },
    fieldRadio: {
        marginLeft: '1.5em',
    },
    fieldCheckbox: {
        marginLeft: 'calc(1.5em - 11px)',
    },
}));

const ModalSelecaoDeTabelas = ({
    onClickConfirmar,
    onClickCancelar,
    tipo,
    habilitarNumerosDeLinha,
    habilitarAgrupamento,
    disabledCheckboxAgrupamento = false,
}) => {
    const classes = useStyles();
    const [iTipo, setTipo] = useState(tipo);
    const [iHabilitarNumerosDeLinha, setHabilitarNumerosDeLinhas] = useState(true);
    const [iHabilitarAgrupamento, setHabilitarAgrupamento] = useState(habilitarAgrupamento);
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const handleChangeTipo = (event) => {
        const newValue = event.target.value;
        setTipo(newValue);
    };

    const handleChangeAgrupamento = (event) => {
        setHabilitarAgrupamento(event.target.checked);
    };

    const handleChangeNumerosDeLinhas = (event) => {
        setHabilitarNumerosDeLinhas(event.target.checked);
    };

    const handleClickConfirmar = () => {
        onClickConfirmar(iTipo, iHabilitarNumerosDeLinha, iHabilitarAgrupamento);
    };

    const handleClickCancelar = () => {
        onClickCancelar();
        setTipo(tipo);
        setHabilitarNumerosDeLinhas(habilitarNumerosDeLinha);
        setHabilitarAgrupamento(habilitarAgrupamento);
    };

    return (
        <Grid container spacing={0} justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item xs={12}>
                <Grid container direction="column">
                    <FormControl component="fieldset">
                        <FormLabel className={classes.title} component="legend">
                            Tipo de tabela
                        </FormLabel>
                        <RadioGroup className={classes.fieldRadio} row aria-label="tipo" name="tipo1" value={iTipo} onChange={handleChangeTipo}>
                            <FormControlLabel value={TabelaDadosRules.TipoTabela.TABELA_NORMAL} control={<Radio />} label={strings.Comuns.tabelaNormal} />
                            <FormControlLabel value={TabelaDadosRules.TipoTabela.TABELA_DINAMICA} control={<Radio />} label={strings.Comuns.tabelaDinamica} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="column">
                    <FormControl component="fieldset">
                        <FormLabel className={classes.title} component="legend">
                            Opções
                        </FormLabel>
                        <FormControlLabel
                            className={classes.fieldCheckbox}
                            control={
                                <Checkbox
                                    size="small"
                                    disabled={disabledCheckboxAgrupamento}
                                    checked={iHabilitarAgrupamento}
                                    onChange={handleChangeAgrupamento}
                                />
                            }
                            label="Habilitar agrupamento"
                        />
                        <FormControlLabel
                            className={classes.fieldCheckbox}
                            control={<Checkbox size="small" checked={iHabilitarNumerosDeLinha} onChange={handleChangeNumerosDeLinhas} disabled />}
                            label="Habilitar numeração das linhas"
                        />
                    </FormControl>
                </Grid>
            </Grid> 

            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <div>
                        <Button variant="contained" color="primary" onClick={handleClickConfirmar} className={classes.button}>
                            Confirmar
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleClickCancelar} className={classes.button}>
                            Cancelar
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ModalSelecaoDeTabelas;
