/* eslint-disable no-magic-numbers */
'use strict';

const Instituicao = {
  buscarDadosServicosMonitorados: async (dbClient, id) => {
    const resultado = await dbClient.select('i.cnes', 'i.nome', 'i.latitude AS latitude', 'i.longitude AS longitude', 'c.nome AS nome_municipio', 'c.latitude AS latitude_cidade', 'c.longitude AS longitude_cidade', 'c.codigo_ibge AS codigo_ibge_cidade', 'e.sigla AS sigla_uf').from('public.instituicao AS i').join('public.cidade AS c', 'i.id_cidade', '=', 'c.id').join('public.estado AS e', 'c.id_estado', '=', 'e.id').where('i.id', '=', id);
    if (resultado.length > 0) {
      return resultado[0];
    }
    return null;
  },
  buscarPorCnes: async (dbClient, cnes) => {
    const resultado = await dbClient.select('i.id', 'i.cnes', 'i.nome', 'i.latitude AS latitude', 'i.longitude AS longitude', 'c.nome AS nome_municipio', 'c.latitude AS latitude_cidade', 'c.longitude AS longitude_cidade', 'c.codigo_ibge AS codigo_ibge_cidade', 'e.sigla AS sigla_estado').from('public.instituicao AS i').join('public.cidade AS c', 'i.id_cidade', '=', 'c.id').join('public.estado AS e', 'c.id_estado', '=', 'e.id').where('i.cnes', '=', cnes);
    if (resultado.length > 0) {
      return resultado[0];
    }
    return null;
  }
};
module.exports = Instituicao;