var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import _ from "lodash";
import FormatNumber from "../format/FormatNumber";
const toAll = (dataset, algoritmoCb) => __awaiter(void 0, void 0, void 0, function* () {
  const dataInner = [];
  dataset.shift(); // deletar a primeira linha
  for (let index = 0; index < dataset.length; index++) {
    const row = dataset[index];
    const [dim, acertos, apresentacoes] = row;
    const {
      data: dataRes
    } = yield algoritmoCb({
      acertos: acertos.toString().split(','),
      apresentacoes: apresentacoes.toString().split(',')
    });
    dataInner.push({
      x: [...dataRes['xLow'], ...dataRes['x']],
      y: [...dataRes['fitValuesLow'], ...dataRes['fitValues']],
      hovertext: `slope = ${FormatNumber.formatDouble(dataRes['getSlopePC'])}`,
      type: 'scatter',
      mode: 'lines',
      // marker: { color: 'green' },
      name: `${dim}: ${FormatNumber.formatDouble(dataRes['threshold'])} dB`
    });
  }
  return {
    dataInner
  };
});
const toGroups = (dataset, algoritmoCb) => __awaiter(void 0, void 0, void 0, function* () {
  const dataInner = [];
  dataset.shift(); // deletar a primeira linha
  let mapAcertosTotal = {};
  let mapApresentacoesTotal = {};
  for (let index = 0; index < dataset.length; index++) {
    const row = dataset[index];
    const [dim_, acertos, apresentacoes] = row;
    const dim = dim_.toString();
    if (!mapAcertosTotal[dim]) {
      mapAcertosTotal[dim] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      mapApresentacoesTotal[dim] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
    const acertosArray = acertos.toString().split(',');
    const apresentacoesArray = apresentacoes.toString().split(',');
    for (let j = 0; j < acertosArray.length; j++) {
      mapAcertosTotal[dim][j] = mapAcertosTotal[dim][j] + Number(acertosArray[j]);
      mapApresentacoesTotal[dim][j] = mapApresentacoesTotal[dim][j] + Number(apresentacoesArray[j]);
    }
  }
  // Executar o algoritmo
  for (const key in mapAcertosTotal) {
    if (Object.hasOwnProperty.call(mapAcertosTotal, key)) {
      const acertosTotal = mapAcertosTotal[key];
      const apresentacoesTotal = mapApresentacoesTotal[key];
      const {
        data: dataRes
      } = yield algoritmoCb({
        acertos: acertosTotal,
        apresentacoes: apresentacoesTotal
      });
      dataInner.push({
        x: [...dataRes['xLow'], ...dataRes['x']],
        y: [...dataRes['fitValuesLow'], ...dataRes['fitValues']],
        hovertext: `slope = ${FormatNumber.formatDouble(dataRes['getSlopePC'])}`,
        type: 'scatter',
        mode: 'lines',
        name: `${key}: ${FormatNumber.formatDouble(dataRes['threshold'])} dB`
      });
    }
  }
  return {
    dataInner
  };
});
const toOne = (dataset, algoritmoCb) => __awaiter(void 0, void 0, void 0, function* () {
  const dataInner = [];
  dataset.shift(); // deletar a primeira linha
  let acertosTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let apresentacoesTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let r_acertosTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let r_apresentacoesTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (let index = 0; index < dataset.length; index++) {
    const row = dataset[index];
    const [_, acertos, apresentacoes, r_acertos, r_apresentacoes] = row;
    const acertosArray = acertos.toString().split(',');
    const apresentacoesArray = apresentacoes.toString().split(',');
    const r_acertosArray = r_acertos.toString().split(',');
    const r_apresentacoesArray = r_apresentacoes.toString().split(',');
    for (let j = 0; j < acertosArray.length; j++) {
      acertosTotal[j] = acertosTotal[j] + Number(acertosArray[j]);
      apresentacoesTotal[j] = apresentacoesTotal[j] + Number(apresentacoesArray[j]);
      if (apresentacoesArray.length > 1) {
        r_acertosTotal[j] = r_acertosTotal[j] + Number(r_acertosArray[j]);
        r_apresentacoesTotal[j] = r_apresentacoesTotal[j] + Number(r_apresentacoesArray[j]);
      }
    }
  }
  function addResult(_acertosTotal, _apresentacoesTotal) {
    let esquerdo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return __awaiter(this, void 0, void 0, function* () {
      if (_.sum(_apresentacoesTotal) === 0) return;
      const {
        data: dataRes
      } = yield algoritmoCb({
        acertos: _acertosTotal,
        apresentacoes: _apresentacoesTotal
      });
      dataInner.push({
        x: [...dataRes['xLow'], ...dataRes['x']],
        y: [...dataRes['fitValuesLow'], ...dataRes['fitValues']],
        hovertext: `slope = ${FormatNumber.formatDouble(dataRes['getSlopePC'])}`,
        type: 'scatter',
        mode: 'lines',
        name: `SRT ${esquerdo ? '(esquerdo)' : '(direito)'}: ${FormatNumber.formatDouble(dataRes['threshold'])} dB`,
        line: {
          color: esquerdo ? 'blue' : 'red',
          width: 3
        },
        showlegend: true
      });
      dataInner.push({
        y: _.zipWith(_acertosTotal, _apresentacoesTotal, (a, b) => a / b),
        x: [-16, -14, -12, -10, -8, -6, -4, -2, 0, 2, 4],
        type: 'scatter',
        mode: 'markers',
        hovertext: _.zipWith(_acertosTotal, _apresentacoesTotal, (a, b) => `${a} acertos <br />${b} apresentações`),
        marker: {
          color: esquerdo ? 'blue' : 'red',
          line: {
            color: esquerdo ? 'blue' : 'red',
            width: 2
          },
          symbol: esquerdo ? 'x-dot' : 'circle-open',
          size: 20
        },
        name: esquerdo ? 'Esquerdo' : 'Direito',
        showlegend: false
      });
      return dataRes;
    });
  }
  // LADO ESQUERDO
  const dataRes = yield addResult(acertosTotal, apresentacoesTotal, true);
  // LADO DIREITO
  const r_dataRes = yield addResult(r_acertosTotal, r_apresentacoesTotal, false);
  // HISTOGRAMA DE TODAS AS APRESENTACOES
  dataInner.push({
    y: _.zipWith(apresentacoesTotal, r_apresentacoesTotal, (a, b) => a + b),
    x: [-16, -14, -12, -10, -8, -6, -4, -2, 0, 2, 4],
    type: 'histogram',
    nbinsx: 15,
    histnorm: 'probability',
    histfunc: 'sum',
    name: 'Apresentações',
    showlegend: false
  });
  return {
    dataInner,
    dataRes,
    r_dataRes
  };
});
export default {
  toAll,
  toOne,
  toGroups
};