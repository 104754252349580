module.exports = {
  abrirJanelaVideo: 'Abrir janela de vídeo',
  aceitarConvite: 'Aceitar convite',
  agenda: 'Agenda',
  agendadores: 'Agendadores',
  agendador: 'Agendador',
  agendamento: 'Agendamento',
  aguardeSalaVideo: 'Aguarde enquanto configuramos a sala de atendimento...',
  alteracaoRede: 'Alteração de rede',
  anexar: 'Anexar',
  anexarArquivo: 'Anexar arquivos',
  anexos: 'Anexos',
  aoVivo: 'Ao vivo',
  atendente: 'Atendente',
  atendenteAdicionadoAnteriormente: 'Este atendente já foi adicionado anteriormente.',
  avisoSemConexao: 'Ops! Parece que perdemos a conexão com o servidor Conecta. Verifique sua conexão com a internet e aguarde, o sistema será recarregado automaticamente assim que a conexão for restabelecida. Se preferir, você pode recarregar manualmente clicando <a href="javascript:window.location.reload(true)"><b>aqui</b></a>.',
  cadastroCategoria: 'Cadastro de categoria',
  cadastroMotivo: 'Cadastro de motivo de cancelamento',
  cadastroRede: 'Cadastro de rede',
  cancelarAgendamento: 'Cancelar agendamento',
  categoria: 'Categoria',
  categorias: 'Categorias',
  chamadaVideo: 'Iniciar atendimento por vídeo',
  chat: 'Chat',
  complementacoes: 'Complementações',
  concluido: 'Concluído',
  concordo: 'Concordo',
  confirmarAceitarEvento: 'Você confirma o aceite no convite deste evento?',
  confirmarAlterarAgendamento: 'Você tem certeza que deseja alterar os dados deste agendamento?',
  confirmarAlterarCategoria: 'Você tem certeza que deseja alterar esta categoria?',
  confirmarAlterarMotivo: 'Você tem certeza que deseja alterar este motivo de cancelamento?',
  confirmarAlterarRede: 'Você tem certeza que deseja alterar esta rede?',
  confirmarCancelarEvento: 'Você tem certeza que deseja cancelar este agendamento?',
  confirmarFecharVideo: 'Você deseja fechar a chamada de áudio e vídeo para todos os usuários conectados no evento?',
  confirmarFinalizarEvento: 'Você deseja encerrar o evento para todos os usuários conectados?',
  confirmarRecusarEvento: 'Você confirma a recusa no convite deste evento?',
  confirmarRegistroAtendimento: 'Você realmente deseja salvar esse registro do evento?',
  confirmarSalvarAgendamento: 'Você tem certeza que deseja realizar este agendamento?',
  confirmarSalvarCategoria: 'Você tem certeza que deseja cadastrar esta categoria?',
  confirmarSalvarMotivo: 'Você tem certeza que deseja cadastrar este motivo de cancelamento?',
  confirmarSalvarRede: 'Você tem certeza que deseja cadastrar esta rede?',
  convite: 'Convite',
  conviteAceito: 'Aceito',
  conviteNegado: 'Negado',
  convitePendente: 'Pendente',
  convites: 'Convites',
  convitesLivre: 'Convites livres',
  dataHoraInicio: 'Data/hora de início',
  dataHoraTermino: 'Data/hora de término',
  desfecho: 'Desfecho',
  desfechoAtendimento: 'Desfecho do atendimento',
  digiteMensagem: 'Digite sua mensagem...',
  duvidaPrincipal: 'Dúvida principal',
  enviandoArquivo: 'Enviando arquivo...',
  enviarMensagem: 'Enviar mensagem',
  erroAssuntoMinimo5Caracteres: 'O assunto deve ter no mínimo 5 (cinco) caracteres válidos.',
  erroConectarBBB: 'Erro ao conectar na sala de áudio e vídeo. Deseja tentar novamente?',
  erroParticipanteDuplicado: 'Este participante já foi adicionado anteriormente.',
  erroParticipanteObrigatorio: 'É obrigatório a inclusão de ao menos um participante no agendamento',
  espera: 'Em espera',
  eventos: 'Eventos',
  eventosPassados: 'Eventos passados',
  fecharChamadaVideo: 'Fechar atendimento por vídeo',
  filtroFuncionario: 'Filtro de funcionários',
  finalizarAtendimento: 'Finalizar atendimento',
  historico: 'Histórico',
  historicoAtendimento: 'Histórico dos atendimentos',
  historicoSaude: 'Histórico de saúde',
  idNaoEncontrado: 'Não conseguimos encontrar o evento. Você será direcionado para tela de início onde poderá escolher o evento desejado.',
  idObrigatorio: 'A inclusão do identificador do agendamento é obrigatória',
  inserirAnexo: 'Inserir anexo',
  instrucaoCadastroCategoria: `<span>- A partir dessa tela, é possível pesquisar e cadastrar categorias de redes.</span>
                                <span>- A partir dessa tela, também é possível alterar e inativar categorias de redes previamente cadastradas.</span>`,
  instrucaoCadastroMotivoCancelamento: `<span>- A partir dessa tela, é possível pesquisar e cadastrar motivos de cancelamento de agendamentos.</span>
                                <span>- A partir dessa tela, também é possível alterar e inativar motivos de cancelamento previamente cadastrados.</span>`,
  instrucaoCadastroRede: `<span>- A partir dessa tela, é possível pesquisar e cadastrar redes.</span>
                                <span>- A partir dessa tela, também é possível alterar e inativar redes previamente cadastradas.</span>`,
  instrucaoAgendaForm: `<span>- Cadastra a agenda de atendente(s) a uma rede de atendimento.</span>`,
  maximo360Minutos: 'Duração máxima de 360 minutos',
  mensagemDataAgendamentoSobreposta: quantidade => `Encontramos ${quantidade} agendamento(s) que se sobrepõe à data/hora escolhidas. Sugerimos a alteração de horário do agendamento atual.`,
  mensagemSemRede: `<p>Não é possível fazer um agendamento de evento neste momento, pois você não possui a permissão de criar agendamento na(s) rede(s) em que está vinculado.</p>
                                <p>Para mais informações, por favor, entre em contato com o seu suporte.</p>`,
  minimo10Minutos: 'Duração mínima de 10 minutos',
  motivoAtendimento: 'Motivo do atendimento',
  motivoCancelamento: 'Motivo de cancelamento',
  motivosCancelamento: 'Motivos de cancelamento',
  notificacaoNovaMensagemAnexo: 'Envio um anexo.',
  notificacaoSolicitacaoAtendimento: 'Um usuário solicitou atendimento.',
  notificacaoTabNovaMensagem: '✉️ Nova mensagem enviada',
  periodo: 'Período',
  participanteAdicionado: 'Participante adicionado',
  participantes: 'Participantes',
  participantesPrincipais: 'Participantes principais',
  permiteRegistro: 'Permite registro',
  permiteRegistroAtendimento: 'Permite registro do evento?',
  placeholderDesfechoAtendimento: 'Descrever as orientações que foram realizadas em relação ao manejo do caso apresentado, como as prescrições, solicitações de exames laboratoriais ou de imagem, orientações ao paciente ou ainda sobre a necessidade de retorno do atendimento.',
  placeholderDuvidaPrincipal: 'A partir da descrição do caso apresentado, qual a dúvida do paciente?',
  placeholderHistoricoSaude: 'Descrever hábitos e comorbidades do paciente que possam influenciar suas condições de saúde, além de internações, cirurgias e tratamentos anteriores que sejam pertinentes ao caso.',
  placeholderMotivoAtendimento: 'Descreva o motivo para realização do atendimento.',
  placeholderRelatoCaso: 'Descrever o caso com clareza, apontando a história clínica atual de forma detalhada.',
  placeholderResultadoExames: 'Listar os resultados dos exames laboratoriais e de imagem que contribuam com a análise do caso.',
  placeholderTratamentosAndamento: 'Listar medicamentos e tratamentos alternativos em uso.',
  proximosEventos: 'Próximos eventos',
  recusarConvite: 'Recusar convite',
  rede: 'Rede',
  redes: 'Redes',
  registroAtendimento: 'Registro do evento',
  registroAtendimentoSalvoSucesso: 'Registro do evento salvo com sucesso.',
  registros: 'Registros',
  relatoCaso: 'Relato do caso',
  relatosPaciente: 'Relatos do paciente',
  resultados: 'Resultados de exames',
  resultadosExames: 'Resultados de exames laboratoriais ou de imagem',
  selecionarEmoji: 'Selecionar emoji',
  selecioneArquivo: 'Selecione um arquivo',
  selecioneAtendente: 'Por favor, selecione ao menos um atendente.',
  semPermissaoEvento: 'Você não tem permissão para participar deste evento pois não está na lista de convidados.',
  sucessoAceitarAgendamento: 'Convite aceito com sucesso',
  sucessoCancelarAgendamento: 'Agendamento cancelado com sucesso',
  sucessoRecusarAgendamento: 'Convite recusado com sucesso',
  sucessoSalvarAgendamento: 'Agendamento salvo com sucesso',
  tempoDuracao: 'Tempo de duração',
  tempoDuracaoMinutos: 'Duração (em minutos)',
  textoAtendenteConectado: 'Atendente conectado, por favor retire sua dúvida.',
  textoAtendimentoFinalizado: 'O atendente encerrou este atendimento.',
  tipoFuncionamento: 'Tipo de funcionamento',
  tratamentosAndamento: 'Tratamentos em andamento',
  validar: 'Validar'
};