import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(theme => ({
    helpIcon: {
        fontSize: '0.8rem',
        verticalAlign: 'super',
        color: theme.palette.primary.main
    },
    tooltip: {
        backgroundColor: theme.palette.background.default,
    }
}));

const Description = ({ children }) => {
    const classes = useStyles();
    return (
        <Tooltip title={children} className={classes.tooltip}>
            <HelpIcon className={classes.helpIcon} />
        </Tooltip>
    );
};

Description.propTypes = {
    children: PropTypes.node
};

export default Description;
