import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        maxWidth: '100vw',
        //overflowY: 'hidden',
    },
    mainContentComPadding: {
        flexGrow: 1,
        marginTop: '104px',
        padding: theme.spacing(3),
        width: `100vw`,
        minHeight: 'calc(100vh - 104px)',
    },
    mainContentSemPadding: {
        flexGrow: 1,
        marginTop: '104px',
        width: `100vw`,
        minHeight: 'calc(100vh - 104px)',
    },
    fakeToolbar: {
        ...theme.mixins.toolbar,
    },
    fullContent: {
        height: '100%',
        width: '100%',
    },
    button: {
        margin: '8px 8px 8px 0px',
    },
    pivottable: {
        overflowY: 'hidden',
        overflowX: 'auto',
        minHeight: '550px',
    },
    dialogContent: {
        '& .MuiDialogContent-root': {
            paddingLeft: '0px',
        },
    },
}));
