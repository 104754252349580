/**
 * Conversão do datasetDruid para o dataset que o GIS entende.
 * Ver projeto do stt-gis (https://codigos.ufsc.br/incod/stt-gis.gi).
 */
import TipoAtributoUtil from '../common/TipoAtributoUtil';
import TipoAtributo from '../constants/TipoAtributo';
// Dimensões Geográficas
const DimGeoUF = 'DimGeoUF';
const DimGeoCodIBGE = 'DimGeoCodIBGE';
// Métricas
const Metric = 'Metric';
const makeObjGeoData = (row, labels) => {
  let geoDataRow = {};
  for (let index = 0; index < row.length; index++) {
    const cell = row[index];
    geoDataRow[labels[index]] = cell;
  }
  return geoDataRow;
};
const makeDatasetGeo = (datasetDruid, metaAtributos) => {
  const header = datasetDruid.shift();
  let dataTypes = [];
  const labels = header === null || header === void 0 ? void 0 : header.map(atributo => {
    const metaAtributoEncontrado = metaAtributos.find(metaAtributo => metaAtributo.atributo == atributo);
    dataTypes.push({
      key: metaAtributoEncontrado.rotulo,
      type: makeTipoGeografico(metaAtributoEncontrado.tipo),
      caption: metaAtributoEncontrado.rotulo
    });
    return metaAtributoEncontrado.rotulo;
  });
  const data = datasetDruid.map(row => {
    return makeObjGeoData(row, labels);
  });
  return {
    dataTypes,
    data
  };
};
const makeTipoGeografico = tipoAtributo => {
  switch (tipoAtributo) {
    case TipoAtributo.TEXTO_GEO_COD_IBGE:
      return DimGeoCodIBGE;
    case TipoAtributo.TEXTO_GEO_UF:
      return DimGeoUF;
  }
  if (TipoAtributoUtil.isNumber(tipoAtributo)) return Metric;
  throw new Error('variável tipoAtributo não suportado');
};
export default {
  makeDatasetGeo,
  makeTipoGeografico
};