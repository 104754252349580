module.exports = {
  adicionarDestinatarioExterno: 'Adicionar destinatário externo',
  anexo: 'Anexo',
  anexos: 'Anexos',
  assunto: 'Assunto',
  assuntoMensagemEnviada: 'Assunto',
  ativar: 'Ativar',
  avisoMensagemBloqueia: 'Essa mensagem bloqueia o uso do sistema para o seu usuário. Por favor, entre em contato com a administração do sistema para mais informações.',
  bloqueioTela: 'Bloqueio de tela',
  cadastroEmailExterno: 'Cadastro de emails externos',
  cabecalhoRodape: 'Cabeçalho/Rodapé',
  cadastroNotificacao: 'Cadastro de notificação',
  cadastroCabecalhoRodape: 'Cadastro de cabeçalho/rodapé',
  campoObrigatorioDestinatario: 'Informe pelo menos um destinatário',
  complementoCategoria: 'Complemento categoria',
  confirmacaoLeitura: 'Confirmação de leitura',
  confirmarAlteracaoNotificacao: 'Você confirma a alteração desta notificação?',
  confirmarAlterarEmailExterno: 'Você confirma a alteração deste email?',
  confirmarAtivacao: 'Você confirma a reativação desta notificação?',
  confirmarExclusao: 'Você confirma a exclusão desta notificação?',
  confirmarExclusaoEmailsExternos: 'Você confirma a exclusão deste email?',
  confirmarInativacao: 'Você confirma a inativação desta notificação?',
  confirmarNotificacao: 'Você confirma o cadastro desta notificação?',
  confirmarSalvarEmailExterno: 'Você confirma o cadastro deste email?',
  confirmarSalvarCabecalhoRodape: 'Você confirma o cadastro deste cabeçalho/rodapé?',
  dataFinalExibicao: 'Data final de exibição',
  dataHoraCadastro: 'Data/hora cadastro',
  dataHoraEnvio: 'Data/hora de envio',
  dataInicialExibicao: 'Data inicial de exibição',
  destinatarios: 'Destinatários',
  duplicar: 'Duplicar',
  emailsExternos: 'Destinatários externos',
  envio: 'Envio',
  erroFuncionarioDuplicado: 'Esse funcionário já foi adicionado anteriormente.',
  favorita: 'Favorita',
  favoritar: 'Favoritar',
  filtroDestinatarios: 'Filtrar destinatários',
  funcionarioAdicionado: 'Funcionário adicionado com sucesso.',
  informeAssunto: 'Informe o assunto',
  instrucaoEmailsExternos: 'A partir dessa tela, é possível pesquisar, cadastrar, alterar e excluir emails externos.',
  instrucaoCabecalhoRodape: 'A partir dessa tela, é possível pesquisar, cadastrar, alterar e excluir templates de cabeçalhos e rodapés de emails.',
  mensagemEnviada: 'Mensagem enviada',
  mensagensEnviadas: 'Mensagens enviadas',
  moduloMensagens: 'Mensagens',
  notificacaoAlterada: 'Notificação alterada com sucesso!',
  notificacaoCadastrada: 'Notificação cadastrada com sucesso!',
  notificacoes: 'Notificações',
  notificacoesFavoritas: 'Notificações favoritas',
  numeroDestinatarios: 'Número de destinatários',
  outraCategoria: 'Informa a outra categoria',
  periodoExibicao: 'Período de exibição',
  preVisualizacao: 'Pré-visualização de notificação',
  preVisualizar: 'Pré-visualizar',
  responsavelSistema: 'SISTEMA',
  salvandoNotificacao: 'Salvando notificação...',
  semCategoria: 'Sem categoria informada',
  sistema: 'Sistema',
  sucessoAtivacao: 'Notificação reativada com sucesso.',
  sucessoExclusao: 'Notificação excluída com sucesso.',
  sucessoInativacao: 'Notificação inativada com sucesso.',
  tipoMensagemEnviada: 'Tipo',
  useFiltros: 'Use os filtros acima para buscar os destinatários'
};