const buildStyleString = () => {
  return `
              <style>
                  .invisivel {
                      display: none;
                  }
                 .pvtUi {
                        color: #2a3f6f;
                        font-family: Inter, -apple-system, Roboto, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
                        border-collapse: collapse;
                      }
                      .pvtUi select {
                        user-select: none;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -khtml-user-select: none;
                        -ms-user-select: none;
                      }
                      .pvtUi td.pvtOutput {
                        vertical-align: top;
                      }

                      table.pvtTable {
                        font-size: 8pt;
                        text-align: left;
                        border-collapse: collapse;
                        margin-top: 3px;
                        margin-left: 3px;
                        font-family: Inter, -apple-system, Roboto, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
                      }

                      table.pvtTable thead tr th,
                      table.pvtTable tbody tr th {
                        background-color: #ebf0f8;
                        border: 1px solid #c8d4e3;
                        font-size: 8pt;
                        padding: 5px;
                      }

                      table.pvtTable .pvtColLabel {
                        text-align: center;
                      }

                      table.pvtTable .pvtTotalLabel {
                        text-align: right;
                      }

                      table.pvtTable tbody tr td {
                        color: #2a3f6f;
                        padding: 5px;
                        background-color: #fff;
                        border: 1px solid #c8d4e3;
                        vertical-align: top;
                        text-align: right;
                      }

                      .pvtTotal,
                      .pvtGrandTotal {
                        font-weight: bold;
                      }

                      .pvtRowOrder,
                      .pvtColOrder {
                        cursor: pointer;
                        width: 15px;
                        margin-left: 5px;
                        display: inline-block;
                        user-select: none;
                        text-decoration: none !important;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -khtml-user-select: none;
                        -ms-user-select: none;
                      }

                      .pvtAxisContainer,
                      .pvtVals {
                        border: 1px solid #0A6FB8;
                        background: #f2f5fa;
                        padding: 5px;
                        min-width: 20px;
                        min-height: 20px;
                      }

                      .pvtRenderers {
                        padding-left: 5px;
                        user-select: none;
                      }

                      .pvtDropdown {
                        display: inline-block;
                        position: relative;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -khtml-user-select: none;
                        -ms-user-select: none;
                        margin: 3px;
                      }
                      .pvtDropdownIcon {
                        float: right;
                        color: #a2b1c6;
                      }
                      .pvtDropdownCurrent {
                        text-align: left;
                        border: 1px solid #a2b1c6;
                        border-radius: 4px;
                        display: inline-block;
                        position: relative;
                        width: 310px;
                        height: 30px;
                        box-sizing: border-box;
                        background: white;
                      }
                      .pvtDropdownCurrentOpen {
                        border-radius: 4px 4px 0 0;
                      }
                      .pvtDropdownMenu {
                        background: white;
                        position: absolute;
                        width: 100%;
                        margin-top: -1px;
                        border-radius: 0 0 4px 4px;
                        border: 1px solid #a2b1c6;
                        border-top: 1px solid #dfe8f3;
                        box-sizing: border-box;
                      }
                      .pvtDropdownValue {
                        padding: 5px 5px;
                        font-size: 14px;
                        text-align: left;
                      }
                      .pvtDropdownActiveValue {
                        background: #ebf0f8;
                      }

                      .pvtVals {
                        text-align: left;
                        white-space: nowrap;
                        vertical-align: top;
                        padding-bottom: 12px;
                      }

                      .pvtRows {
                        height: 35px;
                      }

                      .pvtAxisContainer li {
                        padding: 8px 6px;
                        list-style-type: none;
                        cursor: move;
                      }
                      .pvtAxisContainer li.pvtPlaceholder {
                        -webkit-border-radius: 5px;
                        padding: 3px 15px;
                        -moz-border-radius: 5px;
                        border-radius: 5px;
                        border: 1px dashed #a2b1c6;
                      }
                      .pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
                        display: none;
                      }
                      .pvtAxisContainer li span.pvtAttr {
                        -webkit-text-size-adjust: 100%;
                        background: #fff;
                        border: 1px solid #c8d4e3;
                        padding: 5px 5px;
                        white-space: nowrap;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        border-radius: 5px;
                        user-select: none;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -khtml-user-select: none;
                        -ms-user-select: none;
                      }

                      .pvtTriangle {
                        cursor: pointer;
                        color: #506784;
                      }

                      .pvtHorizList li {
                        display: inline-block;
                      }

                      .pvtVertList {
                        vertical-align: top;
                      }

                      .pvtFilteredAttribute {
                        font-style: italic;
                      }

                      .sortable-chosen .pvtFilterBox {
                        display: none !important;
                      }

                      .pvtCloseX {
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        font-size: 18px;
                        cursor: pointer;
                        text-decoration: none !important;
                      }

                      .pvtDragHandle {
                        position: absolute;
                        left: 5px;
                        top: 5px;
                        font-size: 18px;
                        cursor: move;
                        color: #a2b1c6;
                      }

                      .pvtButton {
                        color: #506784;
                        border-radius: 5px;
                        padding: 3px 6px;
                        background: #f2f5fa;
                        border: 1px solid;
                        border-color: #c8d4e3;
                        font-size: 14px;
                        margin: 3px;
                        transition: 0.34s all cubic-bezier(0.19, 1, 0.22, 1);
                        text-decoration: none !important;
                      }
                      .pvtButton:hover {
                        background: #e2e8f0;
                        border-color: #a2b1c6;
                      }
                      .pvtButton:active {
                        background: #d1dae6;
                      }

                      .pvtFilterBox input {
                        border: 1px solid #c8d4e3;
                        border-radius: 5px;
                        color: #506784;
                        padding: 0 3px;
                        font-size: 14px;
                      }
                      .pvtFilterBox input:focus {
                        border-color: #119dff;
                        outline: none;
                      }

                      .pvtFilterBox {
                        z-index: 100;
                        width: 300px;
                        border: 1px solid #506784;
                        background-color: #fff;
                        position: absolute;
                        text-align: center;
                        user-select: none;
                        min-height: 100px;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -khtml-user-select: none;
                        -ms-user-select: none;
                      }
                      .pvtFilterBox h4 {
                        margin: 15px;
                      }
                      .pvtFilterBox p {
                        margin: 10px auto;
                      }
                      .pvtFilterBox button {
                        color: #2a3f6f;
                      }
                      .pvtFilterBox input[type=text] {
                        width: 230px;
                        color: #828282;
                        margin-bottom: 5px;
                      }

                      .pvtCheckContainer {
                        text-align: left;
                        font-size: 14px;
                        white-space: nowrap;
                        overflow-y: scroll;
                        width: 100%;
                        max-height: 30vh;
                        border-top: 1px solid #dfe8f3;
                      }
                      .pvtCheckContainer p {
                        margin: 0;
                        margin-bottom: 1px;
                        padding: 3px;
                        cursor: default;
                      }
                      .pvtCheckContainer p.selected {
                        background: #ebf0f8;
                      }

                      .pvtOnly {
                        display: none;
                        width: 35px;
                        float: left;
                        font-size: 12px;
                        padding-left: 5px;
                        cursor: pointer;
                      }
                      .pvtOnlySpacer {
                        display: block;
                        width: 35px;
                        float: left;
                      }
                      .pvtCheckContainer p:hover .pvtOnly {
                        display: block;
                      }
                      .pvtCheckContainer p:hover .pvtOnlySpacer {
                        display: none;
                      }

                      .pvtRendererArea {
                        padding: 5px;
                      }
            </style>
  `;
};
const buildMetaString = _ref => {
  let {
    titulo,
    responsavel,
    tipo
  } = _ref;
  return `        
            <meta charset="UTF-8">
            <meta name="stt-titulo" content="${titulo}">
            <meta name="stt-responsavel" content="${responsavel}">
            <meta name='stt-tipo' content='${tipo}'>
      `;
};
const tableToJson = doc => {
  const table = doc.querySelector('table');
  const data = [];
  // Processando cabeçalhos (thead)
  const headers = [];
  const headerElements = table.querySelectorAll('thead th');
  headerElements.forEach(header => {
    headers.push(header.innerText);
  });
  // Adicionando cabeçalhos como primeira linha do JSON
  data.push(headers);
  // Processando o corpo da tabela (tbody)
  const rows = table.querySelectorAll('tbody tr');
  rows.forEach(row => {
    const rowData = [];
    row.querySelectorAll('td').forEach(cell => {
      rowData.push(cell.innerText);
    });
    data.push(rowData);
  });
  return [headers, data];
};
const jsonToHtmlTable = _ref2 => {
  let {
    columns,
    rows
  } = _ref2;
  let table = '<table border="1">';
  // Cabeçalhos (primeira linha do array)
  table += '<thead><tr>';
  columns.forEach(header => {
    table += `<th>${header}</th>`;
  });
  table += '</tr></thead>';
  // Linhas de dados
  table += '<tbody>';
  for (let i = 0; i < rows.length; i++) {
    table += '<tr>';
    rows[i].forEach(cell => {
      table += `<td>${cell}</td>`;
    });
    table += '</tr>';
  }
  table += '</tbody></table>';
  return table;
};
module.exports = {
  buildStyleString,
  buildMetaString,
  tableToJson,
  jsonToHtmlTable
};