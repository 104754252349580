import { useState, useCallback } from 'react';
import axios from 'axios';
import { MSGBuilder, MSGCode, MSGUtil, TabelaDadosRules } from '@stt-analise/util';
import { Cache } from '@stt-componentes/cache';

const fetch = async (payload) => {
    const BACKEND_URL = global.gConfig.url_base_graphql;
    const response = await axios.post(`${BACKEND_URL}/pesquisar`, payload, {
        method: 'POST',
        headers: { Authorization: `Bearer ${Cache.getAccessToken()}`, 'Content-Type': 'application/json' },
    });

    return response.data;
};

export default function useReqPesquisar() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    const resetDataset = useCallback(() => {
        setData([]);
    }, []);

    const reqPesquisar = useCallback(async ({ payload, emitirAlerta, metaAtributos, strings }) => {
        try {
            setLoading(true);
            const result = await fetch(payload);
            setLoading(false);

            // 1 - Resultados não encontrados. Valor 1 por conta do cabeçalho.
            if (result.length <= 1) {
                emitirAlerta(new MSGBuilder(MSGCode.ALE001, strings).buildMsgAlert());
            }

            // 2 - Avisar o usuário do grande volume de dados, se deseja continuar mesmo assim.
            const [validLimitDados, count] = TabelaDadosRules.validarLimiteDataset(result);
            const msgAlert = new MSGBuilder(MSGCode.CON002, strings).buildMsgAlert();
            const yesOrNo = await MSGUtil.confirmationFuncWithRule(msgAlert(count), validLimitDados, emitirAlerta);
            if (!yesOrNo) return;

            setLoading(true);
            // 3 - Aplicar algumas transformações no resultado do dataset.
            const dataset = TabelaDadosRules.formatDataset(payload.cubo, result);

            // 4 - Normalizar os nomes das colunas.
            dataset[0] = TabelaDadosRules.renameHeadersByCubo(payload.cubo, dataset[0], metaAtributos);

            setData(dataset);
        } catch (err) {
            console.error(err);
            const msgAlert = new MSGBuilder(MSGCode.ERR001, strings).buildMsgAlert();
            emitirAlerta(msgAlert);
            setError(err);
        } finally {
            setLoading(false);
        }
    }, []);

    return { reqPesquisar, resetDataset, data, error, loading };
}
