module.exports = {
  abrir: 'Abrir',
  abrirNovaAba: 'Abrir anexo em nova aba',
  agendado: 'Agendado',
  alteracaoComorbidadeModal: 'Alteração de comorbidade',
  anexarArquivo: 'Anexar arquivos',
  anexos: 'Lista de anexos',
  anosFuma: 'Anos que fuma/fumou',
  anosParouFumo: 'Anos que parou de fumar',
  assinando: 'Aguarde - assinando digitalmente o laudo...',
  ausente: 'Ausente',
  avaliar: 'Avaliar',
  BuscaFuncionario: {},
  Cabecalho: {
    modulo: 'Espirometria'
  },
  cadastroComorbidadeModal: 'Cadastro de uma nova comorbidade',
  carregandoExame: 'Carregando exame',
  categoria: 'Categoria',
  categoria: 'Categoria',
  cidadeProcedencia: 'Município de procedência',
  classificacaoRisco: 'Classificação de risco',
  coloracao: 'Coloração',
  comorbidadeObrigatorio: 'É obrigado a informar ao menos uma comorbidade',
  comorbidadesTitulo: 'Comorbidades',
  conclusao: 'Conclusão',
  concordoP: 'Concordo parcialmente',
  concordoT: 'Concordo totalmente',
  confirmando: 'Confirmando laudo',
  confirmarAlteracaoComorbidade: 'Confirma a alteração da comorbidade?',
  confirmarInsercaoComorbidade: 'Confirma a inserção de uma nova comorbidade?',
  contraRefercia: 'Contra-referência',
  controleTratamento: 'Controle de tratamento',
  copiarLaudo: 'Copiar laudo',
  copiarPara: 'Copiar para:',
  dadosCopiados: 'Dados copiados',
  dadosInvalidos: 'Dados inválidos. Verifique os dados informados e tente novamente.',
  dataHoraAtendimento: 'Data/hora do atendimento',
  dataHoraTeste: 'Data/hora do teste',
  dermatose: 'Dermatose',
  descricaoEstudo: 'Descrição do estudo',
  descricaoExame: 'Descrição do exame',
  descritores: 'Descritores',
  diminuida: 'Diminuída',
  direcionados: 'Direcionado ao meu usuário',
  discordoP: 'Discordo parcialmente',
  discordoT: 'Discordo totalmente',
  distribuicao: 'Distribuição',
  duasQuatroVezezMes: '2-4 vezes ao mês',
  duasTresVezesSemana: '2-3 vezes por semana',
  emailInvalido: 'Email inválido',
  enviar: 'Enviar',
  erroAssinatura: 'Erro ao assinar digitalmente o laudo',
  erroGerarTermo: 'Não foi possível gerar o termo de autorização. Tente imprimir o termo através da tela de exames.',
  Erros: {
    naoAutorizado: 'Não autorizado'
  },
  erroSortearExame: 'Não foi possível sortear o próximo exame para laudo. Tente novamente e se o problema persistir, entre em contato com os administradores.',
  erroTratarObesidade: 'O paciente possui classificação do estado nutricional "Obesidade" e, por isso, a opção "Obesidade" não pode ser desmarcada.',
  erroTratarSobrepeso: 'O paciente possui classificação do estado nutricional "Sobrepeso" e, por isso, a opção "Sobrepeso" não pode ser desmarcada.',
  especificacaoTempo: 'Especificação',
  etilismo: 'Etilismo - com qual frequência consome bebidas com álcool?',
  exameMaisAntigoSemLaudo: 'Data do exame mais antigo sem laudo',
  examesAgendados: 'Agendados',
  examesComImagens: 'Com imagens',
  exameSelecionado: 'Exame selecionado',
  examesInvalidos: 'Inválidos',
  examesPacs: 'PACS',
  examesSelecionados: 'Exames selecionados',
  examesValidos: 'Válidos',
  exFumante: 'Ex-fumante',
  fototipo: 'Fototipo',
  frequencia: 'Frequência',
  frequenciaDiaria: 'Frequência diária',
  fumante: 'Fumante',
  gerandoResumo: 'Gerando resumo do laudo',
  hmf: 'História mórbida familiar (HMF)',
  hmp: 'História mórbida pregressa (HMP)',
  ignorandoExame: 'Ignorando exame',
  imagemInvalida: 'O campo deve conter uma imagem do tipo JPG',
  imformacoesCompl: 'Informações complementares',
  infoClinicas: 'Informações clínicas',
  instituicaoSolicitante: 'Instituição solicitante',
  instrucaoComorbidades: 'Cadastra uma comorbidade que será utilizada para especificar a(s) comorbidade(s) que o paciente possui na tela de solicitação de exames.',
  invalido: 'Inválido',
  investigacaoDiagnostica: 'Investigação diagnóstica',
  laudoAssinado: 'Laudo assinado digitalmente com sucesso',
  laudoEmEmissao: 'Devido à demora na emissão do laudo, este exame foi acessado por outro profissional. O próximo exame será carregado.',
  laudosEmitidos: 'Laudos emitidos',
  laudoTextual: 'Laudo textual',
  local: 'Local',
  localizacao: 'Localização',
  logradouro: 'Logradouro',
  medicamentosUso: 'Medicamento em uso (informar a dosagem)',
  medicoExecutor: 'Médico executor',
  mensagemAlertaCancelamento: 'Você confirma o cancelamento do envio de imagens?',
  mensagemConfirmarEnvioExame: 'Deseja enviar as imagens do exame agora?',
  mensagemConfirmarTermo: 'Deseja imprimir o TCLE?',
  mensagemSucessoAlteracao: 'Comorbidade alterada com sucesso',
  mensagemSucessoInsercao: 'Comorbidade inserida com sucesso',
  mensalmente: 'Mensalmente',
  morfologia: 'Morfologia',
  motivoExame: 'Motivo do exame',
  naoFoiPosisvelCarregarExamesInvalidos: 'Não foi possível carregar os dados de exames inválidos',
  naoFoiPossivelCarregarExamesComLaudo: 'Não foi possível carregar os dados de exames laudados',
  naoFoiPossivelCarregarLaudosEmitidos: 'Não foi possível carregar os dados de laudos emitidos',
  naoFoiPossivelCarregarSemLaudo: 'Não foi possível carregar os dados de exames sem laudo',
  naoFoiPossivelCarregarSemLaudo72h: 'Não foi possível carregar os dados de exames com laudo atrasado',
  naoFoiPossivelCarregarTotais: 'Não foi possível carregar os dados de produtividade',
  nenhumAnexo: 'Nenhum anexo encontrado',
  nenhummExameParaLaudo: 'Nenhum exame disponível para laudo',
  noaExisteEquipamento: 'A instituição do exame não possui nenhum ponto cadastrado. Entre em contato com os administradores.',
  notificacaoComorbidades: 'Cadastra uma comorbidade que será utilizada para especificar a(s) comorbidade(s) que o paciente possui na tela de solicitação de exames.',
  novaRede: 'Nova rede',
  numero_exame: 'Número do exame',
  ocupacional: 'Ocupacional',
  opcoes: 'Opções',
  origem: 'Origem',
  outrasInformacoes: 'Outras informações',
  outrasInfosPert: 'Outras informações pertinentes',
  pacs: 'PACS',
  pesquisar: 'Pesquisar',
  previewImagem: 'Visualização da imagem',
  proximoExame: 'Próximo exame',
  publicar: 'Publicar',
  qualquerTermo: 'Qualquer um dos termos',
  quatroOuMaisSemana: '4 ou mais vezes por semana',
  redeAlterada: 'Rede alterada',
  remover: 'Remover',
  responsavelEnvio: 'Responsável pelo envio',
  respostaBroncodilatador: 'Resposta ao broncodilatador',
  resumoHistoria: 'Resumo da história clínica',
  resumoLaudo: 'Resumo do laudo',
  solicitacaoRegistrada: 'Solicitação registrada',
  subcategoria: 'Subcategoria',
  termosDescricao: 'Termos da descrição do exame',
  teste: 'Teste',
  tipo: 'Tipo',
  tipoContato: 'Tipo de contato',
  tipoExame: 'Tipo de exame',
  titulo: 'Envio de imagens',
  tituloConfirmarEnvioExame: 'Deseja enviar as imagens?',
  tituloModalCancelamento: 'Confirmação de cancelamento',
  todasImagens: 'Todas as imagens',
  todosTermos: 'Todos os termos',
  trocarRede: 'Trocar rede',
  ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
  valido: 'Válido',
  visualizarResumo: 'Visualizar resumo'
};