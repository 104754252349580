import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Description from './Description';

const TitleContainer = ({ title, description }) => {
    return (
        <Typography variant="h3" component="h3">
            {title}
            {description && <Description>{description}</Description>}
        </Typography>
    );
};

TitleContainer.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

export default TitleContainer;
