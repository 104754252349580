import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 400,
    },
});

const perguntas = [
    "Você está falando com alguém em uma sala em que há uma televisão ligada. Sem abaixar o volume da televisão, você consegue acompanhar o que diz a pessoa que conversa com você?",
    "Você está ouvindo alguém que fala com você e, ao mesmo tempo, tenta acompanhar as notícias na televisão.Você consegue acompanhar o que ambos estão falando?",
    "Você está conversando com alguém em uma sala em que há muitas pessoas falando. Você consegue acompanhar o que diz a pessoa que conversa com você?",
    "Você está em um grupo de mais ou menos 5 pessoas, em um restaurante movimentado. Você consegue ver cada um do grupo. Você consegue acompanhar a conversa?",
    "Você está em um grupo e a conversa muda de uma pessoa para outra. Você consegue acompanhar com facilidade a conversa, sem perder o início do que cada pessoa fala?"
];

export default function Questionario({ datasetDruid = [], onResult = () => { } }) {
    const classes = useStyles();
    const [media, setMedia] = useState(null);

    useEffect(() => {
        const respostas = datasetDruid[1][0].split(',');
        let resMedia = null;

        // Calcular média
        if (perguntas.length === respostas.length) {
            resMedia = respostas.reduce((a, b) => Number(a) + Number(b), 0) / respostas.length;
        } else {
            console.warn('Não foi possível calcular a média do Questionário!')
        }

        setMedia(resMedia);
        onResult(resMedia);
    }, [])

    if (datasetDruid.length <= 1) return;
    let respostas = datasetDruid[1][0].split(',');

    if (perguntas.length !== respostas.length) {
        respostas = perguntas.map(_ => "Não preenchido");
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Questionário</TableCell>
                        <TableCell align="right">Respostas</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {perguntas.map((pergunta, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {pergunta}
                            </TableCell>
                            <TableCell align="right">{respostas[index]}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow key={10}>
                        <TableCell align="right" component="th" scope="row">
                            Média
                        </TableCell>
                        <TableCell align="right">{media}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
