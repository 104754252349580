import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SttAlerta } from '@stt-componentes/core';
import { NotificationManager } from 'react-notifications';

/* @useSignals */
import { alertaSignal, setOpenMsgAlert } from 'src/signals/alertaSignal';

function sendNotification({ title, message, type }) {
    switch (type) {
        case 'info':
            NotificationManager.info(message);
            break;
        case 'alert':
            NotificationManager.warning(message, title, 3000);
            break;
        case 'confirmation':
        case 'success':
            NotificationManager.success(message, title);
            break;
        case 'error':
            NotificationManager.error(message, title, 5000, () => {});
            break;
    }
}

const assembleOptions = ({ type, handleOnClose, handleOnYes }) => {
    switch (type) {
        case 'confirmation':
            return [
                {
                    title: 'Sim',
                    onClick: handleOnYes,
                },
                {
                    title: 'Não',
                    onClick: handleOnClose,
                },
            ];
        default:
            break;
    }
    return [
        {
            title: 'Ok',
            onClick: handleOnClose,
        },
    ];
};

/* @useSignals */
function MsgAlert() {
    const [alertMsg, setAlertMsg] = useState(null);
    const { msgAlert, openMsgAlert } = alertaSignal.value;

    useEffect(() => {
        if (!msgAlert || !openMsgAlert) return;

        if (msgAlert?.typeComponent === 'notification') {
            sendNotification({ ...msgAlert });
            setOpenMsgAlert(false);
        } else {
            setAlertMsg(msgAlert);
        }
    }, [msgAlert]);

    const handleOnClose = () => {
        setOpenMsgAlert(false);
        alertaSignal.value.noCallback();
    };

    const handleOnYes = () => {
        setOpenMsgAlert(false);
        alertaSignal.value.yesCallback();
    };

    const options = assembleOptions({ handleOnClose, handleOnYes: handleOnYes, type: alertMsg?.type });

    return (
        openMsgAlert && (
            <SttAlerta
                open={openMsgAlert && alertMsg?.typeComponent == 'prompt'}
                title={alertMsg?.title}
                message={alertMsg?.message}
                type={alertMsg?.type}
                options={options}
                onClose={handleOnClose}
            />
        )
    );
}

MsgAlert.propTypes = {
    msgAlert: PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string,
        type: PropTypes.string,
        typeComponent: PropTypes.string,
    }),
    yesCallback: PropTypes.func,
};

export default MsgAlert;
