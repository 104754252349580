module.exports = {
  abrir: 'Abrir',
  abrirNovaAba: 'Abrir anexo em nova aba',
  adicionandoTermoSBC: 'Adicionando termo SBC',
  administrativo: 'Administrativo',
  agendado: 'Agendado',
  aguda: 'Aguda (< 5 dias)',
  alteracao: 'Alteração',
  anexarArquivo: 'Anexar arquivos',
  anexoInvalido: 'O campo deve conter um anexo do tipo imagem ou PDF',
  anexos: 'Lista de anexos',
  atributoPosDescritor: 'Atributo pós-descritor',
  avaliar: 'Avaliar',
  cadastro: 'Cadastro',
  caracterizacaoDorToracica: 'Caracterização da dor torácica',
  caracterizacaoDorToracicaObrigatoria: 'É obrigatório a adição de pelo menos uma caracterização de dor torácica',
  cardiopatiaCongenita: 'Cardiopatia congênita',
  cardiopatiaCongenita: 'Cardiopatia congênita',
  carregandoExame: 'Carregando exame',
  categoria: 'Categoria',
  classificacaoDor: 'Classificação da dor (CCS)',
  definicaoSBC: 'Definição SBC',
  comorbidadesFatoresRisco: 'Comorbidades e fatores de risco',
  conclusao: 'Conclusão',
  confirmando: 'Confirmando laudo',
  confirmarAlteracaoDescricaoRede: 'Deseja alterar a visualização de descritores da rede?',
  confirmarInsercaoDescricaoRede: 'Deseja cadastrar a visualização de descritores da rede?',
  sucessoInsercaoDescricaoRede: 'Visualização de descritores para rede inserida com sucesso',
  sucessoAlteracaoDescricaoRede: 'Visualização de descritores para rede alterada com sucesso',
  contraRefercia: 'Contra-referência',
  laudoImportante: 'Laudo importante',
  cronica: 'Crônica',
  dadosComplementaresIndicacaoClinica: 'Dados complementares da indicação clínica',
  indicacaoClinicaRealizacaoExame: 'Indicação clínica e realização do exame',
  dataHoraAtendimento: 'Data/hora do atendimento',
  dataUltimoEpisodio: 'Data do último episódio',
  descricaoExame: 'Descrição do exame',
  descricaoRede: 'Descrição da rede',
  descritorDuplicado: 'Esse descritor já foi adicionado',
  descritores: 'Descritores',
  descritoresAdicionados: 'Descritores adicionados',
  descritoresObrigatorios: 'Ao menos um descritor de qualquer tipo (SBC, CID-10 ou DECS) é obrigatório',
  descritorSBC: 'Descritor SBC',
  detalhesExame: 'Detalhes do exame',
  detalhesSolicitacao: 'Detalhes da solicitação',
  direcionados: 'Direcionado ao meu usuário',
  dorCardiacaIsquemica: 'Dor cardíaca isquêmica',
  dorCardiacaNaoIsquemica: 'Dor cardíaca não isquêmica',
  dorMomentoAvaliacaoMedica: 'Há dor no momento da avaliação médica?',
  dorMomentoExame: 'Dor no momento do exame',
  dorNaoCardiaca: 'Dor não cardíaca',
  dorToracica: 'Dor torácica',
  duracaoEpisodio: 'Duração do episódio',
  duracaoUltimoEpisodio: 'Duração do último episódio',
  emitirLaudo: 'Emitir laudo',
  envioExameClassificarDorToracica: 'Deseja classificar a dor torácica?',
  erroDescritoresV1: 'Os descritores utilizados no laudo anterior não são compatíveis com a nova versão dos descritores SBC, por isso foram ignorados e precisam ser adicionados novamente. A lista de descritores ignorados é a que segue:',
  erroGerarTermo: 'Não foi possível gerar o termo de autorização. Tente imprimir o termo através da tela de exames.',
  erroSortearExame: 'Não foi possível sortear o próximo exame para laudo. Tente novamente e se o problema persistir, entre em contato com os administradores.',
  erroTratarObesidade: 'O paciente possui classificação do estado nutricional "Obesidade" e, por isso, a opção "Obesidade" não pode ser desmarcada',
  erroTratarSobrepeso: 'O paciente possui classificação do estado nutricional "Sobrepeso" e, por isso, a opção "Sobrepeso" não pode ser desmarcada',
  exameDuplicado: 'Exame duplicado',
  exameMaisAntigoSemLaudo: 'Data do exame mais antigo sem laudo',
  examesAgendados: 'Agendados',
  examesComImagens: 'Com imagens',
  examesInvalidos: 'Inválidos',
  examesPacs: 'PACS',
  examesValidos: 'Válidos',
  fatoresRiscoObrigatorio: 'É obrigatório a adição de pelo menos um fator de risco',
  febreReumatica: 'Febre reumática',
  febreReumatica: 'Febre reumática',
  formularioSolicitacaoMed: 'Formulário de solicitação de medicamentos',
  gerandoResumoPublicacao: 'Gerando resumo do laudo para publicação',
  gerandoResumoVisualizacao: 'Gerando resumo do laudo para visualização',
  gravandoLaudo: 'Gravando laudo',
  hipoteseDiagnostica: 'Hipótese diagnóstica',
  historicoDor: 'Histórico da dor',
  identificacaoExame: 'Identificação do exame',
  ignorandoExame: 'Ignorando exame',
  intensidadeDor: 'Intensidade da dor',
  intensidadeDor: 'Intensidade da dor',
  instrucaoVisualizacaoDadosRede: `<span>- Parametrização da visualização de descritores por rede.</span>
    <span>- A partir dessa tela, é possível realizar a parametrização da visualização dos descritores por rede de telemedicina.</span>
    <span>- A partir dessa tela, também é possível alterar essa parametrização e inativá-la, caso seja necessário.</span>`,
  instrucaoMotivosPriorizacaoRede: `<span>- Parametrização da visualização de motivos de priorização por rede.</span>
    <span>- A partir dessa tela, é possível realizar a parametrização da visualização dos motivos de priorização de exame por rede de telemedicina.</span>
    <span>- A partir dessa tela, também é possível alterar essa parametrização e inativá-la, caso seja necessário.</span>`,
  invalido: 'Inválido',
  investigacaoDor: 'Investigação da dor',
  investigacaoDorToracica: 'Exame para investigação de dor torácica',
  isolada: 'Isolada',
  laudoEmEmissao: 'Devido à demora na emissão do laudo, este exame foi acessado por outro profissional. O próximo exame será carregado.',
  laudosEmitidos: 'Laudos emitidos',
  laudoTextual: 'Laudo textual',
  localizacaoIrradiacaoDor: 'Localização e irradiação da dor',
  localizacaoIrradiacaoDorObrigatoria: 'É obrigatório a adição de pelo menos uma localização ou irradiação da dor',
  medicamentosUso: 'Medicamentos em uso',
  medicamentosUsoObrigatorio: 'É obrigatório a adição de pelo menos um medicamento de uso',
  medicoExecutor: 'Médico executor',
  mensagemAlertaCancelamento: 'Você confirma o cancelamento do envio de imagens?',
  mensagemConfirmarEnvioExame: 'Deseja enviar os anexos do exame agora?',
  mensagemExameDuplicado: 'Esse exame é potencialmente duplicado. Você tem certeza que deseja enviar as imagens mesmo assim?',
  motivoExame: 'Motivo do exame',
  motivoExameNaoDor: 'Caso o motivo do exame não seja dor',
  motivosExame: 'Motivos do exame',
  motivosSolicitacaoObrigatorio: 'É obrigatório a escolha de pelo menos um motivo de solicitação de exame',
  naoFoiPossivelCarregarTotais: 'Não foi possível carregar os dados de produtividade',
  nenhumAnexo: 'Nenhum anexo encontrado',
  nenhumDescritorAdicionado: 'Nenhum descritor adicionado',
  nenhummExameParaLaudo: 'Nenhum exame disponível para laudo',
  numero_exame: 'Número do exame',
  observacaoTecnico: 'Observação do técnico',
  opcoes: 'Opções',
  outrasHipotesesDiagnosticas: 'Outras hipóteses diagnósticas',
  outrasInformacoes: 'Outras informações',
  outrosMedicamentos: 'Outros medicamentos',
  outroTipoDor: 'Outro tipo de dor',
  pacs: 'PACS',
  pesquisar: 'Pesquisar',
  avisoLaudoImportante: 'O laudo deste exame foi marcado como importante pelo médico laudador.',
  preOperatorio: 'Pré-operatório',
  protocoloMed: 'Protocolo de medicamento',
  proximoExame: 'Próximo exame',
  publicar: 'Publicar',
  qualquerTermo: 'Qualquer um dos termos',
  quantoTempoApresentaSintoma: 'Há quanto tempo apresenta sintoma?',
  realizacaoExame: 'Realização do exame',
  receitaMedica: 'Receita médica',
  recorrente: 'Recorrente',
  remover: 'Remover',
  requisicaoMaximo: 'A requisição deve conter no máximo 16 dígitos',
  responsavelEnvio: 'Responsável pelo envio',
  resumoLaudo: 'Resumo do laudo',
  sintomasAssociados: 'Sintomas associados',
  sintomasAssociadosObrigatorio: 'É obrigatório a adição de pelo menos um sintoma associado',
  situacao: 'Situação',
  Steps: {
    imagensGerais: 'Imagens gerais',
    informacoesGerais: 'Informações gerais'
  },
  sucessoRelaudo: 'Exame laudado com sucesso',
  tempoApresentaSintoma: 'Há quanto tempo o paciente apresenta sintoma',
  termosDescricao: 'Termos da descrição do exame',
  textoDescritorSBC: 'Utilize o campo abaixo para pesquisar descritores',
  tipoDor: 'Tipo de dor',
  tipoDorObrigatorio: 'É obrigatório a adição de pelo menos um tipo de dor',
  tituloConfirmarEnvioExame: 'Deseja enviar as imagens?',
  tituloModalCancelamento: 'Confirmação de cancelamento',
  todasImagens: 'Todas as imagens',
  todosTermos: 'Todos os termos',
  ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
  usuarioClassificouDorToracica: 'Usuário classificou dor torácica',
  validacaoDuracaoUltimoEpisodioTamanho: 'Deve ser especificado hora(s) e minuto(s)',
  valido: 'Válido',
  valvulopatia: 'Valvulopatia',
  visualizarDescricao: 'Visualizar descricao',
  visualizarInformacoesComplementares: 'Visualizar informações complementares',
  visualizarPrioridade: 'Visualizar prioridade',
  visualizarNome: 'Visualizar nome',
  visualizarSigla: 'Visualizar sigla',
  visualizarResumo: 'Visualizar resumo',
  visualizacaoDescritorRede: 'Visualização de descritores por rede',
  motivosPriorizacaoRede: 'Motivos de priorização por rede',
  exameNumero: 'Exame nº',
  laudoEmitidoExame: 'Laudo emitido para o exame',
  naoFoiPossivelLaudosGraves: 'Não foi possível carregar a lista de laudos graves',
  notificacao: 'Notificação',
  descritorGraveAdicionado: 'O laudo atual foi marcado como grave pois o último descritor adicionado possui prioridade 1',
  visualizarResumo: 'Visualizar resumo',
  redeTelemedicina: 'Rede de telemedicina',
  confirmarSalvar: 'Você deseja realmente salvar esse(s) motivo(s) de priorização por rede?',
  parametrizacaoMotivosPriorizacaoExame: 'Parametrização de motivos de priorização de exame',
  motivosPriorizacao: 'Motivos de priorização',
  motivosPriorizacaoAdicionados: 'Motivos de priorização adicionados',
  pacienteSintomatico: 'Paciente sintomático'
};