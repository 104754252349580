import { useState } from 'react';
import { Cache } from '@stt-componentes/cache';
import { XLSXUtil } from '@stt-analise/util';

const fetchData = async (payload) => {
    const BACKEND_URL = global.gConfig.url_base_graphql;
    const response = await fetch(`${BACKEND_URL}/exportar-lotes`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${Cache.getAccessToken()}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let rawData = '';
    const readData = async () => {
        console.log(1);
        const { done, value } = await reader.read();
        if (done) {
            return;
        }

        // Acumula os dados brutos
        rawData += decoder.decode(value);
        await readData();
    };
    await readData();
    console.log(rawData);
    return JSON.parse(rawData);
};

export default function useExportarRelatorio() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const reqExportar = async ({ nomeArquivo, tipoArquivo, payload }) => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchData(payload);
            XLSXUtil.downloadBrowser(data, nomeArquivo, tipoArquivo);
            setLoading(false);
            return data;
        } catch (error) {
            setError(error);
            setLoading(false);
            throw error;
        }
    };

    return { reqExportar, loading, error };
}
