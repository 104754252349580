import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Divider } from '@material-ui/core';

const Fieldset = ({ title = '' }) => {
    return (
        <Grid style={{ paddingTop: '8px' }} item xs={12} sm={12} md={12}>
            <Typography variant="h3" component="h3">
                {title}
            </Typography>
            <Divider />
        </Grid>
    );
};

Fieldset.propTypes = {
    title: PropTypes.string,
};

export default Fieldset;
