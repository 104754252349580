const moment = require('moment');
const FiltroConstants = require('../../constants/FiltroConstants').default;
module.exports = {
  cubo: 'cubo_itinerario_solicitacoes',
  primeiraConsultaAutomatica: false,
  atributosSelecionados: ['__time', 'nome_cidade', 'nome_estado', 'perfil_solicitante', 'nome_funcionario_solicitacao', 'protocolo_exame', 'qnt' // Sempre colocar o último atributo como métrica.
  ],
  charts: [{
    title: 'Total de solicitações de câncer de pele',
    type: 'CardAbsoluteChart',
    selectDimensions: ['protocolo_exame', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Câncer de Pele",
    transformations: []
    // Contagem do número total de solicitações de exames de dermatologia do tipo "câncer de pele" registrados em banco de dados. 
    // Este card pode ter seus dados filtrados pela variável "Data de solicitação" presente no dashboard.
  }, {
    title: 'Total de solicitações de hanseníase',
    type: 'CardAbsoluteChart',
    selectDimensions: ['protocolo_exame', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Hanseníase%",
    transformations: []
    // Contagem do número total de solicitações de exames de dermatologia do tipo "hanseníase" registrados em banco de dados. Além disso, somam-se solicitações de "outras dermatoses" onde o campo "sensibilidade_hanseniase" tem seus valores iguais a "A" ou "D".
    // Ou seja, soma-se solicitações de "hanseníase" com "outras dermatoses" onde o campo "sensibilidade_hanseniase = A|D"
    // D = diminuída.
    // A = ausente.
    // Este card pode ter seus dados filtrados pela variável "Data de solicitação" presente no dashboard.
  }, {
    title: 'Total de solicitações de psoríase',
    type: 'CardAbsoluteChart',
    selectDimensions: ['protocolo_exame', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Psoríase",
    transformations: []
    // Contagem do número total de solicitações de exames de dermatologia do tipo "psoríase" registrados em banco de dados. 
    // Este card pode ter seus dados filtrados pela variável "Data de solicitação" presente no dashboard.
  }, {
    title: 'Total de solicitações de outras dermatoses',
    type: 'CardAbsoluteChart',
    selectDimensions: ['protocolo_exame', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Outras Dermatoses%",
    transformations: []
    // Contagem do número total de solicitações de exames de dermatologia do tipo "outras dermatoses" registrados em banco de dados. 
    // Este card pode ter seus dados filtrados pela variável "Data de solicitação" presente no dashboard.
  }, {
    title: 'Total de solicitações de exames preenchidas através do STT feitos pelos médicos',
    type: 'CardAbsoluteChart',
    selectDimensions: ['perfil_solicitante', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Médico Solicitante%",
    transformations: []
    // Contagem do número total de solicitações de exames de dermatologia que foram enviadas ao sistema por usuários que possuem o perfil "médico solicitante". 
    // Este card pode ter seus dados filtrados pela variável "Data de solicitação" presente no dashboard.
  }, {
    title: 'Total de solicitações transcritas no STT através de ficha específica e digitadas pelos técnicos',
    type: 'CardAbsoluteChart',
    selectDimensions: ['perfil_solicitante', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Técnico%",
    transformations: []
    // Contagem do número total de solicitações de exames de dermatologia que foram enviadas ao sistema por usuários que possuem o perfil "técnico". 
    // Este card pode ter seus dados filtrados pela variável "Data de solicitação" presente no dashboard.
  }, {
    title: 'Médicos que solicitaram exames',
    type: 'CardPercentageChart',
    selectDimensions: ['perfil_solicitante', 'qnt'],
    filters: [{
      attribute: 'perfil_solicitante',
      filter: value => value.includes('Médico Solicitante') || value.includes('Técnico')
    }],
    filtersRemote: [],
    having: "Médico Solicitante",
    transformations: [{
      attributes: ['perfil_solicitante'],
      transformation: value => value.includes('Médico Solicitante') ? 'Médico Solicitante' : value
    }]
    // Dado o número de usuários distintos que solicitaram exames de dermatologia, traz a porcentagem destes usuários que possuem o perfil "médico solicitante". 
    // Este card pode ter seus dados filtrados pela variável "Data de solicitação" presente no dashboard.
  }, {
    title: 'Técnicos que transcrevem fichas pelo STT',
    type: 'CardPercentageChart',
    selectDimensions: ['perfil_solicitante', 'qnt'],
    filters: [{
      attribute: 'perfil_solicitante',
      filter: value => value.includes('Médico Solicitante') || value.includes('Técnico')
    }],
    filtersRemote: [],
    having: "Técnico",
    transformations: [{
      attributes: ['perfil_solicitante'],
      transformation: value => value.includes('Técnico') ? 'Técnico' : value
    }]
    // Dado o número de usuários distintos que solicitaram exames de dermatologia, traz a porcentagem destes usuários que possuem o perfil "técnico". 
    // Este card pode ter seus dados filtrados pela variável "Data de solicitação" presente no dashboard.
  }],
  metaAtributos: [{
    atributo: '__time',
    grupo: '0.0 - Variáveis gerais',
    key: 'cubo_itinerario_solicitacoes_variaveis_gerais___time',
    rotulo: 'Data da solicitação',
    tipo: 'DATA_HORA'
  }],
  filtros: [{
    key: 1,
    disabled: false,
    atributosPermitidos: ['__time'],
    atributo: '__time',
    tipoComponente: 'BETWEEN_INCLUSIVE_TIMESTAMP_MS',
    valor: () => {
      const end = moment().toDate();
      const start = moment().subtract(6, 'month').toDate();
      return [start, end];
    }
  }]
};