'use strict';

const Util = require('../util/util');
const {
  ValidationError
} = require('../erros');
const Localizacao = require('./localizacao');
const Paciente = {
  pesquisar: async (dbClient, idPaciente, idEnderecoPaciente) => {
    const consulta = dbClient('public.paciente AS p');
    consulta.where('p.id', '=', idPaciente);
    consulta.select('p.*');
    const paciente = await consulta.first();
    paciente.contatos = await Paciente.buscarContatos(dbClient, idPaciente);
    if (paciente.id_sexo) {
      paciente.sexo = await Paciente.buscarSexo(dbClient, paciente.id_sexo);
    }
    if (paciente.id_raca) {
      paciente.raca = await Paciente.buscarRaca(dbClient, paciente.id_raca);
    }
    if (paciente.id_cidade_nascimento) {
      paciente.cidade_nascimento = await Localizacao.buscarEstadoPaisPorCidade(dbClient, {
        idCidade: paciente.id_cidade_nascimento
      });
      if (paciente.cidade_nascimento && paciente.cidade_nascimento.length > 0) {
        paciente.cidade_nascimento = paciente.cidade_nascimento[0];
      }
    }
    if (idEnderecoPaciente) {
      paciente.endereco = await Paciente.buscarEnderecoPaciente(dbClient, idEnderecoPaciente);
    } else if (paciente.id_endereco_paciente) {
      paciente.endereco = await Paciente.buscarEnderecoPaciente(dbClient, paciente.id_endereco_paciente);
    }
    return paciente;
  },
  buscarRaca: async (dbClient, idRaca) => {
    return await dbClient('public.raca as r').select('r.*').where('r.id', '=', idRaca).first();
  },
  buscarSexo: async (dbClient, idSexo) => {
    return await dbClient('public.sexo as s').select('s.*').where('s.id', '=', idSexo).first();
  },
  buscarContatos: async (dbClient, idPaciente) => {
    let consulta = dbClient('public.contato_paciente AS cp').join('public.tipo_contato AS tc', 'cp.id_tipo_contato', '=', 'tc.id').where('cp.id_paciente', '=', idPaciente);
    return consulta.select('cp.contato', 'tc.id AS id_tipo_contato', 'tc.descricao AS descricao_tipo_contato', 'tc.categoria AS categoria_tipo_contato');
  },
  buscarEnderecoPaciente: async (dbClient, idEnderecoPaciente) => {
    let consulta = dbClient('public.endereco_paciente AS ep').leftJoin('public.pais AS p', 'ep.id_pais', '=', 'p.id').leftJoin('public.estado AS e', 'ep.id_estado', '=', 'e.id').leftJoin('public.cidade AS c', 'ep.id_cidade', '=', 'c.id');
    consulta.where('ep.id', '=', idEnderecoPaciente);
    return consulta.select('ep.cep', 'ep.logradouro', 'ep.bairro', 'ep.numero', 'p.nome AS pais', 'p.id AS id_pais', 'p.sigla AS sigla_pais', 'e.nome AS estado', 'e.id AS id_estado', 'e.sigla AS sigla_estado', 'c.nome AS cidade', 'c.id AS id_cidade').first();
  },
  salvar: async (dbClient, transactionHandler, paciente) => {
    let id = paciente.id;
    let cpf = null;
    let pacienteCpf = null;
    let pacienteCns = null;
    if (paciente.cpf) {
      cpf = await Util.removerMascaraNumero(paciente.cpf);
    }
    if (!id) {
      // Verifica se existe paciente cadastrado com o CPF e/ou CNS.
      // Se existir gera erro, pois nesse caso o paciente deve ser 
      // selecionado através da busca e não ter seus dados preenchidos
      // como se fosse um paciente novo.
      if (cpf) {
        pacienteCpf = await Paciente.buscarPorCpf(dbClient, cpf);
      }
      if (paciente.cns) {
        pacienteCns = await Paciente.buscarPorCns(dbClient, paciente.cns);
      }
      if (pacienteCpf && pacienteCpf.length > 0 && pacienteCns && pacienteCns.length > 0) {
        throw new ValidationError('Já existe um paciente com o CPF e o CNS informados');
      } else if (pacienteCpf && pacienteCpf.length > 0) {
        throw new ValidationError('Já existe um paciente com o CPF informado');
      } else if (pacienteCns && pacienteCns.length) {
        throw new ValidationError('Já existe um paciente com o CNS informado');
      }
    }
    const dados = {
      nome: paciente.nome,
      cpf: cpf,
      cartao_sus: paciente.cns,
      id_sexo: paciente.genero.id,
      data_nascimento: paciente.dataNascimento
    };
    if (paciente.nomeMae) {
      dados.nome_mae = paciente.nomeMae;
    }
    if (paciente.grupoSanguineo) {
      dados.grupo_sanguineo = paciente.grupoSanguineo;
    }
    if (paciente.fatorRh) {
      dados.fator_rh = paciente.fatorRh;
    }
    let idEndereco;
    if (id) {
      await dbClient('public.paciente').where('id', '=', id).update(dados).transacting(transactionHandler);
    } else {
      const result = await dbClient.insert(dados).into('public.paciente').returning('id').transacting(transactionHandler);
      id = result[0];
    }
    if (paciente['id_endereco_paciente']) {
      let enderecoPaciente = {
        logradouro: paciente.logradouro,
        bairro: paciente.bairro,
        cep: paciente.cep,
        municipio: paciente.municipio,
        uf: paciente.uf,
        pais: paciente.pais,
        numero: paciente.numero,
        id_paciente: id
      };
      //Altera os dados do endereço do paciente
      idEndereco = await Paciente.atualizarEndereco(dbClient, transactionHandler, paciente.id_endereco_paciente, enderecoPaciente);
    } else {
      // Salva dados de endereço
      idEndereco = await Paciente.salvarEndereco(dbClient, transactionHandler, id, paciente);
    }

    // Relaciona o endereço ao paciente
    await dbClient('public.paciente').where('id', '=', id).update({
      id_endereco_paciente: idEndereco
    }).transacting(transactionHandler);

    // Salva os contatos
    await Paciente.salvarContatos(dbClient, transactionHandler, id, paciente);
    paciente.id = id;
    paciente.id_endereco_paciente = idEndereco;
    return paciente;
  },
  /**
   * Cria um registro de paciente com os dados mínimos necessários
   * @param {*} dbClient 
   * @param {*} transactionHandler 
   * @param {*} paciente 
   */
  salvarMinimo: async (dbClient, transactionHandler, paciente) => {
    let cpf = null;
    let pacienteCpf = null;
    if (paciente.cpf) {
      cpf = await Util.removerMascaraNumero(paciente.cpf);
    }

    // Verifica se existe paciente cadastrado com o CPF e/ou CNS.
    // Se existir gera erro, pois nesse caso o paciente deve ser 
    // selecionado através da busca e não ter seus dados preenchidos
    // como se fosse um paciente novo.
    if (cpf) {
      pacienteCpf = await Paciente.buscarPorCpf(dbClient, cpf);
    }
    if (pacienteCpf && pacienteCpf.length > 0) {
      throw new ValidationError('Já existe um paciente com o CPF informado');
    }
    const dados = {
      nome: paciente.nome,
      cpf: cpf,
      id_sexo: paciente.genero.id,
      data_nascimento: paciente.dataNascimento,
      nome_mae: paciente.nomeMae
    };
    const result = await dbClient.insert(dados).into('public.paciente').returning('id').transacting(transactionHandler);
    const idPaciente = result[0];
    return {
      id: idPaciente
    };
  },
  atualizar: async (dbClient, transactionHandler, idPaciente, dados) => {
    await dbClient('public.paciente').where('id', '=', idPaciente).update(dados).transacting(transactionHandler);
  },
  atualizarProvisorio: async (dbClient, transactionHandler, idPaciente, dados) => {
    await dbClient('public.paciente_provisorio').where('id', '=', idPaciente).update(dados).transacting(transactionHandler);
  },
  salvarEndereco: async (dbClient, transactionHandler, idPaciente, endereco) => {
    const dados = {
      id_paciente: idPaciente,
      logradouro: endereco.logradouro,
      bairro: endereco.bairro
    };
    if (endereco.cep) {
      dados.cep = endereco.cep;
    }
    if (endereco.municipio) {
      dados.id_cidade = endereco.municipio.id;
    }
    if (endereco.uf) {
      dados.id_estado = endereco.uf.id;
    }
    if (endereco.pais) {
      dados.id_pais = endereco.pais.id;
    }
    if (endereco.numero) {
      dados.numero = endereco.numero;
    }
    const result = await dbClient.insert(dados).into('public.endereco_paciente').returning('id').transacting(transactionHandler);
    return result[0];
  },
  atualizarEndereco: async (dbClient, transactionHandler, idEndereco, endereco) => {
    const dados = {
      id_paciente: endereco.idPaciente,
      logradouro: endereco.logradouro,
      bairro: endereco.bairro
    };
    if (endereco.cep) {
      dados.cep = endereco.cep;
    }
    if (endereco.municipio) {
      dados.id_cidade = endereco.municipio.id;
    }
    if (endereco.uf) {
      dados.id_estado = endereco.uf.id;
    }
    if (endereco.pais) {
      dados.id_pais = endereco.pais.id;
    }
    if (endereco.numero) {
      dados.numero = endereco.numero;
    }
    const result = await dbClient('public.endereco_paciente').where('id', '=', idEndereco).update(dados).returning('id').transacting(transactionHandler);
    return result[0];
  },
  salvarContatos: async (dbClient, transactionHandler, idPaciente, dados) => {
    // Remove todos os contatos do paciente
    await Paciente.removerContatos(dbClient, transactionHandler, idPaciente);
    // Insere o primeiro contato, caso tenha sido informado
    if (dados.tipoContato1 && dados.contato1) {
      let contato1 = await Util.removerMascaraNumero(dados.contato1);
      await dbClient.insert({
        id_paciente: idPaciente,
        id_tipo_contato: dados.tipoContato1,
        contato: contato1,
        contato_whatsapp: dados.contato1Whatsapp ? true : false
      }).into('public.contato_paciente').transacting(transactionHandler);
    }

    // Insere o segundo contato, caso tenha sido informado
    if (dados.tipoContato2 && dados.contato2) {
      let contato2 = await Util.removerMascaraNumero(dados.contato2);
      await dbClient.insert({
        id_paciente: idPaciente,
        id_tipo_contato: dados.tipoContato2,
        contato: contato2,
        contato_whatsapp: dados.contato2Whatsapp ? true : false
      }).into('public.contato_paciente').transacting(transactionHandler);
    }
    if (dados.tipoContatoEmail && dados.email) {
      await dbClient.insert({
        id_paciente: idPaciente,
        id_tipo_contato: dados.tipoContatoEmail,
        contato: dados.email,
        contato_whatsapp: false
      }).into('public.contato_paciente').transacting(transactionHandler);
    }
  },
  removerContatos: async (dbClient, transactionHandler, idPaciente) => {
    await dbClient('public.contato_paciente').where('id_paciente', '=', idPaciente).del().transacting(transactionHandler);
  },
  buscarPorCns: async (dbClient, cns) => {
    return await dbClient('public.paciente AS p').where('p.cartao_sus', '=', cns);
  },
  buscarPorCpf: async (dbClient, cpf) => {
    return await dbClient('public.paciente AS p').where('p.cpf', '=', cpf);
  }
};
module.exports = Paciente;