import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
    },
    box: {
        marginLeft: 0,
        marginRight: 10,
        backgroundColor: theme?.palette?.background?.default
    },
    label: {
        fontWeight: 'bold',
        color: theme?.palette?.text?.primary || 'black'
    },
    value: {
        fontWeight: 'normal',
        color: theme?.palette?.text?.primary || 'black',
        marginLeft: 5,
    },
}));

const getValues = ({ datasetDruid, labels }) => {
    if (!labels) throw new Error(`Adicionar labels para o CardLabels.`);
    if (datasetDruid.length < 2) return [null, null];

    let newLabels = [], values = [];
    const datasetDruidCopy = [...datasetDruid];
    const headers = datasetDruidCopy.shift();
    const row = datasetDruidCopy.shift();

    for (const key in labels) {
        const index = headers.findIndex(e => e === key);
        if (index < 0) continue;
        newLabels.push(labels[key]);
        values.push(row[index]);
    }

    return [newLabels, values];
};

export default function CardLabels({ datasetDruid, labels }) {
    const classes = useStyles();

    const [newLabels, values] = getValues({ datasetDruid, labels });

    return (
        <Card className={classes.root}>
            <Box display="flex" flexWrap="wrap">
                {newLabels.map((label, index) =>
                    <Box key={index} p={1} order={1} className={classes.box}>
                        <Typography component="span" className={classes.label} >
                            {label}:
                        </Typography>
                        <Typography component="span" className={classes.value} >
                            {values[index]}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Card>
    );
}