import React, { useContext, useEffect, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { SttButton } from '@stt-componentes/core';
import { SttTranslateHook } from '@stt-componentes/core';
import { FiltroUtil } from '@stt-analise/util';
import { FiltroSecundario } from '../Filter/FiltroSecundario';
import { useLazyQuery } from '@apollo/client';
import { GERAR_DATASET } from '../../requests/graphql';
import { DashboardContainer } from './DashboardContainer';
import TitleCube from './Title/TitleCube';

/* @useSignals */
import { setOpenAlert } from 'src/signals/alertaSignal';

// styles
const useStyles = makeStyles(() => ({
    button: {
        margin: '8px',
    },
}));

const GridRowButtonExecutar = ({ habilitarBotaoExecutar = true, disabledButton = true, executarConsultaFn }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    return (
        habilitarBotaoExecutar && (
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <SttButton
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            disabled={disabledButton}
                            loading="true"
                            onClick={() => {
                                executarConsultaFn();
                            }}
                        >
                            {strings.Comuns.executarConsulta}
                        </SttButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    );
};

const GridRowCharts = ({
    resultCharts,
    onResultObj,
    charts,
    primeiraConsultaAutomatica = false,
    executarConsultaFn,
    called,
    loading,
    data,
    filtrosSecundarios,
}) => {
    useEffect(() => {
        if (primeiraConsultaAutomatica) {
            executarConsultaFn();
        }
    }, []);

    function dadosNaoEncontrados() {
        setOpenAlert(true, 'ALE001');
        return 'Dados não encontrados!';
    }

    const blockCharts = useMemo(() => {
        let chartView = <span></span>;
        if (called && loading) {
            chartView = <span>Loading...</span>;
        } else if (data && data['iScanDataset']) {
            const valid = data['iScanDataset'].length > 1;
            chartView = valid ? (
                <DashboardContainer dataset={data['iScanDataset']} options={charts} onResultObj={onResultObj} filtrosSecundarios={filtrosSecundarios} />
            ) : (
                dadosNaoEncontrados()
            );
        }
        return chartView;
    }, [called, loading, data, charts]);

    return (
        <Grid item xs={12}>
            {blockCharts}
            {resultCharts}
        </Grid>
    );
};

function Dashboard({ title, filtrosSecundarios, configFiltroSecundario, setFiltrosSecundarios, resultCharts, onResultObj, disabledButton = false }) {
    const [carregarDataset, { called, error, loading, data }] = useLazyQuery(GERAR_DATASET, {
        fetchPolicy: 'network-only',
    });

    const executarConsultaFn = () => {
        const preFiltros = FiltroUtil.normalizeFiltros(filtrosSecundarios);

        carregarDataset({
            variables: {
                cubo: configFiltroSecundario.cubo,
                atributos: configFiltroSecundario.atributosSelecionados,
                filtros: preFiltros.map((f) => ({
                    atributo: f.atributo,
                    tipoComponente: f.tipoComponente,
                    valor: f.valor,
                })),
                medidas: configFiltroSecundario.medidas,
            },
        });
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TitleCube title={title} cubo={configFiltroSecundario.cubo} />
                </Grid>

                <FiltroSecundario
                    filtros={filtrosSecundarios}
                    cubo={configFiltroSecundario.cubo}
                    metaAtributos={configFiltroSecundario.metaAtributos}
                    onChangeFilter={(filtroAlterado) => {
                        const novoFiltrosSecundarios = filtrosSecundarios.map((filtro) => {
                            if (filtro.key == filtroAlterado.key) {
                                return filtroAlterado;
                            }
                            return filtro;
                        });
                        setFiltrosSecundarios(novoFiltrosSecundarios);
                    }}
                    preFiltros={FiltroUtil.normalizeFiltros(filtrosSecundarios)}
                />

                <GridRowButtonExecutar
                    habilitarBotaoExecutar={configFiltroSecundario.habilitarBotaoExecutar}
                    disabledButton={disabledButton}
                    executarConsultaFn={executarConsultaFn}
                />

                <GridRowCharts
                    resultCharts={resultCharts}
                    onResultObj={onResultObj}
                    charts={configFiltroSecundario.charts}
                    primeiraConsultaAutomatica={configFiltroSecundario.primeiraConsultaAutomatica}
                    executarConsultaFn={executarConsultaFn}
                    called={called}
                    loading={loading}
                    data={data}
                    filtrosSecundarios={filtrosSecundarios}
                />
            </Grid>
        </>
    );
}

export default Dashboard;
