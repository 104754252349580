import React, { useState, useEffect, useContext } from 'react';
import { ItinerarioCidadesSpec, ItinerarioCidadesUfscSpec } from '@stt-analise/util';
import { SttTranslateHook } from '@stt-componentes/core';
import Dashboard from '../../../components/Dashboard/Dashboard';

let configFiltroSecundario = ItinerarioCidadesSpec;

/**
 * RN - Regra de negócio para permitir a pesquisa pelos administradores.
 */
const validarFiltros = () => {
    return true;
};

function ItinerarioCidadesDashboard() {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    let titulo = strings.PaginaInicial.dashboardItinerarioCidades;
    if (global.gConfig.config_id === 'ufsc') {
        titulo = strings.PaginaInicial.dashboardItinerarioCidadesUfsc;
        configFiltroSecundario = ItinerarioCidadesUfscSpec;
    }

    const [filtrosSecundarios, setFiltrosSecundarios] = useState(configFiltroSecundario.filtros);
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        const valid = validarFiltros(filtrosSecundarios);
        setDisabledButton(!valid);
    }, [filtrosSecundarios]);

    return (
        <Dashboard
            title={titulo}
            configFiltroSecundario={configFiltroSecundario}
            disabledButton={disabledButton}
            filtrosSecundarios={filtrosSecundarios}
            setFiltrosSecundarios={setFiltrosSecundarios}
        />
    );
}

export default ItinerarioCidadesDashboard;
