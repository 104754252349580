import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';

import { Grid, Container } from '@material-ui/core';
import { SttButton, SttSelect, SttMenuItem, SttModal, SttInput, SttLoading } from '@stt-componentes/core';

import { SttTranslateHook } from '@stt-componentes/core';

/* @useSignals */
import { pesquisaSignal } from 'src/signals/tabelaDados/tabelaDadosSignal';
import { useSignalEffect } from '@preact/signals-react';

function TabelaDadosModalSalvarRelatorio({ handleSalvarRelatorio, nomeRelatorioInicial = '' }) {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [nomeRelatorio, setNomeRelatorio] = useState(nomeRelatorioInicial);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setNomeRelatorio(nomeRelatorioInicial);
    }, [nomeRelatorioInicial]);

    function handleClose() {
        pesquisaSignal.value = { ...pesquisaSignal.value, openModalSalvarRelatorio: false };
        setNomeRelatorio(nomeRelatorioInicial);
    }

    function handleSalvar() {
        handleSalvarRelatorio(nomeRelatorio);
    }

    useSignalEffect(() => {
        setOpen(pesquisaSignal.value.openModalSalvarRelatorio);
    });

    return (
        <SttModal
            title={strings.Comuns.salvarRelatorio}
            open={open}
            iconClose={handleClose}
            outModalClose={handleClose}
            footer={
                <div>
                    <SttButton variant="contained" color="primary" onClick={handleSalvar}>
                        {strings.Comuns.salvar}
                    </SttButton>
                    <SttButton variant="contained" color="primary" onClick={handleClose}>
                        {strings.Comuns.cancelar}
                    </SttButton>
                </div>
            }
        >
            <Container maxWidth="md">
                <SttInput
                    autoFocus
                    value={nomeRelatorio}
                    onChange={(e) => {
                        setNomeRelatorio(e.target.value);
                    }}
                    style={{ minWidth: '300px' }}
                    label={strings.Comuns.nomeRelatorio}
                    required
                />
            </Container>
        </SttModal>
    );
}

export default TabelaDadosModalSalvarRelatorio;
