import React from 'react';
import Paper from '@material-ui/core/Paper';

import VirtualizedTable from './MuiVirtualizedTable';
import { ConvertTable } from '@stt-analise/util';

export default function ReactVirtualizedTable({ labels, datasetDruid, limitTable }) {
    const { rows, columns } = ConvertTable.toMui([...datasetDruid], labels, limitTable);
    
    return (
        <Paper elevation={0} style={{ height: 420, width: '100%' }}>
            <VirtualizedTable rowCount={rows.length} rowGetter={({ index }) => rows[index]} columns={columns} />
        </Paper>
    );
}