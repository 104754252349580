import TipoAtributo from "../constants/TipoAtributo";
const isNumber = tipoAtributo => {
  return tipoAtributo.includes(TipoAtributo.NUMERICO);
};
const isGeo = tipoAtributo => {
  return tipoAtributo.includes(TipoAtributo.TEXTO_GEO);
};
const isText = tipoAtributo => {
  return tipoAtributo.includes(TipoAtributo.TEXTO);
};
export default {
  isNumber,
  isGeo,
  isText
};