const SPLIT_VALUES = ';,';
const TIPO_FILTRO = {
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  LIKE: 'LIKE',
  NOT_LIKE: 'NOT_LIKE',
  IN: 'IN',
  IN_SELECT: 'IN_SELECT',
  NOT_IN: 'NOT_IN',
  BETWEEN_INCLUSIVE_NUMBER: 'BETWEEN_INCLUSIVE_NUMBER',
  BETWEEN_INCLUSIVE_TIME: 'BETWEEN_INCLUSIVE_TIME',
  BETWEEN_INCLUSIVE_TIMESTAMP_MS: 'BETWEEN_INCLUSIVE_TIMESTAMP_MS'
};
export default {
  SPLIT_VALUES,
  TIPO_FILTRO
};