import React from 'react';
import { Grid, Box } from '@material-ui/core';

// import BarChartHorizontal from './BarChartHorizontal';
import ApexBarChartHorizontal from './Apex/ApexBarChartHorizontal';
import { CardAbsoluteChart, CardPercentageChart, CardPercentage2Chart, CardAverageChart, CardDistinctCountChart } from './Card/CardChart';
import { DatasetDruid } from '@stt-analise/util';

import ReactVirtualizedTable from './Table/ReactVirtualizedTable';
import File from './File/File';
import PsicometricaChart from './Plotly/PsicometricaChart';
import CardLabels from './Card/CardLabels';
import Questionario from './Alofono/Questionario';
import ApexPieChart from './Apex/ApexPieChart';
import ApexColumnChart from './Apex/ApexColumnChart';
import TitleContainer from './Title/TitleContainer';
import DashboardInner from './DashboardInner';
import MapChart from './Map/MapChart';
import DownloadXLSXTableButton from './Download/DownloadXLSXTableButton';

const ContainerGraph = ({ dataset, option, onResultObj, filtrosSecundarios }) => {

    const datasetDruid = new DatasetDruid(dataset)
        .applyFilters(option.filters)
        .applyTransformations(option.transformations)
        .addAttribute(option.addAttribute || {})
        .addAttributes(option.addAttributes || [])
        .selectDimensions(option.selectDimensions)
        .flatMap(option.flatMap || {})
        .groupBy({ filterAgg: option.filterAgg, op: option.groupByOp, strAgg: option.groupByStrAgg })
        .groupByV2(option.groupByV2)
        .applyTransformations(option.postTransformations)
        .reduceDimensions(option.reduceDimensions)
        .topGroup({ limit: option.topGroupLimit, textOther: option.topGroupTextOther });

    return (
        <>
            {option.type === 'ApexBarChart' && (
                <Grid item sm={12} md={6} lg={2 * (option.w || 3)}>
                    <Box boxShadow={2} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '15px' }}>
                        <Grid item xs={12}>
                            <TitleContainer title={option.title} description={option.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <ApexBarChartHorizontal
                                datasetDruid={datasetDruid.build()}
                                title={option.title}
                                uiConfig={option.uiConfig}
                            />
                        </Grid>
                    </Box>
                </Grid>
            )}
            {option.type === 'ApexColumnChart' && (
                <Grid item sm={12} md={6} lg={2 * (option.w || 3)}>
                    <Box boxShadow={2} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '15px' }}>
                        <Grid item xs={12}>
                            <TitleContainer title={option.title} description={option.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <ApexColumnChart
                                datasetDruid={datasetDruid.build()}
                                title={option.title}
                                uiConfig={option.uiConfig}
                            />
                        </Grid>
                    </Box>
                </Grid>
            )}
            {option.type === 'Inner' && (
                <DashboardInner dataset={datasetDruid.build()} option={option} filtrosSecundarios={filtrosSecundarios} />
            )}
            {option.type === 'ApexPieChart' && (
                <Grid item sm={12} md={6} lg={2 * (option.w || 2)} xl={2 * (option.w || 2)}>
                    <Box boxShadow={2} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '15px' }}>
                        <Grid item xs={12}>
                            <TitleContainer title={option.title} description={option.description} />
                        </Grid>
                        <Grid item xs={12} style={{ padding: "15px 0px 0px 0px" }}>
                            <ApexPieChart
                                datasetDruid={datasetDruid.build()}
                                uiConfig={option.uiConfig}
                            />
                        </Grid>
                    </Box>
                </Grid>
            )}
            {option.type === 'TableChart' && (
                <Grid item sm={12} md={6} lg={2 * (option.w || 3)}>
                    <Box boxShadow={2} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '15px' }}>
                        <Grid item xs={12}>
                            <TitleContainer title={option.title} description={option.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactVirtualizedTable
                                datasetDruid={datasetDruid.build()}
                                labels={option.labels}
                                limitTable={option.limitTable}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="center" style={{ marginTop: '10px' }}>
                                <DownloadXLSXTableButton datasetDruid={datasetDruid.build()} labels={option.labels} nomeArquivo={option.title} />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            )}
            {option.type === 'File' && (
                <Grid item sm={12} md={6} lg={2 * (option.w || 3)}>
                    <Box boxShadow={2} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '15px', height: '100%' }}>
                        <Grid item xs={12}>
                            <TitleContainer title={option.title} description={option.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <File
                                datasetDruid={datasetDruid.build()}
                                {...option}
                            />
                        </Grid>
                    </Box>
                </Grid>
            )}
            {option.type === 'CardAbsoluteChart' && (
                <Grid item sm={6} md={4} lg={2 * (option.w || 1)}>
                    <CardAbsoluteChart
                        datasetDruid={datasetDruid.build()}
                        title={option.title}
                        description={option.description}
                        having={option.having}
                    />
                </Grid>
            )}
            {option.type === 'CardAverageChart' && (
                <Grid item sm={6} md={4} lg={2 * (option.w || 1)}>
                    <CardAverageChart
                        datasetDruid={datasetDruid.build()}
                        title={option.title}
                        description={option.description}
                    />
                </Grid>
            )}
            {option.type === 'CardDistinctCountChart' && (
                <Grid item sm={6} md={4} lg={2 * (option.w || 1)}>
                    <CardDistinctCountChart
                        datasetDruid={datasetDruid.build()}
                        title={option.title}
                        description={option.description}
                    />
                </Grid>
            )}
            {option.type === 'CardPercentageChart' && (
                <Grid item sm={6} md={4} lg={2 * (option.w || 1)}>
                    <CardPercentageChart
                        datasetDruid={datasetDruid.build()}
                        title={option.title}
                        description={option.description}
                        having={option.having}
                        enableAbsoluteValue={option.enableAbsoluteValue}
                    />
                </Grid>
            )}
            {option.type === 'CardPercentage2Chart' && (
                <Grid item sm={6} md={4} lg={2 * (option.w || 1)}>
                    <CardPercentage2Chart
                        datasetDruid={datasetDruid.build()}
                        title={option.title}
                        description={option.description}
                        having={option.having}
                    />
                </Grid>
            )}
            {option.type === 'CardLabels' && (
                <Grid item sm={12} md={12} lg={2 * (option.w || 6)}>
                    <CardLabels
                        datasetDruid={datasetDruid.build()}
                        labels={option.labels}

                    />

                </Grid>
            )}
            {option.type === 'MapChart' && (
                <Grid item sm={12} md={6} lg={2 * (option.w || 3)}>
                    <Box boxShadow={2} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '15px' }}>
                        <Grid item xs={12}>
                            <TitleContainer title={option.title} description={option.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <MapChart datasetDruid={datasetDruid.build()} metaAtributos={option.metaAtributos} />
                        </Grid>
                    </Box>
                </Grid>
            )}
            {option.type === 'Psicometrica' && (
                <Grid item sm={12} md={12} lg={2 * (option.w || 6)}>
                    <Box boxShadow={2} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '15px' }}>
                        <Grid item xs={12}>
                            <TitleContainer title={option.title} description={option.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <PsicometricaChart showLegend={option.showLegend} dataset={datasetDruid.build()} agg={option.agg} onResult={(result) => {
                                onResultObj({
                                    [option.idRes]: result
                                });
                            }} />
                        </Grid>
                    </Box>
                </Grid>
            )}
            {option.type === 'Questionario' && (
                <Grid item sm={12} md={12} lg={2 * (option.w || 6)}>
                    <Questionario
                        datasetDruid={datasetDruid.build()}
                        onResult={(result) => {
                            onResultObj({
                                [option.idRes]: result
                            });
                        }}
                    />
                </Grid>
            )}
        </>
    )
};

const DashboardContainer = ({ dataset, options, onResultObj = () => { }, filtrosSecundarios }) => {
    const charts = React.useMemo(() => options.map((option, i) => <ContainerGraph dataset={dataset} option={option} key={i} onResultObj={onResultObj} filtrosSecundarios={filtrosSecundarios} />), [options]);

    return (
        <Grid container spacing={1}>
            {charts}
        </Grid>
    );
};

const DashboardInnerContainer = ({ dataset, options, onResultObj = () => { }, filtrosSecundarios }) => {
    const charts = React.useMemo(() => options.map((option, i) => <ContainerGraph dataset={dataset} option={option} key={i} onResultObj={onResultObj} filtrosSecundarios={filtrosSecundarios} />), [options]);

    return (
        <>
            {charts}
        </>
    );
};

export { DashboardContainer, DashboardInnerContainer };
