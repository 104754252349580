import React from 'react';

import { Grid } from '@material-ui/core';
import { InWrapper } from './InWrapper';
import { InAutocompleteWrapper } from './InAutocompleteWrapper';
import CommonFilter from './CommonFilter';
import { TextSelectType } from './SelectType';

import { FiltroConstants } from '@stt-analise/util';

const WithTextSelectType = ({ children, filtro, onChangeFilter }) => {
    return (
        <>
            <Grid item xs={4} sm={4} md={2}>
                <TextSelectType filtro={filtro} onChangeFilter={onChangeFilter} />
            </Grid>
            <Grid item xs={8} sm={8} md={10}>
                {children}
            </Grid>
        </>
    );
};

export const TextFilter = ({ cubo, metaAtributoSelected, disabled, filtro, onChangeFilter, preFiltros }) => {
    let filter;
    switch (filtro.tipoComponente) {
        case FiltroConstants.TIPO_FILTRO.IN:
        case FiltroConstants.TIPO_FILTRO.NOT_IN:
            filter = (
                <WithTextSelectType filtro={filtro} onChangeFilter={onChangeFilter}>
                    <InWrapper cubo={cubo} metaAtributoSelected={metaAtributoSelected} onChangeFilter={onChangeFilter} filtro={filtro} />
                </WithTextSelectType>
            );
            break;
        case FiltroConstants.TIPO_FILTRO.IN_SELECT:
            filter = (
                <Grid item xs={12} sm={12} md={12}>
                    <InAutocompleteWrapper cubo={cubo} metaAtributoSelected={metaAtributoSelected} onChangeFilter={onChangeFilter} filtro={filtro} preFiltros={preFiltros} multiple={filtro.multiple} />
                </Grid>
            );
            break;
        case FiltroConstants.TIPO_FILTRO.EQUAL:
        case FiltroConstants.TIPO_FILTRO.LIKE:
        case FiltroConstants.TIPO_FILTRO.NOT_EQUAL:
        case FiltroConstants.TIPO_FILTRO.NOT_LIKE:
        case "":
            filter = (
                <WithTextSelectType filtro={filtro} onChangeFilter={onChangeFilter}>
                    <CommonFilter filtro={filtro} onChangeFilter={onChangeFilter} disabled={disabled} />
                </WithTextSelectType>
            );
            break;
        default:
            filter = <></>;
    }

    return (
        <Grid container spacing={3}>
            {filter}
        </Grid>
    );
};
