const moment = require('moment');
const FiltroConstants = require('../../constants/FiltroConstants').default;
module.exports = {
  cubo: 'cubo_itinerario_pontos',
  primeiraConsultaAutomatica: true,
  habilitarBotaoExecutar: false,
  atributosSelecionados: ['__time', 'descricao_ponto', 'situacao_ponto', 'qnt' // Sempre colocar o último atributo como métrica.
  ],
  charts: [{
    title: 'Total de pontos',
    type: 'CardAbsoluteChart',
    selectDimensions: ['qnt'],
    filters: [],
    filtersRemote: [],
    transformations: []
    // Contagem do número total de pontos (equipamentos) registrados em banco de dados. Este card inclui o somatório dos pontos ativos, inativos e bloqueados.
    // Ponto ativo = ponto habilitado para envio de exames.
    // Ponto inativo = ponto desabilitado para envio de exames (inativação automática após 6 meses sem enviar nenhum exame).
    // Ponto bloqueado = ponto bloqueado para envio de exames (bloqueio manual por um administrador do sistema).
  }, {
    title: 'Total de pontos ativos',
    type: 'CardAbsoluteChart',
    selectDimensions: ['situacao_ponto', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Ativo",
    transformations: []
    // Contagem do número total de pontos (equipamentos) ativos registrados em banco de dados. Este card inclui o somatório apenas dos pontos ativos.
  }, {
    title: 'Pontos ativos',
    type: 'CardPercentageChart',
    selectDimensions: ['situacao_ponto', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Ativo",
    transformations: []
    // Porcentagem de pontos (equipamentos) ativos registrados em banco de dados. Este card inclui apenas a porcentagem dos pontos ativos, comparando-os com os demais pontos.
  }, {
    title: 'Total de pontos inativos',
    type: 'CardAbsoluteChart',
    selectDimensions: ['situacao_ponto', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Inativo",
    transformations: []
    // Contagem do número total de pontos (equipamentos) inativos registrados em banco de dados. Este card inclui o somatório apenas dos pontos inativos.
  }, {
    title: 'Pontos inativos',
    type: 'CardPercentageChart',
    selectDimensions: ['situacao_ponto', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Inativo",
    transformations: []
    // Porcentagem de pontos (equipamentos) inativos registrados em banco de dados. Este card inclui apenas a porcentagem dos pontos inativos, comparando-os com os demais pontos.
  }, {
    title: 'Total de pontos bloqueados',
    type: 'CardAbsoluteChart',
    selectDimensions: ['situacao_ponto', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Bloqueado",
    transformations: []
    // Contagem do número total de pontos (equipamentos) bloqueados registrados em banco de dados. Este card inclui o somatório apenas dos pontos bloqueados.
  }, {
    title: 'Pontos bloqueados',
    type: 'CardPercentageChart',
    selectDimensions: ['situacao_ponto', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Bloqueado",
    transformations: []
    // Porcentagem de pontos (equipamentos) bloqueados registrados em banco de dados. Este card inclui apenas a porcentagem dos pontos bloqueados, comparando-os com os demais pontos.
  }, {
    title: 'Total de pontos desativados',
    type: 'CardAbsoluteChart',
    selectDimensions: ['situacao_ponto', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Desativado",
    transformations: []
    // Contagem do número total de pontos (equipamentos) desativados registrados em banco de dados. Este card inclui o somatório apenas dos pontos desativados.
  }, {
    title: 'Pontos desativados',
    type: 'CardPercentageChart',
    selectDimensions: ['situacao_ponto', 'qnt'],
    filters: [],
    filtersRemote: [],
    having: "Desativado",
    transformations: []
    // Porcentagem de pontos (equipamentos) desativados registrados em banco de dados. Este card inclui apenas a porcentagem dos pontos desativados, comparando-os com os demais pontos.
  }],
  metaAtributos: [],
  filtros: []
};