import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const CommonFilter = ({ filtro, onChangeFilter, disabled }) => {
    return (
        <>
            <TextField
                disabled={disabled}
                label="Valor"
                value={filtro.valor}
                variant="outlined"
                onChange={(event) => {
                    onChangeFilter({ ...filtro, valor: event.target.value });
                }}
                size="small"
                fullWidth
            />
        </>
    );
};

CommonFilter.propTypes = {
    filtro: PropTypes.object,
    onChangeFilter: PropTypes.func,
    disabled: PropTypes.bool,
};

CommonFilter.defaultProps = {
    filtro: {
        valor: '',
    },
    disabled: false,
};

export default CommonFilter;
