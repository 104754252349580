const moment = require('moment');
const {
  default: TipoAtributo
} = require('../../constants/TipoAtributo');
const {
  default: AggConstants
} = require('../../constants/AggConstants');
const {
  default: TipoVinculo
} = require('../../rbac/TipoVinculo');
const FiltroConstants = require('../../constants/FiltroConstants').default;
module.exports = tipoVinculo => {
  let filtros = [{
    key: 1,
    disabled: false,
    atributosPermitidos: ['data_atendimento', '__time'],
    atributo: 'data_atendimento',
    tipoComponente: 'BETWEEN_INCLUSIVE_TIMESTAMP_MS',
    valor: () => {
      const end = moment().set({
        minute: 0,
        second: 0,
        millisecond: 0
      }).toDate();
      const start = moment().set({
        minute: 0,
        second: 0,
        millisecond: 0
      }).subtract(1, 'month').toDate();
      return [start, end];
    }
  }];
  switch (tipoVinculo) {
    case TipoVinculo.ADMIN:
      filtros = [...filtros, {
        key: 3,
        disabled: true,
        habilitarRegraVinculo: true,
        atributo: 'estado',
        rotulo: 'Estado',
        tipoComponente: 'IN_SELECT',
        valor: ''
      }, {
        key: 4,
        disabled: true,
        habilitarRegraVinculo: true,
        atributo: 'cidade',
        rotulo: 'Município',
        dependsOn: ['estado'],
        tipoComponente: 'IN_SELECT',
        valor: ''
      }, {
        key: 5,
        disabled: true,
        habilitarRegraVinculo: true,
        atributo: 'rede_teleconsultoria',
        rotulo: 'Rede teleconsultoria',
        tipoComponente: 'IN_SELECT',
        valor: ''
      }];
      break;
    case TipoVinculo.ESTADO:
      filtros = [...filtros, {
        key: 2,
        disabled: true,
        hidden: true,
        atributo: 'tipo_regulacao',
        tipoComponente: 'IN_SELECT',
        valor: 'Estadual'
      }, {
        key: 3,
        disabled: true,
        habilitarRegraVinculo: true,
        atributo: 'estado',
        rotulo: 'Estado',
        tipoComponente: 'IN_SELECT',
        valor: ''
      }, {
        key: 4,
        disabled: true,
        habilitarRegraVinculo: true,
        atributo: 'rede_teleconsultoria',
        dependsOnWithValue: [{
          atributo: 'tipo_regulacao',
          tipoComponente: 'IN_SELECT',
          valor: 'Estadual'
        }],
        rotulo: 'Rede teleconsultoria',
        tipoComponente: 'IN_SELECT',
        valor: ''
      }];
      break;
    case TipoVinculo.MUNICIPIO:
      filtros = [...filtros, {
        key: 2,
        disabled: true,
        hidden: true,
        atributo: 'tipo_regulacao',
        tipoComponente: 'IN_SELECT',
        valor: 'Municipal'
      }, {
        key: 3,
        disabled: true,
        habilitarRegraVinculo: true,
        atributo: 'cidade',
        rotulo: 'Município',
        tipoComponente: 'IN_SELECT',
        valor: ''
      }, {
        key: 4,
        disabled: true,
        habilitarRegraVinculo: true,
        atributo: 'rede_teleconsultoria',
        dependsOnWithValue: [{
          atributo: 'tipo_regulacao',
          tipoComponente: 'IN_SELECT',
          valor: 'Municipal'
        }],
        rotulo: 'Rede teleconsultoria',
        tipoComponente: 'IN_SELECT',
        valor: ''
      }];
      break;
    case TipoVinculo.REDE_TELECONSULTORIA:
      filtros = [...filtros, {
        key: 2,
        disabled: true,
        habilitarRegraVinculo: true,
        atributo: 'rede_teleconsultoria',
        rotulo: 'Rede teleconsultoria',
        tipoComponente: 'IN_SELECT',
        valor: ''
      }];
      break;
    default:
      break;
  }
  return {
    cubo: 'cubo_dashboard_teleconsultoria',
    primeiraConsultaAutomatica: false,
    atributosSelecionados: ['__time', 'id', 'teleconsultoria_finalizada', 'teleconsultoria_avaliada', 'redes_teleconsultorias_vinculo_paciente', 'data_solicitacao', 'data_atendimento', 'classificacao', 'especificacao', 'rede_teleconsultoria', 'rede_teleconsultoria_abrev', 'codigo_ibge', 'nome_solicitante', 'cbo_familia_solicitante', 'cbo_ocupacao_solicitante', 'orientacao_especifica', 'orientacao_especifica_sigla', 'nome_teleconsultor', 'cbo_familia_teleconsultor', 'cbo_ocupacao_teleconsultor', 'nome_instituicao', 'satisfacao_geral', 'tempo_resposta_hora', 'cid10', 'ciap2', 'nome_paciente', 'cns_paciente', 'cpf_paciente', 'lida_solicitante', 'medida_contador' // Sempre colocar o último atributo como métrica.
    ],
    charts: [{
      title: 'teleconsultorias realizadas',
      description: 'Teleconsultorias respondidas.',
      type: 'CardAbsoluteChart',
      selectDimensions: ['medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }],
      transformations: []
    }, {
      title: 'de teleconsultorias com recomendação de manejo na APS',
      description: 'Teleconsultorias com pacientes vinculados.',
      type: 'CardPercentageChart',
      selectDimensions: ['orientacao_especifica_sigla', 'medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }, {
        attribute: 'redes_teleconsultorias_vinculo_paciente',
        filter: value => value == 'Sim'
      }],
      having: ['M'],
      transformations: []
    }, {
      title: 'encaminhamentos desnecessários potencialmente evitados',
      description: 'Teleconsultorias com pacientes vinculados.',
      type: 'CardAbsoluteChart',
      w: 2,
      selectDimensions: ['medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }, {
        attribute: 'orientacao_especifica_sigla',
        filter: value => value == 'M'
      }, {
        attribute: 'redes_teleconsultorias_vinculo_paciente',
        filter: value => value == 'Sim'
      }],
      transformations: []
    }, {
      title: 'de teleconsultorias respondidas em até 72h',
      description: 'Teleconsultorias respondidas.',
      type: 'CardPercentage2Chart',
      selectDimensions: ['tempo_resposta_hora', 'medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }],
      having: 'Sim',
      transformations: [{
        attributes: ['tempo_resposta_hora'],
        transformation: value => value !== null && value <= 72 ? 'Sim' : 'Não'
      }]
    }, {
      title: 'teleconsultorias avaliadas como "boa/muito boa"',
      description: 'Teleconsultorias respondidas e avaliadas.',
      type: 'CardPercentage2Chart',
      selectDimensions: ['satisfacao_geral', 'medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_avaliada',
        filter: value => value == 'Sim'
      }],
      transformations: [],
      having: ['Muito satisfeito(a)', 'Satisfeito(a)']
    }, {
      title: '1. DISTRIBUIÇÃO ESPACIAL DAS SOLICITAÇÕES',
      description: 'Teleconsultorias respondidas.',
      type: 'MapChart',
      metaAtributos: [{
        atributo: 'codigo_ibge',
        rotulo: 'Cidade',
        tipo: TipoAtributo.TEXTO_GEO_COD_IBGE
      }, {
        atributo: 'medida_contador',
        rotulo: 'Quantidade',
        tipo: TipoAtributo.NUMERICO_INTEIRO
      }],
      selectDimensions: ['codigo_ibge', 'medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }],
      transformations: []
    }, {
      title: '2. ORIENTAÇÕES DAS TELECONSULTORIAS SEGUNDO A REDE DE TELECONSULTORIA',
      description: 'Teleconsultorias respondidas.',
      type: 'ApexBarChart',
      selectDimensions: ['orientacao_especifica', 'rede_teleconsultoria_abrev', 'medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }],
      transformations: [],
      uiConfig: {
        enableAnimation: true
      }
    }, {
      title: '3. AVALIAÇÕES DAS TELECONSULTORIAS SEGUNDO A REDE DE TELECONSULTORIA',
      description: 'Teleconsultorias avaliadas.',
      type: 'ApexBarChart',
      selectDimensions: ['satisfacao_geral', 'rede_teleconsultoria_abrev', 'medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_avaliada',
        filter: value => value == 'Sim'
      }],
      transformations: [],
      uiConfig: {
        chartLegendColors: {
          'Não preenchido': '#fdef19',
          Indiferente: '#d0d0ce',
          'Muito insatisfeito(a)': '#000000',
          'Insatisfeito(a)': '#cc0000',
          'Satisfeito(a)': '#00b23d',
          'Muito satisfeito(a)': '#2e93fa'
        },
        chartLegendOrder: ['Indiferente', 'Não preenchido', 'Muito insatisfeito(a)', 'Insatisfeito(a)', 'Satisfeito(a)', 'Muito satisfeito(a)']
      }
    }, {
      title: '4. RESPOSTAS DAS TELECONSULTORIAS DENTRO DAS 72H SEGUNDO A REDE DE TELECONSULTORIA',
      description: 'Teleconsultorias respondidas.',
      type: 'ApexBarChart',
      selectDimensions: ['tempo_resposta_hora', 'rede_teleconsultoria_abrev', 'medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }],
      transformations: [{
        attributes: ['tempo_resposta_hora'],
        transformation: value => value !== null && value <= 72 ? 'Sim' : 'Não'
      }]
    }, {
      type: 'Inner',
      inner: {
        cubo: 'cubo_dashboard_teleconsultoria_atrasadas',
        excludeFiltros: [1],
        atributosSelecionados: ['prazo', 'rede_teleconsultoria', 'medida_contador'],
        medidas: [{
          atributo: 'medida_contador',
          funcaoAgregadora: AggConstants.SUM,
          alias: 'medida_contador'
        }],
        charts: [{
          title: '5. TELECONSULTORIAS EM ABERTO NA DATA E HORA DO ÚLTIMO PROCESSAMENTO',
          description: 'Solicitações de teleconsultorias.',
          type: 'ApexBarChart',
          selectDimensions: ['prazo', 'rede_teleconsultoria', 'medida_contador'],
          filters: [],
          transformations: [{
            attributes: ['prazo'],
            transformation: value => {
              switch (value) {
                case 'Atrasados 7+ dias':
                  return 'Em aberto há 7 dias ou mais';
                case 'Atrasadas há menos de 7 dias':
                  return 'Em aberto há menos de 7 dias';
                case 'Dentro do prazo':
                  return 'Dentro do prazo de 72 horas';
                default:
                  return value;
              }
            }
          }, {
            attributes: ['rede_teleconsultoria'],
            transformation: value => {
              const values = value === null || value === void 0 ? void 0 : value.split('->');
              if (values.length > 1) return values[1];
              return value;
            }
          }],
          uiConfig: {
            chartLegendColors: {
              'Não preenchido': '#d0d0ce',
              'Em aberto há 7 dias ou mais': '#cc0000',
              'Em aberto há menos de 7 dias': '#fdef19',
              'Dentro do prazo de 72 horas': '#2e93fa'
            }
          }
        }]
      }
    }, {
      title: '6. CBO DO PROFISSIONAL SOLICITANTE',
      description: 'Teleconsultorias respondidas.',
      type: 'ApexBarChart',
      selectDimensions: ['cbo_familia_solicitante', 'medida_contador'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }],
      transformations: []
    }, {
      title: '7. TELECONSULTORIAS RESPONDIDAS POR TELECONSULTOR',
      description: 'Teleconsultorias respondidas. São exibidos apenas os 20 teleconsultores com o maior número de respostas. Para visualizar o resultado completo, faça o download dos dados em formato de planilha.',
      type: 'TableChart',
      limitTable: 20,
      selectDimensions: ['nome_teleconsultor', 'rede_teleconsultoria_abrev', 'medida_contador'],
      labels: ['TELECONSULTOR', 'TELECONSULTORIAS RESPONDIDAS', 'REDE DE TELECONSULTORIA'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value === 'Sim'
      }],
      transformations: []
    }, {
      title: '8. PLANILHA COM DADOS ADICIONAIS SOBRE OS TELECONSULTORES',
      description: 'Teleconsultorias respondidas.',
      subtitle: 'Clique aqui para baixar uma planilha do Excel com os seguintes microdados: (1) teleconsultor respondente; (2) CBO do teleconsultor respondente; (3) especialidade da teleconsultoria; (4) orientação do teleconsultor; (5) avaliação da resposta; (6) prazo (dias) para a resposta; (6) CID registrada; (7) CIAP-2 registrada.',
      nomeArquivo: '8. PLANILHA COM DADOS ADICIONAIS SOBRE OS TELECONSULTORES',
      type: 'File',
      addAttribute: {
        genDimensions: ['prazo (dias) para a resposta'],
        valueFn: (data_atendimento, data_solicitacao) => {
          if (!data_atendimento) return ['Não se aplica'];
          if (!data_solicitacao) return ['Sem data de solicitação.'];
          const diffDays = Math.round((data_atendimento - data_solicitacao) / 86400000);
          return [diffDays];
        },
        attributes: ['data_atendimento', 'data_solicitacao']
      },
      selectDimensions: ['id', 'nome_teleconsultor', 'cbo_familia_teleconsultor', 'cbo_ocupacao_teleconsultor', 'especificacao', 'orientacao_especifica', 'satisfacao_geral', 'prazo (dias) para a resposta', 'cid10', 'ciap2'],
      customHeader: ['id', 'teleconsultor respondendo', 'CBO família do teleconsultor respondente', 'CBO ocupação do teleconsultor respondente', 'especialidade da teleconsultoria', 'orientação do teleconsultor', 'avaliação da resposta', 'prazo (dias) para a resposta', 'CID registrada', 'CIAP-2 registrada'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }, {
        attribute: 'nome_teleconsultor',
        filter: value => value != 'Não preenchido'
      }],
      transformations: []
    }, {
      title: '9. PLANILHA COM DADOS ADICIONAIS SOBRE A SOLICITAÇÃO',
      description: 'Teleconsultorias respondidas.',
      subtitle: 'Clique aqui para baixar uma planilha do Excel com os seguintes microdados: (1) identificação da solicitação; (2) profissional solicitante; (3) CBO do profissional solicitante; (4) unidade de saúde do profissional solicitante; (5) especialidade solicitada; (6) orientação do teleconsultor; (7) resposta lida pelo solicitante; (8) nome, cpf e cns do paciente vinculado; (9) rede de teleconsultoria.',
      nomeArquivo: '9. PLANILHA COM DADOS ADICIONAIS SOBRE A SOLICITAÇÃO',
      type: 'File',
      selectDimensions: ['id', 'nome_solicitante', 'cbo_familia_solicitante', 'cbo_ocupacao_solicitante', 'nome_instituicao', 'especificacao', 'orientacao_especifica', 'lida_solicitante', 'nome_paciente', 'cns_paciente', 'cpf_paciente', 'rede_teleconsultoria'],
      customHeader: ['ID da solicitação', 'Profissional solicitante', 'CBO família do profissional', 'CBO ocupação do profissional', 'Unidade de saúde do profissional solicitante', 'Especialidade solicitada', 'Orientação do teleconsultor', 'Resposta lida pelo solicitante', 'Nome do paciente', 'CNS do paciente', 'CPF do paciente', 'Rede de teleconsultoria'],
      filters: [{
        attribute: 'teleconsultoria_finalizada',
        filter: value => value == 'Sim'
      }],
      transformations: []
    }],
    metaAtributos: [{
      atributo: '__time',
      grupo: '0.0 - Variáveis gerais',
      rotulo: 'Data da solicitação',
      tipo: 'DATA_HORA'
    }, {
      atributo: 'data_atendimento',
      grupo: '0.0 - Variáveis gerais',
      rotulo: 'Data da resposta',
      tipo: 'TIMESTAMP_MS'
    }, {
      atributo: 'rede_teleconsultoria',
      grupo: '1.0 - Dados básicos',
      key: 'cubo_dashboard_teleconsultoria_solicitacao_rede_teleconsultoria',
      rotulo: 'Rede teleconsultoria',
      tipo: 'TEXTO'
    }, {
      atributo: 'tipo_regulacao',
      grupo: '1.0 - Dados básicos',
      key: 'cubo_dashboard_teleconsultoria_tipo_regulacao',
      rotulo: 'Tipo regulação',
      tipo: 'TEXTO'
    }, {
      atributo: 'tipo',
      grupo: '1.0 - Dados básicos',
      rotulo: 'Tipo de solicitação',
      tipo: 'TEXTO'
    }, {
      atributo: 'classificacao',
      grupo: '1.0 - Dados básicos',
      rotulo: 'Classificação da solicitação',
      tipo: 'TEXTO'
    }, {
      atributo: 'cidade',
      grupo: '1.0 - Dados básicos',
      rotulo: 'Município',
      tipo: 'TEXTO'
    }, {
      atributo: 'estado',
      grupo: '1.0 - Dados básicos',
      rotulo: 'Estado',
      tipo: 'TEXTO'
    }, {
      atributo: 'especificacao',
      grupo: '1.0 - Dados básicos',
      rotulo: 'Especialidade',
      tipo: 'TEXTO'
    }],
    filtros
  };
};