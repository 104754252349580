import React, { useState, useEffect } from 'react';

import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { OperationWrapper } from './OperationWrapper';
import { FiltroConstants } from '@stt-analise/util';

const MetaAtributoFiltro = ({ cubo, metaAtributos, disabled, loading, filtro, onChangeFilter, preFiltros }) => {
    const [metaAtributoSelected, setMetaAtributoSelected] = useState({});
    const [value, setValue] = useState(null);

    const filterOptions = ({ metaAtributos, atributosPermitidos }) => {
        if (atributosPermitidos) {
            return metaAtributos.filter((metaAtributo) => {
                return atributosPermitidos.includes(metaAtributo.atributo);
            });
        }
        return metaAtributos;
    };

    /**
     * Para quando o filtro estiver preenchido com valores iniciais.
     * Filtro (atributo, key, tipoComponente, valor)
     */
    useEffect(() => {
        if (filtro && filtro.atributo) {
            const metaAtributoFound = metaAtributos.filter((metaAtributo) => metaAtributo.atributo === filtro.atributo)[0];
            setMetaAtributoSelected(metaAtributoFound);
            setValue(metaAtributos.find((meta) => meta.atributo == filtro.atributo));
        }
    }, [filtro, metaAtributos]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={3} hidden={filtro.hidden || false}>
                <Autocomplete
                    id="grouped-demo"
                    disabled={disabled || filtro.disabled}
                    loading={loading}
                    // options={metaAtributos}
                    options={filterOptions({ metaAtributos, atributosPermitidos: filtro.atributosPermitidos })}
                    groupBy={(option) => option.grupo}
                    getOptionLabel={(option) => option?.rotulo || ""}
                    value={value || ""}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Variável" variant="outlined" size="small" />}
                    onChange={(_, novoMetaAtributo) => {
                        // Normalizar para um valor padrão do tipoComponente.
                        let tipoComponente = FiltroConstants.TIPO_FILTRO.EQUAL;
                        if(novoMetaAtributo.tipoComponente){
                            tipoComponente = novoMetaAtributo.tipoComponente;
                        }

                        setMetaAtributoSelected(novoMetaAtributo);
                        onChangeFilter({ ...filtro, atributo: novoMetaAtributo.atributo, tipoComponente });
                    }}
                    disableClearable={true}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={9} hidden={filtro.hidden || false}>
                <OperationWrapper cubo={cubo} metaAtributoSelected={metaAtributoSelected} disabled={disabled} onChangeFilter={onChangeFilter} filtro={filtro} preFiltros={preFiltros} />
            </Grid>
        </Grid>
    );
};

/**
 * 
 * @param {preFiltros} Array<Object> Proveniente dos dashboards
 * 
 * @returns 
 */
export const FiltroSecundario = ({ cubo, filtros, metaAtributos, loadingMetaAtributos = false, onChangeFilter, preFiltros, propsFiltroMap }) => {
    return (
        <>
            {filtros.map((filtro, index) => (
                <React.Fragment key={`${filtro.atributo}-${index}`}>
                    <MetaAtributoFiltro
                        cubo={cubo}
                        metaAtributos={metaAtributos}
                        disabled={loadingMetaAtributos}
                        filtro={filtro}
                        onChangeFilter={onChangeFilter}
                        preFiltros={preFiltros || filtros}
                    />
                </React.Fragment>
            ))}
        </>
    );
};
