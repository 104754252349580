import React from 'react';

import { SttSelect, SttMenuItem } from '@stt-componentes/core';

import { FiltroConstants } from '@stt-analise/util';

const TIPOS_FILTRO_TEXTO = [
    {
        tipoComponente: FiltroConstants.TIPO_FILTRO.EQUAL,
        rotulo: 'igual a',
    },
    {
        tipoComponente: FiltroConstants.TIPO_FILTRO.NOT_EQUAL,
        rotulo: 'diferente de',
    },
    {
        tipoComponente: FiltroConstants.TIPO_FILTRO.IN,
        rotulo: 'incluir',
    },
    {
        tipoComponente: FiltroConstants.TIPO_FILTRO.NOT_IN,
        rotulo: 'excluir',
    },
];

const TIPOS_FILTRO_TIMESTAMP_MS = [
    {
        tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
        rotulo: 'intervalo',
    },
];

export const TextSelectType = ({ filtro, onChangeFilter }) => (
    <>
        <SttSelect
            label="Operação"
            value={filtro && filtro.tipoComponente || FiltroConstants.TIPO_FILTRO.EQUAL}
            onChange={(event) => {
                const newFilter = { ...filtro, tipoComponente: event.target.value };
                if (onChangeFilter) onChangeFilter(newFilter);
            }}
        >
            {TIPOS_FILTRO_TEXTO.map((tiposFiltro) => (
                <SttMenuItem key={tiposFiltro.tipoComponente} value={tiposFiltro.tipoComponente}>
                    {tiposFiltro.rotulo}
                </SttMenuItem>
            ))}
        </SttSelect>
    </>
);

export const TimestampMSSelectType = ({ filtro, onChangeFilter }) => (
    <>
        <SttSelect
            label="Operação"
            value={filtro.tipoFiltro}
            onChange={(event) => {
                const newFilter = { ...filtro, tipoComponente: event.target.value };
                if (onChangeFilter) onChangeFilter(newFilter);
            }}
        >
            {TIPOS_FILTRO_TIMESTAMP_MS.map((tiposFiltro) => (
                <SttMenuItem key={tiposFiltro.tipoComponente} value={tiposFiltro.tipoComponente}>
                    {tiposFiltro.rotulo}
                </SttMenuItem>
            ))}
        </SttSelect>
    </>
);
