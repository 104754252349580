import { SPLIT_CHAR_SPECIAL } from '../constants/Split';
/**
 * Função responsável por selecionar apenas algumas colunas (dimensões) do dataset.
 *
 * @param {Matriz} dataset
 * @param {Array} selectAttributes
 * @returns novo dataset {Matriz}
 */
const selectDimensions = function (dataset) {
  let selectAttributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // Pegar as informações do cabeçalho
  const header = dataset[0];
  // Encontrar os indices que representa a coluna da matriz.
  let indexes = [];
  selectAttributes.forEach(attribute => {
    indexes.push(header.indexOf(attribute));
  });
  // Devolver um novo dataset, com as colunas selecionadas.
  return dataset.map(row => indexes.map(i => row[i]));
};
/**
 * Responsável por filtrar.
 */
const applyFilterDimensions = (dataset, filters) => {
  const header = dataset[0];
  // Encontrar os indices que representa a coluna da matriz.
  let indexes = [];
  filters.forEach(filter => {
    indexes.push(header.indexOf(filter.attribute));
  });
  // Lógica para filtrar.
  let newDataset = [header];
  for (let index = 1; index < dataset.length; index++) {
    const row = dataset[index];
    const validFilter = [];
    indexes.forEach((jColumn, i) => {
      validFilter.push(filters[i].filter(row[jColumn]));
    });
    if (validFilter.every(result => result === true)) {
      newDataset.push(row);
    }
  }
  return newDataset;
};
const applyTransformationDimensions = (dataset, transformations) => {
  const header = dataset[0];
  // Encontrar os indices que representa a coluna da matriz.
  let indexesTransformations = [];
  transformations.forEach(filter => {
    const attributesFouded = filter.attributes.map(attribute => header.indexOf(attribute)) || [];
    indexesTransformations.push(attributesFouded);
  });
  console.log(indexesTransformations);
  // Lógica para filtrar.
  let newDataset = [header];
  // if(nameAttr) newDataset = [[nameAttr, ...header]];
  for (let index = 1; index < dataset.length; index++) {
    let row = [...dataset[index]];
    indexesTransformations.forEach((jColumn, iTrasformation) => {
      if (jColumn.findIndex(e => e !== -1) !== -1) {
        row[jColumn[0]] = transformations[iTrasformation].transformation(...jColumn.map(j => row[j]));
      }
    });
    newDataset.push(row);
  }
  return newDataset;
};
const countByNDimension = function (dataset) {
  let applyAverage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const dimension = dataset[0].length;
  if (dimension === 2) {
    return dataset.reduce((acc, curr) => {
      const key = `${curr[0]}${SPLIT_CHAR_SPECIAL}${curr[1]}`;
      acc[key] = acc[key] ? acc[key] + 1 : 1;
      return acc;
    }, {});
    // return _.countBy(dataset);
  } else if (dimension > 2) {
    let weight = {};
    let countByKey = dataset.reduce((acc, curr) => {
      const key = `${curr[0]}${SPLIT_CHAR_SPECIAL}${curr[1]}`;
      acc[key] = acc[key] ? acc[key] + curr[2] : curr[2];
      if (applyAverage) {
        weight[key] = weight[key] ? weight[key] + 1 : 1;
      }
      return acc;
    }, {});
    if (applyAverage) {
      for (const key in countByKey) {
        if (Object.hasOwnProperty.call(countByKey, key)) {
          countByKey[key] = countByKey[key] / weight[key];
        }
      }
    }
    return countByKey;
  } else {
    throw new Error('Dataset não suportado.');
  }
};
/**
 * Somatório da última dimensão.
 *
 * @param dataset
 * @returns retorna valor númerico da somatória da última métrica.
 */
const countAllByDataset = dataset => {
  /**
   * Deletar o cabeçalho
   */
  dataset.shift();
  let sum = 0;
  dataset.forEach(row => {
    const metricValue = row.slice(-1)[0];
    sum += Number(metricValue);
  });
  return sum;
};
function applyAggregation(values, aggregationType) {
  switch (aggregationType) {
    case 'sum':
    case 'avg':
    case 'min':
    case 'max':
      if (typeof values[0] !== 'number') {
        throw new Error(`${aggregationType} can only be applied to numeric values.`);
      }
      break;
    case 'concat':
      if (typeof values[0] !== 'string') {
        throw new Error(`${aggregationType} can only be applied to string values.`);
      }
      break;
    case 'distinct-count':
      break;
    default:
      throw new Error(`Aggregation type '${aggregationType}' is not supported.`);
  }
  switch (aggregationType) {
    case 'distinct-count':
      return new Set(values).size;
    case 'sum':
      return values.reduce((acc, val) => acc + val, 0);
    case 'avg':
      return values.reduce((acc, val) => acc + val, 0) / values.length;
    case 'min':
      return Math.min(...values);
    case 'max':
      return Math.max(...values);
    case 'concat':
      return values.join(', ');
    default:
      return null;
  }
}
const groupByWithStringAgg = (dataset, option) => {
  var _a;
  const header = dataset[0];
  const valueColumnIndex = header.indexOf(option.attribute);
  if (valueColumnIndex === -1) {
    throw new Error(`Column name '${option.attribute}' not found.`);
  }
  const records = dataset.slice(1);
  const grouped = {};
  records.forEach(record => {
    var _a;
    const key = record.filter((_, index) => {
      var _a;
      return index !== valueColumnIndex && !((_a = option.stringAggColumnNames) === null || _a === void 0 ? void 0 : _a.includes(header[index]));
    }).join('-');
    if (!grouped[key]) {
      grouped[key] = {
        values: [],
        strings: {},
        group: record.filter((_, index) => {
          var _a;
          return index !== valueColumnIndex && !((_a = option.stringAggColumnNames) === null || _a === void 0 ? void 0 : _a.includes(header[index]));
        })
      };
    }
    grouped[key].values.push(record[valueColumnIndex]);
    (_a = option.stringAggColumnNames) === null || _a === void 0 ? void 0 : _a.forEach(columnName => {
      const columnIndex = header.indexOf(columnName);
      if (columnIndex === -1) {
        throw new Error(`Column name '${columnName}' not found.`);
      }
      if (!grouped[key].strings[columnName]) {
        grouped[key].strings[columnName] = [];
      }
      grouped[key].strings[columnName].push(record[columnIndex]);
    });
  });
  const resultHeader = header.filter(col => {
    var _a;
    return col !== option.attribute && !((_a = option.stringAggColumnNames) === null || _a === void 0 ? void 0 : _a.includes(col));
  }).concat(option.operation).concat((_a = option.stringAggColumnNames) === null || _a === void 0 ? void 0 : _a.map(col => `${col}_concat`));
  const output = [resultHeader];
  Object.values(grouped).forEach(item => {
    var _a;
    const aggregatedValue = applyAggregation(item.values, option.operation);
    const aggregatedStrings = (_a = option.stringAggColumnNames) === null || _a === void 0 ? void 0 : _a.map(columnName => applyAggregation(item.strings[columnName], 'concat'));
    output.push(item.group.concat(aggregatedValue).concat(aggregatedStrings));
  });
  return output;
};
const groupBy = (dataset, option) => {
  const header = dataset[0];
  const valueColumnIndex = header.indexOf(option.attribute);
  if (valueColumnIndex === -1) {
    throw new Error(`Column name '${option.attribute}' not found.`);
  }
  const records = dataset.slice(1);
  const grouped = {};
  records.forEach(record => {
    const key = record.slice(0, valueColumnIndex).concat(record.slice(valueColumnIndex + 1)).join('-');
    const value = record[valueColumnIndex];
    if (!grouped[key]) {
      grouped[key] = {
        values: [],
        group: record.slice(0, valueColumnIndex).concat(record.slice(valueColumnIndex + 1))
      };
    }
    grouped[key].values.push(value);
  });
  const resultHeader = header.slice(0, valueColumnIndex).concat(header.slice(valueColumnIndex + 1)).concat(option.alias || option.attribute);
  const output = [resultHeader];
  Object.values(grouped).forEach(item => {
    const aggregatedValue = applyAggregation(item.values, option.operation);
    output.push(item.group.concat(aggregatedValue));
  });
  return output;
};
const DataManipulation = {
  groupBy,
  groupByWithStringAgg,
  countByNDimension,
  selectDimensions,
  applyFilterDimensions,
  applyTransformationDimensions,
  countAllByDataset
};
export default DataManipulation;