import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SttAlerta } from '@stt-componentes/core';

import { NotificationManager } from 'react-notifications';
import { MSGBuilder, MSGCode } from '@stt-analise/util';
import { Strings } from '@stt-utilitarios/core';
const strings = { ...Strings.analise };

function sendNotification({ title, message, type }) {
    switch (type) {
        case 'info':
            NotificationManager.info(message);
            break;
        case 'alert':
            NotificationManager.warning(message, title, 3000);
            break;
        case 'confirmation':
        case 'success':
            NotificationManager.success(message, title);
            break;
        case 'error':
            NotificationManager.error(message, title, 5000, () => { });
            break;
    }
};

function ErrorAlert({ openAlert = false, errorCode, setOpenAlert, yesCallback = () => { } }) {
    const [alertMsg, setAlertMsg] = useState(new MSGBuilder(MSGCode.ERR001, strings).buildMsgAlert());

    useEffect(() => {
        if (openAlert) {
            const newAlertMsg = new MSGBuilder(MSGCode[errorCode] || MSGCode.ERR001, strings).buildMsgAlert();
            if (newAlertMsg.typeComponent === 'notification') {
                sendNotification({ ...newAlertMsg });
                setOpenAlert(false);
            }
            setAlertMsg(newAlertMsg);
        }
    }, [errorCode]);

    const handleOnClose = () => {
        setOpenAlert(false);
    };

    const options = [
        {
            title: 'Ok',
            onClick: handleOnClose,
        },
    ];

    return openAlert && <SttAlerta open={openAlert && alertMsg.typeComponent == 'prompt'} title={alertMsg.title} message={alertMsg.message} type={alertMsg.type} options={options} onClose={handleOnClose} />;
}

ErrorAlert.propTypes = {
    openAlert: PropTypes.bool,
    errorCode: PropTypes.string,
    setOpenAlert: PropTypes.func,
};

export default ErrorAlert;
