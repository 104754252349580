import React, { useRef } from 'react';
// styles
import useStyles from './styles';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';
import { useSignalEffect } from '@preact/signals-react';
import { Funcionalidade } from '@stt-analise/util';

const SERVICOS = {
    /* -- Solicitação de exame -- */
    "SOLICITACAOEXAME_CANCERDEPELE": "Solicitação de exame;Câncer de pele",
    "SOLICITACAOEXAME_OUTRASDERMATOSES": "Solicitação de exame;Outras dermatoses",
    "SOLICITACAOEXAME_HANSENIASE": "Solicitação de exame;Hanseníase",

    /* -- Realização de exame -- */
    "REALIZACAOEXAME_CANCERDEPELE": "Realização de exame;Câncer de pele",
    "REALIZACAOEXAME_OUTRASDERMATOSES": "Realização de exame;Outras dermatoses",
    "REALIZACAOEXAME_HANSENIASE": "Realização de exame;Hanseníase",

    /* Emissão de laudo > Dermatose */
    "EMISSAOLAUDO_DERMATOSE_MELANOMACUTANEO": "Emissão de laudo;Dermatose;Melanoma cutâneo",
    "EMISSAOLAUDO_DERMATOSE_CECCBC": "Emissão de laudo;Dermatose;CEC/CBC",
    "EMISSAOLAUDO_DERMATOSE_HANSENIASE": "Emissão de laudo;Dermatose;Hanseníase",
    "EMISSAOLAUDO_DERMATOSE_PSORIASE": "Emissão de laudo;Dermatose;Psoríase",

    /* Emissão de laudo > Classificação de risco */
    "EMISSAOLAUDO_CLASSIFICACAORISCO_VERMELHA": "Emissão de laudo;Classificação de risco;Vermelha",
    "EMISSAOLAUDO_CLASSIFICACAORISCO_AMARELA": "Emissão de laudo;Classificação de risco;Amarela",
    "EMISSAOLAUDO_CLASSIFICACAORISCO_VERDE": "Emissão de laudo;Classificação de risco;Verde",
    "EMISSAOLAUDO_CLASSIFICACAORISCO_AZUL": "Emissão de laudo;Classificação de risco;Azul",
    "EMISSAOLAUDO_CLASSIFICACAORISCO_BRANCA": "Emissão de laudo;Classificação de risco;Branca",

    /* Acesso a laudo > Dermatose */
    "ACESSOLAUDO_DERMATOSE_MELANOMACUTANEO": "Acesso a laudo;Dermatose;Melanoma cutâneo",
    "ACESSOLAUDO_DERMATOSE_CECCBC": "Acesso a laudo;Dermatose;CEC/CBC",
    "ACESSOLAUDO_DERMATOSE_HANSENIASE": "Acesso a laudo;Dermatose;Hanseníase",
    "ACESSOLAUDO_DERMATOSE_PSORIASE": "Acesso a laudo;Dermatose;Psoríase",

    /* Acesso a laudo > Classificação de risco */
    "ACESSOLAUDO_CLASSIFICACAORISCO_VERMELHA": "Acesso a laudo;Classificação de risco;Vermelha",
    "ACESSOLAUDO_CLASSIFICACAORISCO_AMARELA": "Acesso a laudo;Classificação de risco;Amarela",
    "ACESSOLAUDO_CLASSIFICACAORISCO_VERDE": "Acesso a laudo;Classificação de risco;Verde",
    "ACESSOLAUDO_CLASSIFICACAORISCO_AZUL": "Acesso a laudo;Classificação de risco;Azul",
    "ACESSOLAUDO_CLASSIFICACAORISCO_BRANCA": "Acesso a laudo;Classificação de risco;Branca",
};

const makeServicos = (servicos) => {
    let listaCategoria = [], listaLegendas = [];
    for (const key in servicos) {
        if (servicos.hasOwnProperty(key)) {
            const legenda = servicos[key];
            listaCategoria.push(key);
            listaLegendas.push(legenda);
        }
    }
    return { listaCategoria, listaLegendas };
};

function MapTeledermato() {
    const classes = useStyles();
    const iFrame = useRef(null);
    
    useSignalEffect(() => {
        const acesso = usuarioSignal.value.rbac.montarAcesso(Funcionalidade.PAGINA_MAPA_EVENTOS_DERMATO);
        const { estados, temPermissao } = acesso;
        if (!temPermissao) {
            iFrame.current.contentWindow.location.href = '/apps/menu-principal';
            return;
        }
        const { listaCategoria, listaLegendas } = makeServicos(SERVICOS);
        const options = {
            selectColors: 1,
            base_serverurl: global.gConfig.url_base_api_monitoracao,
            lista_de_categoria: listaCategoria,
            lista_de_legendas: listaLegendas,
            abrangencia: 'BRASIL', // BRASIL ou SANTA_CATARINA
            lista_or: [
                ['EMISSAOLAUDO_DERMATOSE', 'EMISSAOLAUDO_CLASSIFICACAORISCO'],
                ['ACESSOLAUDO_DERMATOSE', 'ACESSOLAUDO_CLASSIFICACAORISCO'],
            ],
            estados: estados,
        };
        console.log("ESTADOS PARA ACESSO AO MAPA TELEDERMATO: ", estados); 
        iFrame.current.contentWindow.STT2_OPTIONS = options;
    });

    return (
        <div className={classes.mapContainer}>
            <iframe allowFullScreen={true} ref={iFrame} referrerPolicy="unsafe-url" src={`/iframes/monitor-gis/index.html?version=${global.gConfig?.modulo_versao}`} width="100%" height="100%" />
        </div>
    );
}

export default MapTeledermato;