/**
 * Tipos de atributos
 * TEXTO
 * TEXTO_GEO_UF
 * TEXTO_GEO_CODIBGE
 * NUMERICO
 * NUMERICO_TIMEMS
 * NUMERICO_FLOAT
 * NUMERICO_INTEIRO
 */
var TipoAtributo;
(function (TipoAtributo) {
  TipoAtributo["TEXTO"] = "TEXTO";
  TipoAtributo["TEXTO_GEO"] = "TEXTO_GEO";
  TipoAtributo["TEXTO_GEO_UF"] = "TEXTO_GEO_UF";
  TipoAtributo["TEXTO_GEO_COD_IBGE"] = "TEXTO_GEO_COD_IBGE";
  TipoAtributo["NUMERICO"] = "NUMERICO";
  TipoAtributo["NUMERICO_INTEIRO"] = "NUMERICO_INTEIRO";
  TipoAtributo["DATA"] = "DATA";
  TipoAtributo["TIMESTAMP_MS"] = "TIMESTAMP_MS";
  TipoAtributo["DATA_HORA"] = "DATA_HORA";
})(TipoAtributo || (TipoAtributo = {}));
export default TipoAtributo;