import React, { useEffect, useContext, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Divider, Box, Container } from '@material-ui/core';
import { SttButton, SttModal, SttInput, SttTranslateHook } from '@stt-componentes/core';
import { formatAggTitle } from '@stt-analise/pivottable/Utilities';

export default function MuiAgg({
    aggregatorList,
    aggregatorName,
    vals,
    onClickAdd,
    onDeleteItem,
    getMessage,
}) {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [openModal, setOpenModal] = useState(false);
    const [aggTitle, setAggTitle] = useState('');

    useEffect(() => {
        setAggTitle(formatAggTitle(aggregatorName, vals));
    }, [aggregatorName, vals])

    function handleOpen() {
        setOpenModal(true);
    }

    function handleClose() {
        setOpenModal(false);
    }

    return (
        <>
            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
            <Box
                alignItems="center"
                component="div"
                display="flex"
                flexDirection="column"
            >
                <Button
                    size="small"
                    variant="contained"
                    style={{ textAlign: 'center' }}
                    onClick={() => {
                        handleOpen()
                    }}
                >
                    {strings.Comuns.adicionarMetrica}
                </Button>
                <List
                    style={{ margin: 0, marginTop: 5, backgroundColor: 'white', width: '100%' }}
                    dense={true}
                    disablePadding
                >
                    {aggregatorList?.map((e, i) => (
                        <ListItem key={i} divider>
                            <ListItemText
                                primary={`${e.aggTitle || formatAggTitle(e.aggregatorName, e.vals)}`}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => onDeleteItem(i)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <SttModal
                title={getMessage(aggregatorName)}
                open={openModal}
                iconClose={handleClose}
                outModalClose={handleClose}
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" onClick={() => {
                            onClickAdd(aggTitle);
                            handleClose();
                        }}>
                            Adicionar
                        </SttButton>
                    </div>
                }
            >
                <Container maxWidth="md">
                    <SttInput
                        autoFocus
                        value={aggTitle}
                        onChange={(e) => {
                            setAggTitle(e.target.value);
                        }}
                        style={{ minWidth: '400px' }}
                        label={strings.Comuns.renomearColuna}
                        required
                    />
                </Container>
            </SttModal>
        </>
    );
}
