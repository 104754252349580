/**
 * Funções puras que realizam alguma regra de negócio.
 */


const _procurarFiltrosDependentes = ({ filtro, preFiltros }) => {
    const { dependsOn, dependsOnWithValue } = filtro;

    if(dependsOnWithValue) return dependsOnWithValue;

    if (!hasDependsOn(dependsOn)) return [];

    if (preFiltros && dependsOn) {
        const preFiltrosDepends = preFiltros.filter(filtro_ => dependsOn.includes(filtro_.atributo));

        // Validar se existe algum filtro com valor vazio
        const isEmpty = preFiltrosDepends.filter(filtro => filtro.valor == '' && filtro.valor.trim() == '').length > 0;
        if (isEmpty) return [];

        return preFiltrosDepends;
    }
    return [];
}

/**
 * RN - Regra para determinar se um filtro é dependente de outro.
 */
export const aplicarFiltrosDependentes = ({ filtro, preFiltros }) => {
    return _procurarFiltrosDependentes({ filtro, preFiltros });
}

/**
 * RN AUX - Verifica se possui algum filtro dependente.
 */
export const hasDependsOn = (dependsOn) => {
    return dependsOn && dependsOn.length > 0;
}

/**
 * Verificar se todos os filtros dependentes estão preenchidos
 */
export const verificarFiltrosPreenchidos = ({ filtro, preFiltros }) => {
    const { dependsOn } = filtro;

    if (!hasDependsOn(dependsOn)) return true; // Liberar acesso ao filtro.

    if (preFiltros && dependsOn) {
        const preFiltrosDepends = preFiltros.filter(filtro_ => dependsOn.includes(filtro_.atributo));

        // Validar se existe algum filtro com valor vazio
        const filtros = preFiltrosDepends.filter(filtro => filtro.valor != '' && filtro.valor.trim() != '');

        return dependsOn.length === filtros.length;
    }
    return true;
};