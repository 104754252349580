'use strict';

const Wreck = require('@hapi/wreck');
const CarimboTempo = {
  /**
   * Função que acessa o serviço de carimbo do tempo e verifica se os dados estão íntegros.
   * 
   * @param {*} conteudo conteúdo a ser validado na carimbadora
   * @param {*} carimbo carimbo do tempo para comparação de integridade dos dados
   * @returns 
   */
  verificarCarimbo: async (conteudo, carimbo, config) => {
    const payload = {
      conteudo: conteudo,
      carimbo: carimbo
    };
    const dados = JSON.stringify(payload);
    const usuario = config.usuario;
    const senha = config.senha;
    const options = {
      headers: {
        'Authorization': `Basic ${Buffer.from(`${usuario}:${senha}`).toString('base64')}`,
        'Content-Type': 'application/json',
        'X-Api-Version': '1.0.0'
      },
      payload: dados,
      json: true
    };
    try {
      const {
        payload
      } = await Wreck.post(`${config.url_base}/verificarCarimbo`, options);
      const data = {
        data: payload.datahora,
        statusCarimbo: payload.statuscarimbo,
        certificado: payload.cncertificado,
        hashCertificado: payload.hashceritificado,
        emissor: payload.cnemissor,
        statusProcesso: payload.statusprocesso
      };
      return data;
    } catch (err) {
      console.log(err);
      //Erro de conexão com o micro-serviço de carimbo do tempo.
      return false;
    }
  },
  /**
   * Função que acessa o serviço de carimbo do tempo e cria um novo carimbo do tempo.
   * 
   * @param {*} conteudo conteúdo a ser carimbado
   * @param {*} carimbo 
   * @returns 
   */
  criarCarimbo: async (conteudo, config) => {
    const payload = {
      conteudo: conteudo
    };
    const dados = JSON.stringify(payload);
    const usuario = config.usuario;
    const senha = config.senha;
    const options = {
      headers: {
        'Authorization': `Basic ${Buffer.from(`${usuario}:${senha}`).toString('base64')}`,
        'Content-Type': 'application/json',
        'X-Api-Version': '1.0.0'
      },
      payload: dados,
      json: true
    };
    try {
      const {
        payload
      } = await Wreck.post(`${config.url_base}/carimbar`, options);
      return payload;
    } catch (err) {
      //Erro de conexão com o micro-serviço de carimbo do tempo.
      return false;
    }
  }
};
module.exports = CarimboTempo;