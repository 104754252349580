const moment = require('moment');
const parser = require('ua-parser-js');
const {
  assembleResultadoTDRDashboard,
  assembleTempoDeAcessoTelas
} = require('../../rules/AlofonoRules');
const FiltroConstants = require('../../constants/FiltroConstants').default;
module.exports = {
  cubo: 'cubo_dashboard_alofono',
  primeiraConsultaAutomatica: false,
  atributosSelecionados: ['__time', 'data_execucao_teste', 'paciente_nome', 'paciente_cpf', "paciente_uf", "paciente_cidade", "paciente_idade", "paciente_tipo_fone", "paciente_user_agent", "paciente_dt_nascimento", 'paciente_sexo', 'tempo_acesso_telas', 'concluido', 'snr', 'acertos', 'apresentacoes', 'r_acertos', 'r_apresentacoes', 'srt_resultado_ouvido_esquerdo', 'srt_resultado_ouvido_direito', 'medida_contador'],
  charts: [{
    title: 'total de triagens realizadas',
    type: 'CardAbsoluteChart',
    selectDimensions: ['paciente_cpf', 'medida_contador'],
    filters: [],
    filtersRemote: [],
    transformations: [],
    groupByOp: 'distinct'
  }, {
    title: 'triagens concluídas com no mínimo um teste DNR',
    type: 'CardPercentageChart',
    w: 2,
    selectDimensions: ['paciente_cpf', 'concluido', 'medida_contador'],
    filters: [],
    filtersRemote: [],
    groupByOp: 'distinct',
    groupByStrAgg: true,
    postTransformations: [{
      attributes: ['concluido'],
      transformation: concluido => {
        if (concluido.includes('Sim')) return 'Sim';
        return 'Não';
      }
    }],
    enableAbsoluteValue: true,
    having: "Sim",
    transformations: []
  }, {
    title: 'triagens concluídas com no mínimo dois testes DNR',
    type: 'CardPercentageChart',
    w: 2,
    selectDimensions: ['paciente_cpf', 'concluido', 'medida_contador'],
    filters: [],
    filtersRemote: [],
    groupByOp: 'sum',
    groupByStrAgg: true,
    postTransformations: [{
      attributes: ['concluido', 'medida_contador'],
      transformation: (concluido, medida_contador) => {
        if (concluido.includes('Sim') && medida_contador >= 2) return 'Validado';
        return 'Não validado';
      }
    }, {
      attributes: ['medida_contador'],
      transformation: medida_contador => {
        return 1; // o mesmo que distinct-count.
      }
    }],
    enableAbsoluteValue: true,
    having: "Validado",
    transformations: []
  }, {
    title: 'triagens não concluídas',
    type: 'CardPercentageChart',
    selectDimensions: ['paciente_cpf', 'concluido', 'medida_contador'],
    filters: [],
    filtersRemote: [],
    enableAbsoluteValue: true,
    groupByOp: 'distinct',
    groupByStrAgg: true,
    postTransformations: [{
      attributes: ['concluido'],
      transformation: concluido => {
        if (concluido.includes('Sim')) return 'Sim';
        return 'Não';
      }
    }],
    having: "Não",
    transformations: []
  }, {
    title: '1. Quantidade de triagens concluídos por faixa etária',
    type: 'ApexBarChart',
    selectDimensions: ['paciente_cpf', 'paciente_uf', 'paciente_idade', 'medida_contador'],
    filters: [{
      attribute: 'concluido',
      filter: value => value == 'Sim'
    }],
    groupByOp: 'distinct',
    postTransformations: [{
      attributes: ['paciente_idade'],
      transformation: value => {
        if (Number(value) <= 17) return '0 a 17 anos';
        if (Number(value) <= 29) return '18 a 29 anos';
        if (Number(value) <= 39) return '30 a 39 anos';
        if (Number(value) <= 49) return '40 a 49 anos';
        if (Number(value) <= 59) return '50 a 59 anos';
        if (Number(value) <= 69) return '60 a 69 anos';
        if (Number(value) >= 70) return '70+ anos';
        return 'Não preenchido';
      }
    }],
    reduceDimensions: ['paciente_uf', 'paciente_idade', 'medida_contador']
  }, {
    title: '2. Quantidade de triagens concluídos por sexo',
    type: 'ApexBarChart',
    selectDimensions: ['paciente_cpf', 'paciente_uf', 'paciente_sexo', 'medida_contador'],
    filters: [{
      attribute: 'concluido',
      filter: value => value == 'Sim'
    }],
    transformations: [],
    groupByOp: 'distinct',
    reduceDimensions: ['paciente_uf', 'paciente_sexo', 'medida_contador']
  }, {
    title: '3. Resultado representativo (ou médio) da amostra por faixa etária (orelha esquerda)',
    type: 'Psicometrica',
    selectDimensions: ['paciente_idade', 'acertos', 'apresentacoes'],
    filters: [{
      attribute: 'acertos',
      filter: value => value != 'Não preenchido'
    }],
    showLegend: true,
    agg: "group",
    transformations: [{
      attributes: ['paciente_idade'],
      transformation: value => {
        if (Number(value) <= 17) return '0 a 17 anos';
        if (Number(value) <= 29) return '18 a 29 anos';
        if (Number(value) <= 39) return '30 a 39 anos';
        if (Number(value) <= 49) return '40 a 49 anos';
        if (Number(value) <= 59) return '50 a 59 anos';
        if (Number(value) <= 69) return '60 a 69 anos';
        if (Number(value) >= 70) return '70+ anos';
        return 'Não preenchido';
      }
    }]
  }, {
    title: '4. Resultado representativo (ou médio) da amostra por faixa etária (orelha direita)',
    type: 'Psicometrica',
    selectDimensions: ['paciente_idade', 'r_acertos', 'r_apresentacoes'],
    filters: [{
      attribute: 'r_acertos',
      filter: value => value != 'Não preenchido'
    }],
    showLegend: true,
    agg: "group",
    transformations: [{
      attributes: ['paciente_idade'],
      transformation: value => {
        if (Number(value) <= 17) return '0 a 17 anos';
        if (Number(value) <= 29) return '18 a 29 anos';
        if (Number(value) <= 39) return '30 a 39 anos';
        if (Number(value) <= 49) return '40 a 49 anos';
        if (Number(value) <= 59) return '50 a 59 anos';
        if (Number(value) <= 69) return '60 a 69 anos';
        if (Number(value) >= 70) return '70+ anos';
        return 'Não preenchido';
      }
    }]
  }, {
    title: '5. Resultado representativo (ou médio) da amostra por sexo (orelha esquerda)',
    type: 'Psicometrica',
    selectDimensions: ['paciente_sexo', 'acertos', 'apresentacoes'],
    filters: [{
      attribute: 'acertos',
      filter: value => value != 'Não preenchido'
    }],
    showLegend: true,
    agg: "group",
    transformations: []
  }, {
    title: '6. Resultado representativo (ou médio) da amostra por sexo (orelha direita)',
    type: 'Psicometrica',
    selectDimensions: ['paciente_sexo', 'r_acertos', 'r_apresentacoes'],
    filters: [{
      attribute: 'r_acertos',
      filter: value => value != 'Não preenchido'
    }],
    showLegend: true,
    agg: "group",
    transformations: []
  }, {
    title: '7. PLANILHA',
    subtitle: 'Clique aqui para baixar uma planilha do Excel com todos os dados.',
    type: 'File',
    addAttributes: [{
      genDimensions: ['browser_name', 'browser_version', 'os_name', 'os_version'],
      valueFn: paciente_user_agent => {
        const userAgent = parser(paciente_user_agent);
        const {
          name: browserName,
          version: browserVersion
        } = userAgent.browser;
        const {
          name: osName,
          version: osVersion
        } = userAgent.os;
        return [browserName, browserVersion, osName, osVersion];
      },
      attributes: ['paciente_user_agent']
    }, {
      genDimensions: ['srt_resultado_ouvido_esquerdo_string', 'srt_resultado_ouvido_direito_string'],
      valueFn: (srt_resultado_ouvido_esquerdo, srt_resultado_ouvido_direito) => {
        const resultadoSRTDireito = assembleResultadoTDRDashboard(srt_resultado_ouvido_direito);
        const resultadoSRTEsquerdo = assembleResultadoTDRDashboard(srt_resultado_ouvido_esquerdo);
        return [resultadoSRTEsquerdo, resultadoSRTDireito];
      },
      attributes: ['srt_resultado_ouvido_esquerdo', 'srt_resultado_ouvido_direito']
    }, {
      genDimensions: ['Tela 1', 'Tela 2', 'Tela 3', 'Tela 4', 'Tela 5', 'Tela 6', 'Tela 7', 'Tela 8', 'Tela 9', 'Tela 10', 'Tempo total'],
      valueFn: tempo_acesso_telas => {
        return assembleTempoDeAcessoTelas(tempo_acesso_telas);
      },
      attributes: ['tempo_acesso_telas']
    }],
    selectDimensions: [
    // Dados gerais
    'data_execucao_teste',
    // Dados do paciente
    'paciente_nome', 'paciente_cpf', "paciente_uf", "paciente_cidade", "paciente_idade", "paciente_dt_nascimento", 'paciente_sexo', "paciente_tipo_fone",
    // Dados do aparelho do paciente
    'browser_name', 'browser_version', 'os_name', 'os_version', 'paciente_user_agent',
    // Dados teste auditivo
    'concluido', "snr", 'acertos', 'apresentacoes', 'srt_resultado_ouvido_esquerdo', 'srt_resultado_ouvido_esquerdo_string', 'r_acertos', 'r_apresentacoes', 'srt_resultado_ouvido_direito', 'srt_resultado_ouvido_direito_string',
    // Telas de acesso
    'Tela 1', 'Tela 2', 'Tela 3', 'Tela 4', 'Tela 5', 'Tela 6', 'Tela 7', 'Tela 8', 'Tela 9', 'Tela 10', 'Tempo total'],
    customHeader: [
    // Dados gerais
    'data_execucao_teste',
    // Dados do paciente
    'paciente_nome', 'paciente_cpf', "paciente_uf", "paciente_cidade", "paciente_idade", "paciente_dt_nascimento", 'paciente_sexo', "tipo_fone",
    // Dados do aparelho do paciente
    'browser_name', 'browser_version', 'os_name', 'os_version', 'paciente_user_agent',
    // Dados teste auditivo
    'teste_auditivo_concluido', "snr", 'orelha_esquerda_acertos', 'orelha_esquerda_apresentacoes', 'orelha_esquerda_srt', 'orelha_esquerda_resultado', 'orelha_direita_acertos', 'orelha_direita_apresentacoes', 'orelha_direita_srt', 'orelha_direita_resultado',
    // Telas de acesso
    'Tela 1 (seg)', 'Tela 2 (seg)', 'Tela 3 (seg)', 'Tela 4 (seg)', 'Tela 5 (seg)', 'Tela 6 (seg)', 'Tela 7 (seg)', 'Tela 8 (seg)', 'Tela 9 (seg)', 'Tela 10 (seg)', 'Tempo total (seg)'],
    nomeArquivo: 'alofono',
    filters: [],
    filtersRemote: [],
    transformations: [{
      attributes: ['data_execucao_teste'],
      transformation: data_execucao_teste => {
        return moment(data_execucao_teste).format('DD/MM/YY HH:mm');
      }
    }]
  }],
  metaAtributos: [{
    atributo: '__time',
    grupo: '0.0 - Variáveis gerais',
    key: 'cubo_dashboard_alofono_variaveis_gerais___time',
    rotulo: 'Data da execução dos testes',
    tipo: 'DATA_HORA'
  }, {
    atributo: 'data_atendimento',
    grupo: '0.0 - Variáveis gerais',
    key: 'cubo_dashboard_alofono_variaveis_gerais_data_atendimento',
    rotulo: 'Data do atendimento',
    tipo: 'TIMESTAMP_MS'
  }, {
    atributo: 'paciente_sexo',
    grupo: '1.0 - Paciente',
    key: 'cubo_dashboard_alofono_solicitacao_paciente_sexo',
    rotulo: 'Paciente: sexo',
    tipo: 'TEXTO'
  }, {
    atributo: 'paciente_cidade',
    grupo: '1.0 - Paciente',
    key: 'cubo_dashboard_alofono_solicitacao_paciente_cidade',
    rotulo: 'Paciente: município',
    tipo: 'TEXTO'
  }, {
    atributo: 'paciente_uf',
    grupo: '1.0 - Paciente',
    key: 'cubo_dashboard_alofono_solicitacao_paciente_uf',
    rotulo: 'Paciente: estado',
    tipo: 'TEXTO'
  }, {
    atributo: 'paciente_idade',
    grupo: '1.0 - Paciente',
    key: 'cubo_dashboard_alofono_solicitacao_paciente_idade',
    rotulo: 'Paciente: idade',
    tipo: 'NUMERICO_INTEIRO'
  }],
  filtros: [{
    key: 1,
    disabled: false,
    atributosPermitidos: ['__time'],
    atributo: '__time',
    tipoComponente: 'BETWEEN_INCLUSIVE_TIMESTAMP_MS',
    valor: () => {
      const end = moment().toDate();
      const start = moment().subtract(1, 'month').toDate();
      return [start, end];
    }
  }, {
    key: 2,
    disabled: true,
    atributo: 'paciente_uf',
    rotulo: 'Estado',
    tipoComponente: 'IN_SELECT',
    valor: ''
  }, {
    key: 3,
    disabled: true,
    atributo: 'paciente_cidade',
    rotulo: 'Município',
    tipoComponente: 'IN_SELECT',
    valor: ''
  }, {
    key: 4,
    disabled: true,
    atributo: 'paciente_sexo',
    rotulo: 'Sexo',
    tipoComponente: 'IN_SELECT',
    valor: ''
  }, {
    key: 5,
    disabled: true,
    atributo: 'paciente_idade',
    rotulo: 'Idade',
    tipoComponente: 'BETWEEN_INCLUSIVE_NUMBER',
    valor: ""
  }]
};