import _ from 'lodash';
/**
 * Montagem da estrutura data para o gráfico ApexChart.
 *
 * @param {*} obj
 * @param {*} labels
 * @returns
 */
const mountDataApexChart = (obj, labels) => {
  const data = new Array(labels.length).fill(0);
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const count = obj[key];
      const index = labels.indexOf(key);
      data[index] = count;
    }
  }
  return data;
};
/**
 * Permite duas dimensões:
 * - A primeira dimensão deve vir agrupada.
 * - A segunda dimensão deve ser uma métrica.
 */
const toApexPieChart = datasetDruid => {
  let labels = [],
    series = [];
  if (datasetDruid && datasetDruid.length <= 1) return [labels, series];
  // Bloquear para dimensões diferente de 2.
  if (datasetDruid[0].length != 2) throw new Error('Permite apenas 2 dimensões.');
  // Desconsiderar o cabeçalho do dataset
  datasetDruid.slice(1).forEach(row => {
    const [dim1, metric] = row;
    labels.push(dim1.toString());
    series.push(Number(metric));
  });
  return [labels, series];
};
/**
 * Lógica para montagem do Total nos gráficos de barras.
 *
 * @param result Estrutura de dados do gráfico ApexCharts.
 * @param dataLabels Estrutura de dados do gráfico ApexCharts.
 * @param totalDisplayState Variável de controle para exibição do "total" | "parts".
 * @param total valor total de todas as métricas.
 * @returns
 */
const _makeTotalDisplay = (result, dataLabels, totalDisplayState, total) => {
  if (totalDisplayState === "total") {
    dataLabels['Total'] = {
      name: 'Total',
      count: total
    };
    result['Total'] = {
      Total: total
    };
  }
  if (totalDisplayState === "parts") {
    for (const _firstDim in result) {
      if (Object.prototype.hasOwnProperty.call(result, _firstDim)) {
        const innerObj = result[_firstDim];
        let totalInner = 0;
        for (const _secondDim in innerObj) {
          if (Object.prototype.hasOwnProperty.call(innerObj, _secondDim)) {
            const count = innerObj[_secondDim];
            totalInner += count;
          }
        }
        result[_firstDim]['Total'] = totalInner;
      }
    }
    dataLabels['Total'] = {
      name: 'Total',
      count: total
    };
  }
  return [result, dataLabels];
};
const _makeThreeDimBarChart = (datasetWithoutHeader, totalDisplayState) => {
  let result = {};
  let dataLabels = {};
  let total = 0;
  for (const row of datasetWithoutHeader) {
    const [_firstDim, _secondDim, count] = row;
    const firstDim = (_firstDim || 'Não preenchido').toString();
    const secondDim = (_secondDim || 'Não preenchido').toString();
    if (!result[firstDim]) {
      result[firstDim] = {};
    }
    if (!result[firstDim][secondDim]) {
      result[firstDim][secondDim] = count;
    } else {
      result[firstDim][secondDim] += count;
    }
    if (!dataLabels[secondDim]) dataLabels[secondDim] = {
      name: secondDim,
      count
    };else dataLabels[secondDim].count = dataLabels[secondDim].count + count;
    total += count;
  }
  if (totalDisplayState) {
    [result, dataLabels] = _makeTotalDisplay(result, dataLabels, totalDisplayState, total);
  }
  return [result, dataLabels];
};
const _makeTwoDimBarChart = (datasetWithoutHeader, totalDisplayState) => {
  let result = {};
  let dataLabels = {};
  let total = 0;
  for (const row of datasetWithoutHeader) {
    const [_secondDim, count] = row;
    const firstDim = 'Quantidade';
    const secondDim = _secondDim.toString();
    if (!result[firstDim]) {
      result[firstDim] = {};
    }
    if (!result[firstDim][secondDim]) {
      result[firstDim][secondDim] = count;
    } else {
      result[firstDim][secondDim] += count;
    }
    if (!dataLabels[secondDim]) dataLabels[secondDim] = {
      name: secondDim,
      count
    };else dataLabels[secondDim].count = dataLabels[secondDim].count + count;
    total += count;
  }
  if (totalDisplayState) {
    [result, dataLabels] = _makeTotalDisplay(result, dataLabels, totalDisplayState, total);
  }
  return [result, dataLabels];
};
/**
 * Prevalece @param Colors sobre @param defaultColors
 * Mantém o tamanho e índices do @param defaultColors
 */
const mergeColors = (defaultColors, colors) => {
  return defaultColors.map((iColor, index) => colors[index] || iColor);
};
/**
 * Lógica para ordenar a Legenda.
 */
const orderChart = (colors, series, chartLegendOrder) => {
  const cloneColors = [...colors];
  const cloneSeries = [...series];
  const newSeries = [];
  const newColors = [];
  chartLegendOrder.forEach(categoria => {
    const index = cloneSeries.findIndex(serie => serie.name === categoria);
    if (index !== -1) {
      newSeries.push(cloneSeries.splice(index, 1)[0]);
      newColors.push(cloneColors.splice(index, 1)[0]);
    }
  });
  cloneSeries.forEach((s, index) => {
    newSeries.push(s);
    newColors.push(cloneColors[index]);
  });
  return [newSeries, newColors];
};
;
const toApexBarChart = function () {
  let {
    datasetDruid = [],
    applySorting = true,
    orderDirection = 'desc',
    orderType = 'count',
    defaultColors = [],
    chartLegendColors = {},
    limitBarChart = 0,
    chartLegendOrder,
    totalDisplayState = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (datasetDruid && datasetDruid.length <= 1) {
    return [];
  }
  const nDim = datasetDruid[0].length;
  if (nDim !== 3 && nDim !== 2) throw new Error("Não permite dataset diferente de 3 dimensões.");
  /**
   * Preparar dataset inicial
   * 1. Clonar dataset
   * 2. Retirar o cabeçalho
   */
  let nDataset;
  if (applySorting) {
    nDataset = [..._.sortBy(datasetDruid.slice(1), [])];
  } else {
    nDataset = [...datasetDruid.slice(1)];
  }
  /**
   * Preparar a montagem das estruturas.
   */
  const makeNDim = {
    2: _makeTwoDimBarChart,
    3: _makeThreeDimBarChart
  };
  const [result, dataLabels] = makeNDim[nDim](nDataset, totalDisplayState);
  /**
   * Lógica para ordenar as métrica, o eixo Y
   */
  let labelsArray = _.orderBy(Object.values(dataLabels), [orderType], [orderDirection]).map(e => e.name);
  if (limitBarChart > 0) {
    labelsArray = labelsArray.slice(0, limitBarChart);
  }
  /**
   * Regras para exibição dos intervalos no eixo X
   * ver https://apexcharts.com/docs/options/xaxis/
   */
  let tickAmount;
  if (labelsArray.length > 1) {
    tickAmount = 'dataPoints';
  }
  /**
   * Utilizar a estrutura montada para criar os pequenos datasets do gráfico.
   * Separar em vários dataset por uma dimensão.
   */
  const series = [];
  const colors = [];
  for (const key in result) {
    if (Object.hasOwnProperty.call(result, key)) {
      const color = chartLegendColors[key];
      colors.push(color);
      const innerData = result[key];
      series.push({
        name: key,
        data: mountDataApexChart(innerData, labelsArray)
      });
    }
  }
  /**
   * Lógica para ordernar as categorias (legendas), eixo X.
   * 1 - Para ordenar a legenda do gráfico.
   * 2 - Caso não tenha cores atribuidas para a legenda, utilizar cores padrões.
   */
  let newColors = mergeColors(defaultColors, colors);
  let newSeries = series;
  if (chartLegendOrder) {
    [newSeries, newColors] = orderChart(colors, series, chartLegendOrder);
  }
  return [labelsArray, newSeries, newColors, tickAmount];
};
const countAllByApexChartDataset = function () {
  let series = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let sum = 0;
  series.forEach(_ref => {
    let {
      data
    } = _ref;
    const metricValue = data.reduce((partialSum, a) => partialSum + a, 0);
    sum += metricValue;
  });
  return sum;
};
export default {
  toApexBarChart,
  toApexPieChart,
  countAllByApexChartDataset
};