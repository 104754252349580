import React, { useEffect, useState } from 'react';
import ReactDOMServer from "react-dom/server";
import { ConvertApexBarChart, XLSXUtil } from '@stt-analise/util';
import { SiMicrosoftexcel } from 'react-icons/si';
import Chart from 'react-apexcharts';

const defaultColors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed', '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0'];

const ApexBarChartHorizontal = ({ datasetDruid, title, uiConfig }) => {
    const [dataset, setDataset] = useState(null);
    useEffect(() => {
        const [categories, series, colors, tickAmount] = ConvertApexBarChart.toApexBarChart({ datasetDruid: [...datasetDruid], defaultColors, ...uiConfig });
        setDataset([categories, series, colors, tickAmount]);
    }, []);

    if (dataset === null) {
        return 'loading';
    }

    if (datasetDruid.length <= 1) {
        return 'Nenhum dado';
    }

    const [categories, series, colors, tickAmount] = dataset;

    const config = {
        series,
        chart: {
            type: 'bar',
            // height: 600,
            // height: 'auto',
            width: '100%',
            stacked: true,
            animations: {
                enabled: !!uiConfig?.enableAnimation,
            },
            toolbar: {
                tools: {
                    customIcons: [{
                        icon: ReactDOMServer.renderToString(<SiMicrosoftexcel style={{ width: '22px', height: '22px' }} />),
                        index: 0,
                        title: 'Download XLSX',
                        class: 'apexcharts-menu-icon',
                        click: function () {
                            XLSXUtil.toXLSX(datasetDruid, null, title);
                        }
                    }]
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        colors: colors,
        stroke: {
            width: 1,
            colors: ['#fff'],
        },
        xaxis: {
            categories,
            tickAmount,
        },
        yaxis: {
            title: {
                text: undefined,
            },
        },
        grid: {
            borderColor: '#90A4AE',
            strokeDashArray: 7,
            xaxis: {
              lines: {
                show: true
              }
            }
          },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        responsive: [
            {
                // breakpoint: 480,
                options: {
                    chart: {
                        // width: 1000,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    return <Chart options={config} series={config.series} type="bar" />;
};

export default ApexBarChartHorizontal;
