'use strict';

const LogFuncionario = {
  tabela: 'public.log_funcionario',
  inserir: async function (dbClient) {
    let transactionHandler = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let dados = arguments.length > 2 ? arguments[2] : undefined;
    const {
      idFuncionario,
      evento,
      url,
      codigo,
      prioridade,
      arquivo,
      linha,
      categoria
    } = dados;
    let insert = dbClient.insert({
      id_funcionario: idFuncionario,
      evento: evento,
      url: url,
      codigo: codigo,
      prioridade: prioridade,
      arquivo: arquivo,
      linha: linha,
      categoria: categoria
    }).into(LogFuncionario.tabela);
    if (transactionHandler) {
      insert = insert.transacting(transactionHandler);
    }
    await insert;
  }
};
module.exports = LogFuncionario;