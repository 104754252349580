import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { REQUEST_FILTRO } from '../../../requests/graphql';

import { Grid, Container, Badge, Chip } from '@material-ui/core';
import { SttButton, SttModal } from '@stt-componentes/core';
import { makeStyles } from '@material-ui/core/styles';
import InFilter from './In';
import { FiltroConstants } from '@stt-analise/util';
import ChipsArray from './ChipArray';

const useStyles = makeStyles(() => ({
    button: {
        margin: '8px',
    },
}));

export const InWrapper = ({ cubo, metaAtributoSelected, onChangeFilter, filtro }) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [requestAutocompletar, { variables: variablesAutocompletar, loading: loadingAutocompletar, data: dataAutocompletar }] = useLazyQuery(REQUEST_FILTRO);
    const [cached, setCached] = useState([]);

    function handleOpen() {
        setOpenModal(true);
    }

    function handleClose() {
        setOpenModal(false);
    }

    function normalizeFiltroValor(filtro) {
        return filtro.valor == '' ? [] : filtro.valor.split(FiltroConstants.SPLIT_VALUES);
    }

    useEffect(() => {
        setCached([]);
        if (metaAtributoSelected.atributo) {
            requestAutocompletar({ variables: { cubo, atributo: metaAtributoSelected.atributo }, fetchPolicy: 'cache-first' });
        }
    }, [metaAtributoSelected]);

    useEffect(() => {
        if (dataAutocompletar && dataAutocompletar['iAutocompletar']) {
            const newCached = [...cached, ...dataAutocompletar['iAutocompletar']];
            setCached([...new Set(newCached)]);
        }
    }, [dataAutocompletar]);

    return (
        <>
            {metaAtributoSelected.atributo && (
                <>
                    <Badge badgeContent={normalizeFiltroValor(filtro).length} color="primary">
                        <ChipsArray data={normalizeFiltroValor(filtro)}/>
                        <SttButton
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleOpen();
                            }}
                        >
                            Palavras
                        </SttButton>
                    </Badge>

                    <SttModal title={metaAtributoSelected.rotulo} open={openModal} iconClose={handleClose} outModalClose={handleClose}>
                        <Container maxWidth="md">
                            <InFilter
                                loading={loadingAutocompletar}
                                words={cached}
                                wordsSelected={normalizeFiltroValor(filtro)}
                                onClickSearch={({ valueSearched, wordsSelected }) => {
                                    requestAutocompletar({
                                        variables: { cubo, atributo: metaAtributoSelected.atributo, valor: valueSearched },
                                        fetchPolicy: 'cache-first',
                                    });
                                    onChangeFilter({ ...filtro, valor: wordsSelected.join(FiltroConstants.SPLIT_VALUES) });
                                }}
                                onClickSave={({ wordsSelected }) => {
                                    onChangeFilter({ ...filtro, valor: wordsSelected.join(FiltroConstants.SPLIT_VALUES) });
                                    handleClose();
                                }}
                            />
                        </Container>
                    </SttModal>
                </>
            )}
        </>
    );
};
