import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { SttTranslateHook } from '@stt-componentes/core';
import { GET_METADADOS_ETL } from '../../../requests/graphql';
import { useQuery } from '@apollo/client';
import Description from './Description';
import { FormatDate } from '@stt-analise/util';

/* @useSignals */
import { relatorioAssinadoSignal } from 'src/signals/tabelaDados/tabelaDadosSignal';

const useStyles = makeStyles({
    title: {
        display: 'flex',
    },
    right: {
        marginLeft: 'auto',
    }
});

const formatDtUltimoProcessamento = (strings, data) => {
    if (!data || !data['iMetaETL']) return null;

    const { dtInicioProcessamento, tipo, dtProcessado, dtUltimoRegistro, statusPorcentagem } = data['iMetaETL'];

    // Montagem do título principal
    const terminoUltimoProcessamento = dtProcessado || dtInicioProcessamento;
    const formatTerminoUltimoProcessamento = FormatDate.formatDataHora(terminoUltimoProcessamento);
    relatorioAssinadoSignal.value.dtUltimoProcessamento = formatTerminoUltimoProcessamento;
    const resultTerminoUltimoProcessamento = `${strings.Comuns.ultimoProcessamento}: ${formatTerminoUltimoProcessamento}`;

    // Montagem das informações do Tooltip
    let resultProcessamento, resultUltimoRegistro, resultTipoProcessamento, resultDataInicioProcessamento;
    if (dtInicioProcessamento) resultDataInicioProcessamento = `Data de início do processamento: ${FormatDate.formatDataHora(dtInicioProcessamento)}`;
    if (dtProcessado && statusPorcentagem) resultProcessamento = `Data processada: ${FormatDate.formatDataHora(dtProcessado)} (${statusPorcentagem}%)`;
    if (dtUltimoRegistro) resultUltimoRegistro = `Data do último registro: ${FormatDate.formatDataHora(dtUltimoRegistro)}`;
    if (tipo) resultTipoProcessamento = `Tipo de processamento: ${tipo}`;

    // @TODO por enquanto, está informação está fixada.
    const resultFrequenciaAtualizacao = "Frequência de atualização: diária";

    return { resultFrequenciaAtualizacao, resultTerminoUltimoProcessamento, resultProcessamento, resultUltimoRegistro, resultTipoProcessamento, resultDataInicioProcessamento };
}

const TitleCube = ({ title, cubo = "" }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const { loading, error, data } = useQuery(GET_METADADOS_ETL, {
        fetchPolicy: 'network-only', variables: {
            cubo
        },
        skip: cubo === ""
    });

    let renderInfo = <></>;
    if (loading) renderInfo = <Typography className={classes.right} variant="h4" component="h4">Carregando...</Typography>;
    else if (error) renderInfo = <Typography className={classes.right} variant="h4" component="h4">Ocorreu um erro ao carregar os dados.</Typography>;
    else {
        const res = formatDtUltimoProcessamento(strings, data);
        renderInfo = (res && <>
            <Typography className={classes.right} variant="h4" component="h4">
                {res.resultTerminoUltimoProcessamento}
                <Description>
                    {res.resultFrequenciaAtualizacao && <>{res.resultFrequenciaAtualizacao} <br /></>}
                    {res.resultTipoProcessamento && <>{res.resultTipoProcessamento} <br /></>}
                    {res.resultDataInicioProcessamento && <>{res.resultDataInicioProcessamento} <br /></>}
                    {res.resultProcessamento && <>{res.resultProcessamento} <br /></>}
                    {res.resultUltimoRegistro && <>{res.resultUltimoRegistro} <br /></>}
                </Description>
            </Typography>
        </>);
    }

    return (
        <div className={classes.title}>
            <Typography variant="h1" color='primary' >
                {title}
            </Typography>
            {renderInfo}
        </div>
    );
};

TitleCube.propTypes = {
    title: PropTypes.string,
    cubo: PropTypes.string,
};

export default TitleCube;
