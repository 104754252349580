import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { assembleResultadoDashboard } from '@stt-analise/util/lib/esm/rules/AlofonoRules';

const useStyles = makeStyles(theme => ({
    root: {
        height: 180,
        paddingTop: theme.spacing(2),
    },
    text: {
        fontSize: '3.0em',
        marginTop: theme.spacing(0.5),
    },
    textResultadoFinal: {
        fontSize: '2.5em',
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
        color: ({ RTDR }) => RTDR && RTDR == 1 ? (theme?.palette?.success?.main || 'black') : (theme?.palette?.error?.main || 'black'),
    },
    topText: {
        // fontWeight: 'bold',
        color: theme?.palette?.text?.primary || 'black'
    },
    paragraph: {
        margin: theme.spacing(0.5)
    }
}));

const RTDR_TEXTO = {
    0: 'dificuldade de audição',
    1: 'boa audição',
    2: 'sem dados para avaliar'
};

const SEM_DADOS = 'Sem dados para avaliar';

export default function Resultado({ resObj }) {
    const RTDR = assembleResultadoDashboard(resObj['resTDRGlobal']);
    const classes = useStyles({ RTDR });

    return (
        <Card className={classes.root}>
            <Box display="flex" justifyContent="center">
                <Grid container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                // justify="center"
                >
                    <Typography variant="h3" component="h3" className={classes.topText} >
                        {'Resultado da triagem auditiva'}
                    </Typography>
                    <Typography variant="h3" component="h3" className={classes.text} >
                        {resObj['resTDRGlobal'] ? `${Number(resObj['resTDRGlobal']).toFixed(2)} dB` : ''}
                    </Typography>
                    <Typography component="p" align='center' className={classes.paragraph}>
                        {RTDR_TEXTO[RTDR] || SEM_DADOS}
                    </Typography>
                </Grid>
                <Grid container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                // justify="center"
                >
                    <Typography variant="h3" component="h3"  >
                        {'Resultado final'}
                    </Typography>
                    <Typography align='center' component="p" className={classes.textResultadoFinal} >
                        {RTDR_TEXTO[RTDR] || SEM_DADOS}
                    </Typography>
                </Grid>
            </Box>

        </Card>
    );
}