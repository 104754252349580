import React, { useEffect, useState } from 'react';
import { ConvertApexBarChart } from '@stt-analise/util';
import Chart from 'react-apexcharts';

const ApexPieChart = ({ datasetDruid, uiConfig }) => {
    const [dataset, setDataset] = useState(null);
    useEffect(() => {
        const [labels, series] = ConvertApexBarChart.toApexPieChart([...datasetDruid]);
        setDataset([labels, series]);
    }, []);

    if (dataset === null) {
        return 'loading';
    }

    if (datasetDruid.length <= 1) {
        return 'Nenhum dado';
    }

    const [labels, series] = dataset;

    const config = {
        series: series,
        labels: labels,
        chart: {
            type: 'donut',
            stacked: true,
            animations: {
                enabled: !!uiConfig?.enableAnimation,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toFixed(2) + "%"
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40,
        },
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed', '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0']
    };

    if (uiConfig?.colorLabel) {
        config.colors = uiConfig.colorLabel(labels);
    }

    return <Chart options={config} series={config.series} type="donut" />;
};

export default ApexPieChart;
