import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import DownloadXLSXButton from '../Download/DownloadXLSXButton';


const useStyles = makeStyles((theme) => ({
    root: {
        // minWidth: 275,
        height: 150,
        padding: theme.spacing(2),
        margin: theme.spacing(0.5),
    },
    title: {
        fontSize: 14,
    },
    num: {
        fontSize: '2.5em',
        marginBottom: 12,
        marginTop: '30px',
    },
}));

export default function File({ datasetDruid, customHeader, subtitle, nomeArquivo }) {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={0}>
            <Box display="flex" justifyContent="center">
                <Grid container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                >
                    <Typography variant="caption" component="p" align='left'>
                        {subtitle}
                    </Typography>
                    <br />
                    <Box display="flex" justifyContent="center">
                        <DownloadXLSXButton datasetDruid={datasetDruid} customHeader={customHeader} nomeArquivo={nomeArquivo} />
                    </Box>
                </Grid>
            </Box>
        </Card>
    );
}