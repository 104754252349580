/**
 * Formatação de valores no formato de ponto flutuante.
 *
 * @param double
 * @returns
 */
const formatDouble = double => {
  return double.toFixed(2);
};
export default {
  formatDouble
};