import { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    snackbarRootOn: {
        backgroundColor: '#00db30',
        width: '50px',
        border: 'solid #00c730 2px'
    },
    snackbarRootOff: {
        backgroundColor: '#ffe164',
        width: '50px',
        border: 'solid #ffc864 2px'
    },
    snackbarMessage: {
        padding: '0',
        color: 'black',
    },
    snackbarPosition: {
        // top: '-840px',
    }
}));

const DeviceStatus = () => {
    const classes = useStyles();
    const [Offline, setOffline] = useState(navigator.onLine);
    const [showOnlineSnackbar, setShowOnlineSnackbar] = useState(false);

    useEffect(() => {
        const handleStatusChange = () => {
            const isCurrentlyOnline = navigator.onLine;
            setOffline(isCurrentlyOnline);

            if (isCurrentlyOnline) {
                setShowOnlineSnackbar(true);
                setTimeout(() => setShowOnlineSnackbar(false) , 3000);
                window.location.reload(true);
            }
        };

        window.addEventListener('online', handleStatusChange);
        window.addEventListener('offline', handleStatusChange);

        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, []);

    return (
        <>
            <Snackbar
                open={!Offline}
                message={'Não há conexão.'}
                className={classes.snackbarPosition}
                ContentProps={{
                    classes: {
                        root: classes.snackbarRootOff,
                        message: classes.snackbarMessage
                    },
                }}
            />
            <Snackbar
                open={showOnlineSnackbar}
                message={'Conexão reestabelecida.'}
                className={classes.snackbarPosition}
                ContentProps={{
                    classes: {
                        root: classes.snackbarRootOn,
                        message: classes.snackbarMessage
                    },
                }}
            />
        </>
    );
};

DeviceStatus.propTypes = {};

export default DeviceStatus;
