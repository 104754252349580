import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

import Interno from './pages/interno';
import { Error } from './pages/error/Error';
import Authenticate from './pages/authenticate/Authenticate';

const App = () => {
    const [loaded, setLoaded] = useState(false);
    const [offline, setOffline] = useState(false);

    useEffect(() => {
        setOffline(!navigator.onLine);
    }, []);

    return (
        <>
            <Authenticate onLoaded={setLoaded} offline={offline} />
            {loaded && (
                <BrowserRouter basename={`/${global.gConfig.basename}`}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/apps/menu-principal" />} />
                        <Route path="/apps" element={<Navigate to="/apps/menu-principal" />} />
                        <Route path="/*" element={<Interno />} />
                        <Route path="*" element={<Error />} />
                    </Routes>
                </BrowserRouter>
            )}
        </>
    );
};

export default App;
