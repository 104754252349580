import { signal } from '@preact/signals-react';
import { Funcionalidade, Rbac } from '@stt-analise/util';
import SystemConstants from '../util/SystemConstants';

export const usuarioSignal = signal({
    email: '',
    nome: '',
    login: '',
    permissoesRBAC: [],
    perfisRBAC: [],
    submodulos: [],
    isAuthorized: false,
    rbac: new Rbac([], []),
});

export const alterarUsuario = ({ email, nome, login, permissoesRBAC = [], perfisRBAC = [] }) => {
    const rbac = new Rbac(permissoesRBAC, perfisRBAC);

    // Permissão para acessos dos submodulos.
    const submodulosPermitidos = SystemConstants.SUBMODULOS.filter((submodulo) => {
        if (!submodulo.funcionalidade) return true;
        return rbac.montarAcesso(submodulo.funcionalidade).temPermissao;
    });

    // Verificar se existe permissão para acessar o módulo.
    const isAuthorized = rbac.montarAcesso(Funcionalidade.MODULO).temPermissao;

    usuarioSignal.value = {
        ...usuarioSignal.value,
        email,
        nome,
        login,
        permissoesRBAC,
        perfisRBAC,
        submodulos: submodulosPermitidos,
        isAuthorized,
        rbac,
    };
};
