import React from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Componentes STT
import { SttCircularProgress } from '@stt-componentes/core';

// styles
import useStyles from './styles';

const Content = () => {
    const classes = useStyles();

    return (
        <>
            <Paper classes={{ root: classes.paperRoot }}>
                <Typography variant="h2" color="secondary" className={classnames(classes.textRow, classes.errorCode)}>
                    404
                </Typography>
                <Typography variant="h3" color="primary" className={classes.textRow}>
                    Oops. Página não encontrada!
                    <SttCircularProgress color="primary" />
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/" size="large" className={classes.backButton}>
                    Voltar para Página Inicial
                </Button>
            </Paper>
        </>
    );
};

export function Error() {
    const classes = useStyles();

    return (
        <Grid container className={classes.container}>
            <Content />
        </Grid>
    );
}

export function ErrorContent() {
    const classes = useStyles();

    return (
        <Grid container className={classes.content}>
            <Content />
        </Grid>
    );
}