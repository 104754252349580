import React from 'react';
import { SiMicrosoftexcel } from 'react-icons/si';
import { Tooltip, Button } from '@material-ui/core';
import { XLSXUtil } from '@stt-analise/util';

export default function DownloadXLSXButton({ datasetDruid, customHeader, nomeArquivo }) {
    return (
        <Tooltip title="Exportar dados">
            <div>
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={datasetDruid.length <= 1}
                    onClick={() => {
                        XLSXUtil.toXLSX(datasetDruid, customHeader, nomeArquivo);
                    }}
                    startIcon={<SiMicrosoftexcel />}
                >
                    Download
                </Button>
            </div>
        </Tooltip>
    );
}