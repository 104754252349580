import { buildMetaString, buildStyleString, jsonToHtmlTable, tableToJson } from "./HTMLStringUtil";
import PDFTabelaNormal from "./PDFTabelaNormal";
import PDFTabelaDinamica from './PDFTabelaDinamica';
const gerarHTMLTabelaNormalLegacy = (columns, rows) => {
  const tableString = jsonToHtmlTable({
    columns,
    rows
  });
  const html = `
        <html>
        <head>
            ${buildMetaString({
    titulo: 'titulo',
    responsavel: 'responsavel',
    tipo: 'TABELA_NORMAL'
  })}
            <style>
                .invisivel {
                    display: none;
                }
            </style>
        </head>
        <body>
            <div class="invisivel">
            ${tableString}
            </div>
        </body>
        </html>
    `;
  return html;
};
const gerarHTMLTabelaDinamicaLegacy = () => {
  const tableHtml = document.querySelector('td.pvtOutput');
  const metaTituloHtml = document.querySelector('div.MuiSelect-root').innerText.trim();
  const metaAutorHtml = document.querySelector('meta[name="author"]').getAttribute('content');
  const metaSistemaHtml = document.querySelector('meta[name="description"]').getAttribute('content');
  const metaKeyWords = document.querySelector('meta[name="keywords"]').getAttribute('content');
  const html = `
        <html>
        <head>
            ${buildMetaString({
    titulo: metaTituloHtml,
    responsavel: metaAutorHtml,
    tipo: 'TABELA_DINAMICA'
  })}
            ${buildStyleString()}
        </head>
        <body>
            <div class="invisivel">
            ${tableHtml.outerHTML}
            </div>
        </body>
        </html>
    `;
  return html;
};
const gerarHTMLTabelaDinamica = () => {
  const tableHtml = document.querySelector('td.pvtOutput');
  return tableHtml.outerHTML;
};
const gerarHTMLTabelaNormal = (columns, rows) => {
  const tableString = jsonToHtmlTable({
    columns,
    rows
  });
  return tableString;
};
const gerarPDFTabelaNormalFrontend = (columns, rows) => {
  PDFTabelaNormal.exportarFrontend(columns, rows);
};
const gerarPDFTabelaNormalBackend = htmlString => {
  const buffer = PDFTabelaNormal.exportarBackend(htmlString);
  return pdf;
};
const gerarPDFTabelaDinamicaFrontend = () => {
  PDFTabelaDinamica.exportarFrontend();
};
const gerarPDFTabelaDinamicaBackend = htmlString => {
  const buffer = PDFTabelaNormal.exportarBackend(htmlString);
  return pdf;
};
export default {
  gerarHTMLTabelaNormal,
  gerarHTMLTabelaDinamica,
  gerarPDFTabelaNormalFrontend,
  gerarPDFTabelaNormalBackend,
  gerarPDFTabelaDinamicaFrontend,
  gerarPDFTabelaDinamicaBackend
};