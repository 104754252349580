/**
 * Regras de negócio do frontend para o projeto alofono.
 */
const LIMIAR_TDR = -11;
/**
 * Resultado TDR para o dashboard alofono.
 * srt_resultado: -11.0
 */
export const assembleResultadoTDRDashboard = srt_resultado => {
  if (!srt_resultado) return 'Não se aplica';
  if (Number(srt_resultado) <= LIMIAR_TDR) {
    return 'Passou';
  } else {
    return 'Não passou';
  }
};
/**
 * Recupera o tempo de acesso das telas do alofono.
 * Formato da string tempo_acesso_telas: "6->10; 1->7", ou seja Tela 6 possui 10 segundos e Tela 1 possui 7 segundos.
 *
 * @return Array com 11 elementos, os 10 primeiros elementos com o tempo de acesso das telas (1-10),
 * e o último elemento com o tempo de acesso.
 */
export const assembleTempoDeAcessoTelas = tempo_acesso_telas => {
  let defaultValue = [null, null, null, null, null, null, null, null, null, null];
  let tempoTotalAcesso = 0;
  const telasStr = tempo_acesso_telas.split(';');
  telasStr.forEach(str => {
    const [numTela, secTela] = str.split('->');
    const iValue = Number(numTela) - 1;
    if (secTela && iValue < 10) {
      defaultValue[iValue] = Number(secTela);
      tempoTotalAcesso += Number(secTela);
    }
  });
  return [...defaultValue, tempoTotalAcesso];
};
/**
 *
 * 0 - 'sugestão de fazer avaliação auditiva',
 * 1 - 'boa audição',
 * 2 - 'sem dados para avaliar'
 *
 * @param resTDRGlobal
 * @param resQuestionario
 * @returns RTDR número que representa um estado do resultado.
 */
export const assembleResultadoDashboard = resTDRGlobal => {
  let RTDR = -1;
  if (resTDRGlobal) {
    RTDR = Number(Number(resTDRGlobal) < LIMIAR_TDR);
  }
  return RTDR;
};