import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');
/**
 * Para o caso de o timestamp estiver com defasagem de -3 horas por conta do fuso horário brasileiro.
 * Ou seja, seu Date/Hora está no fuso horário universal com offset atrasado.
 *
 * @param timestamp
 * @returns
 */
const deyaledTimeZoneformatDate = timestamp => {
  const OFFSET_FUSO = 3;
  return moment(timestamp).add(OFFSET_FUSO, 'hours').format();
};
/**
 * Para o caso do timestmp estiver correto e sem defasagem, ou seja seu Date/Hora está no fuso horário universal (zero zone).
 *
 * @param timestamp
 * @returns
 */
const formatDate = (timestamp, formatMoment) => {
  if (formatMoment) return moment(timestamp).format(formatMoment);
  return moment(timestamp).format();
};
/**
 * Formatar @param date na exibição DATA e HORA (DD/MM/YYYY - HH:mm).
 */
const formatDataHora = date => {
  return date && moment(date).format('DD/MM/YYYY - HH:mm');
};
/**
 * Formatar @param data para timestamp usando moment.
 *
 * @returns timestamp em milisegundos.
 */
const formatDataToTimestamp = data => {
  return moment(data).format('x');
};
/**
 *  Formatar timestamp unix para String yyyy-mm-dd.
 *
 * @param timestamp
 * @returns
 */
const formatTimestampToYYYYMMDD = timestamp => {
  return moment(timestamp).format('YYYY-MM-DD');
};
const formatIntervalInclusive = (lowerTimestamp, upperTimestamp) => {
  let lower = null,
    upper = null;
  if (lowerTimestamp) lower = moment(Number(lowerTimestamp)).startOf('day').unix() * 1000;
  if (upperTimestamp) upper = moment(Number(upperTimestamp)).endOf('day').unix() * 1000;
  return [lower === null || lower === void 0 ? void 0 : lower.toString(), upper === null || upper === void 0 ? void 0 : upper.toString()];
};
const gerarDataAtualPTBR = () => {
  const dataAtual = new Date();
  const options = {
    timeZone: 'America/Sao_Paulo'
  };
  const dataHoraBrasil = dataAtual.toLocaleString('pt-BR', options);
  return dataHoraBrasil;
};
const substractYear = date => {
  return moment(date).subtract(1, 'year').toDate();
};
export default {
  formatDate,
  deyaledTimeZoneformatDate,
  formatDataHora,
  formatDataToTimestamp,
  formatTimestampToYYYYMMDD,
  formatIntervalInclusive,
  gerarDataAtualPTBR,
  substractYear
};