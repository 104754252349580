import React, { useEffect, useMemo } from 'react';

import { FiltroUtil } from '@stt-analise/util'
import { useLazyQuery } from '@apollo/client';
import { GERAR_DATASET } from '../../requests/graphql';
import { DashboardInnerContainer } from './DashboardContainer';

function Dashboard({ dataset = [], option, filtrosSecundarios = [] }) {
    const [carregarDataset, { called, error, loading, data }] = useLazyQuery(GERAR_DATASET, {
        fetchPolicy: 'network-only',
    });

    const executarConsultaFn = () => {
        // Excluir os filtros (excludeFiltros) que não devem ser herdados.
        const filtrosSecundariosPermitidos = filtrosSecundarios.filter(filtro => !option.inner.excludeFiltros?.includes(filtro.key));
        const preFiltros = FiltroUtil.normalizeFiltros(filtrosSecundariosPermitidos);

        carregarDataset({
            variables: {
                cubo: option.inner.cubo,
                atributos: option.inner.atributosSelecionados,
                filtros: preFiltros.map(f => ({
                    atributo: f.atributo,
                    tipoComponente: f.tipoComponente,
                    valor: f.valor
                })),
                medidas: option.inner.medidas,
            },
        });
    };

    useEffect(() => {
        if (dataset.length > 1) {
            executarConsultaFn();
        }
    }, [dataset]);

    const blockCharts = useMemo(() => {
        let chartView = <span></span>;
        if (called && loading) {
            chartView = <span>Loading...</span>;
        } else if (data && data['iScanDataset']) {
            const valid = data['iScanDataset'].length > 1;
            chartView = valid ? <DashboardInnerContainer dataset={data['iScanDataset']} options={option.inner.charts} /> : 'Dados não encontrados!';
        }
        return chartView;
    }, [called, loading, data, option.inner.charts]);

    return (
        <>
            {blockCharts}
        </>
    );
}

export default Dashboard;