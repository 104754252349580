import React from 'react';
import { Grid } from '@material-ui/core';
import Resultado from './Alofono/Resultado';

const ContainerGraph = ({ resObj, chart }) => {
    return (
        <>
            {chart.type === 'Resultado' && (
                <Grid item sm={12} md={12} lg={12}>
                    <Resultado
                        resObj={resObj}
                    />
                </Grid>
            )}
        </>
    )
};

const DashboardResultContainer = ({ resultCharts = [], resObj }) => {
    const Charts = () => resultCharts.map((chart, i) => <ContainerGraph chart={chart} key={i} resObj={resObj} />);

    return (
        <Grid container spacing={1}>
            <Charts />
        </Grid>
    );
};

export default DashboardResultContainer;
