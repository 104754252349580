'use strict';

const Localizacao = {
  buscarEstadoPaisPorCidade: async (dbClient, dados) => {
    const {
      idCidade,
      ibgeCidade
    } = dados;
    let consulta = dbClient('public.cidade AS c').join('public.estado AS e', 'e.id', '=', 'c.id_estado').join('public.pais AS p', 'p.id', '=', 'e.id_pais');
    consulta.select('c.id as id_cidade', 'c.nome as nome_cidade', 'e.id as id_estado', 'e.nome as nome_estado', 'e.sigla as sigla_estado', 'p.id as id_pais', 'p.nome as nome_pais');
    if (idCidade) {
      consulta.where('c.id', '=', idCidade);
    }
    if (ibgeCidade) {
      consulta.where('c.codigo_ibge', '=', ibgeCidade);
    }
    return await consulta;
  }
};
module.exports = Localizacao;