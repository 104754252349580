import React, { useEffect, useMemo, useState } from 'react';
import MapChart from './MapChart';

import { PivotData } from '@stt-analise/pivottable/Utilities';

// styles
import useStyles from './styles';
import { TipoAtributo, TipoAtributoUtil } from '@stt-analise/util';
import _ from 'lodash';

const getMetaAttribCount = (metaAttributes) => {
    return [
        ...metaAttributes,
        {
            atributo: 'count',
            rotulo: 'qnt',
            tipo: TipoAtributo.NUMERICO_INTEIRO,
        },
    ];
};

const getDatasetCount = (metaAttributes, dataPivottable) => {
    return [
        [...metaAttributes.map((m) => m.rotulo), 'count'],
        ...dataPivottable.map((ell) => {
            let row = [];
            metaAttributes.forEach((m) => {
                row.push(ell[m.rotulo]);
            });
            row.push(1); // peso 1 para o count.
            return row;
        }),
    ];
};

const MapRenderer = (props) => {
    const classes = useStyles();
    const { cols, rows, data, meta, valueFilter } = props;

    const [dataset, setDataset] = useState([]);
    const [metaAttribs, setMetaAttribs] = useState([]);

    useEffect(() => {
        const pivotData = new PivotData(props);
        const metaAttribGeo = meta.filter((m) => TipoAtributoUtil.isGeo(m.tipo));
        const dataResult = data.filter((record) => pivotData.filter(record));
        const headers = [...cols, ...rows]; // Recuperar todos as cols e rows selecionados.
        const newMetaAttribs = metaAttribGeo.filter((m) => headers.includes(m.rotulo));
        if (newMetaAttribs.length === 0) return;

        const newDataset = getDatasetCount(newMetaAttribs, dataResult);
        if (!_.isEqual(dataset, newDataset)) {
            setDataset(newDataset);
            setMetaAttribs(getMetaAttribCount(newMetaAttribs));
        }
    }, [cols, rows, valueFilter]);

    const chart = useMemo(() => {
        const allowRender = dataset.length > 1 && metaAttribs.length > 1;

        return allowRender ? (
            <MapChart
                key={dataset.length}
                datasetDruid={dataset}
                metaAtributos={metaAttribs.map((m) => {
                    if (m.key) return { ...m, atributo: m.rotulo };
                    return m;
                })}
            />
        ) : (
            'Não existe nenhuma informação geográfica nos atributos selecionados!'
        );
    }, [dataset, metaAttribs]);

    return <div className={classes.mapContainerPivottable}>{chart}</div>;
};
export default MapRenderer;
