var MSGCode;
(function (MSGCode) {
  MSGCode[MSGCode["ERR001"] = 0] = "ERR001";
  MSGCode[MSGCode["ERR002"] = 1] = "ERR002";
  MSGCode[MSGCode["ERR003"] = 2] = "ERR003";
  MSGCode[MSGCode["ERR004"] = 3] = "ERR004";
  MSGCode[MSGCode["ERR005"] = 4] = "ERR005";
  MSGCode[MSGCode["ALE001"] = 5] = "ALE001";
  MSGCode[MSGCode["SUC001"] = 6] = "SUC001";
  MSGCode[MSGCode["SUC002"] = 7] = "SUC002";
  MSGCode[MSGCode["CON001"] = 8] = "CON001";
  MSGCode[MSGCode["CON002"] = 9] = "CON002";
  MSGCode[MSGCode["CON003"] = 10] = "CON003";
  MSGCode[MSGCode["CON004"] = 11] = "CON004";
})(MSGCode || (MSGCode = {}));
export default MSGCode;