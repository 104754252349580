'use strict';

const Paciente = require('./paciente');
const Instituicao = require('./instituicao');
const Localizacao = require('./localizacao');
const LogAplicacao = require('./log-aplicacao');
const LogAplicacaoPublico = require('./log-aplicacao-publico');
const LogFuncionario = require('./log-funcionario');
const LogUsuario = require('./log-usuario');
module.exports = {
  Paciente,
  Localizacao,
  LogAplicacao,
  LogFuncionario,
  LogUsuario,
  LogAplicacaoPublico,
  Instituicao
};