import * as XLSX from 'xlsx';
export const datasetToPivottable = (scanResult, columns) => {
  const size = scanResult.length;
  if (size === 0) return [];
  const dataset = [columns];
  for (let index = 0; index < size; index++) {
    const element = scanResult[index];
    dataset.push(...element.events);
  }
  return dataset;
};
export const datasetSQLToPivottable = sqlResult => {
  return sqlResult;
};
export const datasetGroupByToPivottable = groupByResult => {
  const size = groupByResult.length;
  if (size === 0) return [];
  const dataset = [Object.keys(groupByResult[0].event)];
  for (let index = 0; index < size; index++) {
    const element = groupByResult[index];
    dataset.push(Object.values(element.event));
  }
  return dataset;
};
/**
 * Montar Workbook.
 */
const assembleWb = (scanResult, columns) => {
  const data = [];
  const size = scanResult.length;
  if (size > 0) {
    for (let element of scanResult) {
      for (let event of element.events) {
        let obj = {};
        columns.forEach((column, j) => {
          obj[column] = event[j];
        });
        data.push(obj);
      }
    }
  }
  const ws = XLSX.utils.json_to_sheet(data, {
    header: columns
  });
  return {
    Sheets: {
      data: ws
    },
    SheetNames: ['data']
  };
};
/**
 * Montar Workbook.
 */
const assembleWbDataset = dataset => {
  let data = [],
    columns = [];
  const size = dataset.length;
  if (size > 1) {
    columns = dataset[0];
    dataset.slice(1).forEach(row => {
      let obj = {};
      row.forEach((el, iHeader) => {
        obj[columns[iHeader]] = el;
      });
      data.push(obj);
    });
  }
  const ws = XLSX.utils.json_to_sheet(data, {
    header: columns
  });
  return {
    Sheets: {
      data: ws
    },
    SheetNames: ['data']
  };
};
export const getXLSXBufferDataset = dataset => {
  const wb = assembleWbDataset(dataset);
  const base64 = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'base64'
  });
  return Buffer.from(base64, 'base64');
};
export const getCSVBufferDataset = dataset => {
  const wb = assembleWbDataset(dataset);
  // @ts-ignore
  const base64 = XLSX.write(wb, {
    bookType: 'csv',
    type: 'base64',
    forceQuotes: true,
    FS: ';'
  });
  return Buffer.from(base64, 'base64');
};
export const getXLSXBuffer = (scanResult, columns) => {
  const wb = assembleWb(scanResult, columns);
  const base64 = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'base64'
  });
  return Buffer.from(base64, 'base64');
};
export const getCSVBuffer = (scanResult, columns) => {
  const wb = assembleWb(scanResult, columns);
  XLSX.set_cptable;
  // @ts-ignore
  const base64 = XLSX.write(wb, {
    bookType: 'csv',
    type: 'base64',
    forceQuotes: true,
    FS: ';'
  });
  XLSX.utils.sheet_to_csv(wb);
  return Buffer.from(base64, 'base64');
};
export const downloadBrowser = (dataset, nomeArquivo, tipoArquivo) => {
  const wb = assembleWbDataset(dataset);
  const filename = `${nomeArquivo}.${tipoArquivo}`;
  switch (tipoArquivo) {
    case 'csv':
      // @ts-ignore
      XLSX.writeFile(wb, filename, {
        bookType: 'csv',
        type: 'base64',
        forceQuotes: true,
        FS: ';'
      });
      break;
    case 'xlsx':
      XLSX.writeFile(wb, filename, {
        bookType: 'xlsx',
        type: 'base64'
      });
      break;
    default:
      throw new Error(`Não existe implementação para tipoArquivo ${tipoArquivo}`);
  }
};
export const getNoResultDataset = type => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([['Nenhum resultado encontrado']]);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Resultado');
  const base64 = XLSX.write(workbook, {
    bookType: type,
    type: 'base64'
  });
  return Buffer.from(base64, 'base64');
};
export const toXLSX = function (dataset, customHeader) {
  let nomeArquivo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'dataset';
  const headerDataset = dataset.shift();
  const header = customHeader || headerDataset;
  // @ts-ignore
  const wb = XLSX.utils.book_new({
    sheet: nomeArquivo,
    raw: true
  });
  const ws = XLSX.utils.json_to_sheet(dataset.map(row => {
    let obj = {};
    row.forEach((cell, index) => {
      obj[header[index]] = cell;
    });
    return obj;
  }));
  XLSX.utils.book_append_sheet(wb, ws, 'dataset');
  XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'base64'
  });
  XLSX.writeFile(wb, `${nomeArquivo}.xlsx`);
  dataset.unshift(header);
};
export const fromRowsKnexToXLSX = rows => {
  // @ts-ignore
  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = {
    Sheets: {
      data: ws
    },
    SheetNames: ['data']
  };
  const base64 = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'base64'
  });
  return Buffer.from(base64, 'base64');
};
export const tableToXLSXFile = function (table) {
  let nomeRelatorio = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'relatorio';
  try {
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.table_to_sheet(table, {
      raw: true
    }); // , dateNF: 'dd/mm/yyyy hh:mm:ss'
    XLSX.utils.book_append_sheet(wb, sheet, 'Relatorio');
    const filename = `${nomeRelatorio}.xlsx`;
    XLSX.writeFile(wb, filename);
  } catch (error) {
    console.error('Erro ao exportar os dados para XLSX: ', error);
    throw new Error('Erro ao exportar os dados para XLSX: ' + error);
  }
};
export const exportarTabelaDinamicaParaXLSX = function () {
  let nomeRelatorio = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'relatorio';
  const viewElement = document.querySelector('td.pvtOutput');
  const table = viewElement === null || viewElement === void 0 ? void 0 : viewElement.firstElementChild;
  tableToXLSXFile(table, nomeRelatorio);
};
export const exportarTabelaSimplesParaXLSX = function (colums, rows) {
  let nomeRelatorio = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'relatorio';
  const dadosPlanilha = rows.map(linha => {
    let dadosRows = {};
    colums.forEach(colum => {
      dadosRows[colum.header] = linha[colum.accessorKey];
    });
    return dadosRows;
  });
  const planilha = XLSX.utils.json_to_sheet(dadosPlanilha);
  const pastaTrabalho = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(pastaTrabalho, planilha, 'Dados Exportados');
  // Gera o arquivo XLSX
  XLSX.writeFile(pastaTrabalho, `${nomeRelatorio}.xlsx`);
};