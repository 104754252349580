import React, { useState, useEffect, useContext } from 'react';
import { ItinerarioExamesSpec } from '@stt-analise/util';
import { SttTranslateHook } from '@stt-componentes/core';
import Dashboard from '../../../components/Dashboard/Dashboard';

const configFiltroSecundario = ItinerarioExamesSpec;

/**
 * RN - Regra de negócio para permitir a pesquisa pelos administradores.
 */
const validarFiltros = () => {
    return true;
};

function ItinerarioExamesDashboard() {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [filtrosSecundarios, setFiltrosSecundarios] = useState(configFiltroSecundario.filtros);
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        const valid = validarFiltros(filtrosSecundarios);
        setDisabledButton(!valid);
    }, [filtrosSecundarios]);

    return (
        <Dashboard
            title={strings.PaginaInicial.dashboardItinerarioExames}
            configFiltroSecundario={configFiltroSecundario}
            disabledButton={disabledButton}
            filtrosSecundarios={filtrosSecundarios}
            setFiltrosSecundarios={setFiltrosSecundarios}
        />
    );
}

export default ItinerarioExamesDashboard;
