import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid } from '@material-ui/core';
import { DateRangePicker, DateRangeDelimiter, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';

import logoSeta from '../../Image/seta.svg';
import { GET_DATE_LIMIT_BY_CUBE } from '../../../requests/graphql';
import { useQuery } from '@apollo/client';
import { FiltroConstants } from '@stt-analise/util';

/* @useSignals */
import { setOpenAlert } from 'src/signals/alertaSignal';

const useStyles = makeStyles(() => ({
    divDataRange: {
        margin: '0px',
        '& input': {
            padding: '10.5px 10.5px!important',
        },
        '& label': {
            transform: 'translate(15px, 12px) scale(1)',
        },
        '& fieldset': {
            borderColor: '#828282',
        },
        '& p img': {
            position: 'relative',
            top: '5px',
        },
    },
    textField: {
        width: '100%',
    },
}));

const toDates = (value) => {
    if (typeof value === 'function') {
        return value();
    }

    const dates = value.split(FiltroConstants.SPLIT_VALUES);
    if (dates.length === 2) {
        return [new Date(Number(dates[0])), new Date(Number(dates[1]))];
    } else {
        console.log('Valor incorreto!');
        return [null, null];
    }
};

const toValue = (dates) => {
    if (dates.length === 2) {
        const [startDate, finalDate] = dates;
        return `${startDate && startDate.getTime()}${FiltroConstants.SPLIT_VALUES}${finalDate && finalDate.getTime()}`;
    } else {
        return null;
    }
};

const IntervalMSWrapper = ({ cubo, metaAtributoSelected, onChangeFilter, filtro }) => {
    const classes = useStyles();

    const { loading, error, data } = useQuery(GET_DATE_LIMIT_BY_CUBE, {
        variables: {
            cubo,
        },
    });

    useEffect(() => {
        if (error) {
            setOpenAlert(true, 'ERR001');
        }
    }, [error]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <span>Não foi possível acessar. Tente novamente mais tarde.</span>;
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
                <div className={classes.divDataRange}>
                    <LocalizationProvider dateAdapter={DateFnsAdapter}>
                        <DateRangePicker
                            // disabled={disabled}
                            startText="Data inicial"
                            endText="Data final"
                            value={toDates(filtro && filtro.valor)}
                            onChange={(dates) => {
                                onChangeFilter({ ...filtro, valor: toValue(dates) });
                            }}
                            showTodayButton={true}
                            inputFormat="dd/MM/yyyy"
                            toolbarTitle="selecione o período do cubo"
                            toolbarFormat="dd/MM/yyyy"
                            showToolbar
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField {...startProps} error={Boolean(false)} helperText="" className={classes.textField} />
                                    <DateRangeDelimiter disabled>
                                        <img src={logoSeta} alt="seta" />
                                    </DateRangeDelimiter>
                                    <TextField {...endProps} error={Boolean(false)} helperText="" className={classes.textField} />
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </div>
            </Grid>
        </Grid>
    );
};

export default IntervalMSWrapper;
