'use strict';

const RBAC = {
  contarPerfisVinculoModulo: async (perfis, modulosAcesso, knex) => {
    let consulta = knex('public.rbac_perfil_permissao_modulo');
    consulta = consulta.count();
    consulta.whereIn('identificador_rbac_perfil', perfis);
    if (modulosAcesso) {
      consulta.where('identificador_rbac_permissao', 'IN', modulosAcesso);
      consulta.where('identificador_rbac_modulo', 'IN', modulosAcesso);
    }
    return consulta;
  },
  buscarEstadosPerfisVinculoModulo: async (idUsuario, modulosAcesso, knex) => {
    let consulta = knex('public.rbac_perfil_permissao_modulo as rppm').join('public.rbac_perfil_usuario_estado as rpue', 'rpue.identificador_rbac_perfil', '=', 'rppm.identificador_rbac_perfil');
    consulta = consulta.distinct('rpue.id_usuario', 'rpue.identificador_rbac_perfil', 'rpue.id_estado');
    if (modulosAcesso) {
      consulta.where('rppm.identificador_rbac_permissao', 'IN', modulosAcesso);
      consulta.where('rppm.identificador_rbac_modulo', 'IN', modulosAcesso);
    }
    consulta.where('rpue.id_usuario', '=', idUsuario);
    return consulta;
  },
  buscarLocalPerfisVinculoModulo: async (idUsuario, modulosAcesso, knex) => {
    let consulta = knex('public.rbac_perfil_permissao_modulo as rppm').join('public.rbac_perfil_usuario_instituicao as rpui', 'rpui.identificador_rbac_perfil', '=', 'rppm.identificador_rbac_perfil');
    consulta = consulta.distinct('rpui.id_usuario', 'rpui.identificador_rbac_perfil', 'rpui.id_instituicao');
    if (modulosAcesso) {
      consulta.where('rppm.identificador_rbac_permissao', 'IN', modulosAcesso);
      consulta.where('rppm.identificador_rbac_modulo', 'IN', modulosAcesso);
    }
    consulta.where('rpui.id_usuario', '=', idUsuario);
    return consulta;
  },
  buscarLocalPerfisVinculoModuloPerfil: async (idUsuario, modulosAcesso, identificadorPerfil, knex) => {
    let consulta = knex('public.rbac_perfil_permissao_modulo as rppm').join('public.rbac_perfil_usuario_instituicao as rpui', 'rpui.identificador_rbac_perfil', '=', 'rppm.identificador_rbac_perfil');
    consulta = consulta.distinct('rpui.id_usuario', 'rpui.identificador_rbac_perfil', 'rpui.id_instituicao');
    if (modulosAcesso) {
      consulta.where('rppm.identificador_rbac_permissao', 'IN', modulosAcesso);
      consulta.where('rppm.identificador_rbac_modulo', 'IN', modulosAcesso);
    }
    consulta.where('rpui.id_usuario', '=', idUsuario);
    consulta.where('rpui.identificador_rbac_perfil', '=', identificadorPerfil);
    return consulta;
  }
};
module.exports = RBAC;