import React from 'react';
import { SiMicrosoftexcel } from 'react-icons/si';
import { Tooltip, Button } from '@material-ui/core';
import { XLSXUtil, ConvertTable } from '@stt-analise/util';
export default function DownloadXLSXTableButton({ datasetDruid, nomeArquivo, labels, limitTable }) {
    const dataset = ConvertTable.toMuiXLSX([...datasetDruid], labels, limitTable);

    return (
        <Tooltip title="Exportar dados">
            <div>
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={datasetDruid.length <= 1}
                    onClick={() => {
                        XLSXUtil.toXLSX(dataset, labels, nomeArquivo);
                    }}
                    startIcon={<SiMicrosoftexcel />}
                >
                    Download
                </Button>
            </div>
        </Tooltip>
    );
}
