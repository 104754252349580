'use strict';

const path = require('path');
const QRcode = require('qrcode');
const Jimp = require('jimp');
const QrCode = {
  gerarComLogo: async url => {
    try {
      // Gerar o QR code de forma síncrona
      const qrBuffer = await QRcode.toBuffer(url, {
        errorCorrectionLevel: 'H'
      });

      // Carregar o QR code gerado
      const qrImage = await Jimp.read(qrBuffer);

      // Carregar a logomarca]
      const logoPath = path.join(__dirname, '..', 'imagens', 'logo-qrcode.png');
      const logo = await Jimp.read(logoPath);

      // Redimensionar a logomarca
      logo.resize(qrImage.bitmap.width / 4, Jimp.AUTO);

      // Centralizar a logomarca sobre o QR code
      const x = (qrImage.bitmap.width - logo.bitmap.width) / 2;
      const y = (qrImage.bitmap.height - logo.bitmap.height) / 2;

      // Combinar as duas imagens
      qrImage.composite(logo, x, y, {
        mode: Jimp.BLEND_SOURCE_OVER,
        opacitySource: 1,
        opacityDest: 1
      });
      return qrImage.getBase64Async(Jimp.MIME_PNG);
    } catch (err) {
      console.log(err);
      return await QRcode.toDataURL(url);
    }
  },
  gerarSemLogo: url => {
    return QRcode.toDataURL(url);
  }
};
module.exports = QrCode;