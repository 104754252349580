import React, { useState, useEffect, useContext } from 'react';
import { Funcionalidade, TeleconsultoriaSpec, TipoUsuario, TipoVinculo } from '@stt-analise/util';
import { SttTranslateHook } from '@stt-componentes/core';
import Dashboard from '../../../components/Dashboard/Dashboard';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';

/**
 * RN - Regra de negócio para permitir a pesquisa pelos administradores.
 */
const validarFiltros = (filtros) => {
    if (usuarioSignal.value.rbac.montarAcesso(Funcionalidade.PAGINA_DASHBOARD_TELECONSULTORIA).tipoUsuario === TipoUsuario.ADMIN) return true;

    const ignoreKeys = [1];
    const newFiltros = filtros.filter((filtro) => !ignoreKeys.includes(filtro.key));

    // Se pelo menos 1 filtro for preenchido.
    const valid =
        filtros.filter((filtro) => filtro.valor != '' && filtro.valor != undefined && !(typeof filtro.valor === 'string' && filtro.valor.includes('null')))
            .length > 0;
    return valid;
};

const formatarDescricaoTipoVinculo = (tipoVinculo) => {
    switch (tipoVinculo) {
        case TipoVinculo.ADMIN:
            return 'Gestão Admin';
        case TipoVinculo.ESTADO:
            return 'Gestão Estadual';
        case TipoVinculo.REDE_TELECONSULTORIA:
            return 'Gestão Rede Teleconsultoria';
        case TipoVinculo.MUNICIPIO:
            return 'Gestão Municipal';
        default:
    }
    return `Gestão ${tipoVinculo}`;
};

function Teleconsultoria() {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [configFiltroSecundario, setConfigFiltroSecundario] = useState(null);
    const [filtrosSecundarios, setFiltrosSecundarios] = useState(null);
    const [disabledButton, setDisabledButton] = useState(false);
    const [descricaoTipoVinculo, setDescricaoTipoVinculo] = useState('');

    useEffect(() => {
        if (!filtrosSecundarios) return;
        const valid = validarFiltros(filtrosSecundarios);
        setDisabledButton(!valid);
    }, [filtrosSecundarios]);

    useEffect(() => {
        const acesso = usuarioSignal.value.rbac.montarAcesso(Funcionalidade.PAGINA_DASHBOARD_TELECONSULTORIA);
        const descricaoTipoVinculo = formatarDescricaoTipoVinculo(acesso.tipoVinculo);
        const _configFiltroSecundario = TeleconsultoriaSpec(acesso.tipoVinculo);

        setConfigFiltroSecundario(_configFiltroSecundario);
        setFiltrosSecundarios(_configFiltroSecundario.filtros);
        setDescricaoTipoVinculo(descricaoTipoVinculo);
        return () => {
            setConfigFiltroSecundario(null);
            setFiltrosSecundarios(null);
            setDescricaoTipoVinculo('');
        }
    }, []);

    return (
        configFiltroSecundario &&
        filtrosSecundarios && (
            <Dashboard
                title={`${strings.PaginaInicial.dashboardTeleconsultoria} - ${descricaoTipoVinculo}`}
                configFiltroSecundario={configFiltroSecundario}
                disabledButton={disabledButton}
                filtrosSecundarios={filtrosSecundarios}
                setFiltrosSecundarios={setFiltrosSecundarios}
            />
        )
    );
}

export default Teleconsultoria;
