import { signal } from '@preact/signals-react';

/**
 * Estados utilizados apenas para publicação de um relatório assinado (geração do PDF).
 */
export const relatorioAssinadoSignal = signal({
    dtUltimoProcessamento: null,
    dtPrincipalRotulo: null,
    dtPrincipalInicial: null,
    dtPrincipalFinal: null,
});

/**
 * Estados para gerenciar um relatório novo ou carregado.
 */
export const pesquisaSignal = signal({
    idPesquisa: null,
    titulo: null,
    login: null,
    isNew: false,
    openModalSalvarRelatorio: false,
});