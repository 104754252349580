import React from 'react';

import { FiltroConstants, TipoAtributo } from '@stt-analise/util';
import { TextFilter } from './text/TextFilter';
import { DateFilter } from './date/DateFilter';
import IntegerFilter from './number/IntegerFilter';

export const OperationWrapper = ({ cubo, metaAtributoSelected, disabled, filtro, onChangeFilter, preFiltros }) => {
    if (!metaAtributoSelected || !metaAtributoSelected.tipo) return <></>;

    let filter;
    switch (metaAtributoSelected.tipo) {
        case TipoAtributo.TEXTO:
        case TipoAtributo.TEXTO_GEO_UF:
        case TipoAtributo.TEXTO_GEO_COD_IBGE:
        case TipoAtributo.TEXTO_GEO:
            filter = (
                <TextFilter
                    cubo={cubo}
                    metaAtributoSelected={metaAtributoSelected}
                    disabled={disabled}
                    onChangeFilter={onChangeFilter}
                    filtro={filtro}
                    preFiltros={preFiltros}
                />
            );
            break;
        case TipoAtributo.NUMERICO_INTEIRO:
            filter = <IntegerFilter cubo={cubo} metaAtributoSelected={metaAtributoSelected} onChangeFilter={onChangeFilter} filtro={filtro} />;
            break;
        case TipoAtributo.TIMESTAMP_MS:
        case TipoAtributo.DATA_HORA:
            filter = <DateFilter cubo={cubo} metaAtributoSelected={metaAtributoSelected} onChangeFilter={onChangeFilter} filtro={filtro} />;
            break;
        default:
    }

    return <>{filter}</>;
};
