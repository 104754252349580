import React from 'react';
import { Funcionalidade } from '@stt-analise/util';
import { SttItCidades, SttItExames, SttItPontos, SttItSolicitacoes, SttMonitoracaoEventos, SttTabelaDados } from '@stt-componentes/icons';

// pages
import Inicio from '../pages/interno/inicio/Inicio';
import TabelaDados from '../pages/interno/tabela-dados/TabelaDados';
import TeleconsultoriaDashboard from '../pages/interno/dashboard/TeleconsultoriaDashboard';
import MapaInterativo from '../pages/interno/mapa-interativo/MapaInterativo';
import MapaInterativoTeledermato from '../pages/interno/mapa-interativo/MapaInterativoTeledermato';
import AlofonoDashboard from '../pages/interno/dashboard/AlofonoDashboard';
import ItinerarioExamesDashboard from '../pages/interno/dashboard/ItinerarioExamesDashboard';
import ItinerarioSolicitacoesDashboard from '../pages/interno/dashboard/ItinerarioSolicitacoesDashboard';
import ItinerarioCidadesDashboard from '../pages/interno/dashboard/ItinerarioCidadesDashboard';
import ItinerarioPontosDashboard from '../pages/interno/dashboard/ItinerarioPontosDashboard';
import AlofonoDashboardIndividual from '../pages/interno/dashboard/AlofonoDashboardIndividual';
import Agendamento from '../pages/interno/administrativo/Administrativo';

import { Strings } from '@stt-utilitarios/core';
const strings = { ...Strings.analise };

const HOME = {
    id: '1',
    text: strings.Comuns.paginaInicial,
    exact: false,
    path: '/apps/menu-principal',
    semPadding: true,
};

const SUBMODULOS = [
    {
        id: '3',
        text: strings.PaginaInicial.pivottable,
        exact: true,

        path: '/apps/tabela-de-dados',
        funcionalidade: Funcionalidade.PAGINA_TABELA_DADOS,
    },
    {
        id: '4',
        textParent: 'Dashboard',
        text: strings.PaginaInicial.dashboardTeleconsultoria,
        exact: true,

        path: '/apps/dashboard/teleconsultoria',
        funcionalidade: Funcionalidade.PAGINA_DASHBOARD_TELECONSULTORIA,
    },
    {
        id: '5',
        textParent: 'Mapa Interativo',
        text: strings.PaginaInicial.monitoracaoEventos,
        exact: true,

        path: '/apps/mapa-interativo',
        funcionalidade: Funcionalidade.PAGINA_MAPA_EVENTOS,
        semPadding: true,
    },
    {
        id: '12',
        textParent: 'Mapa Interativo',
        text: strings.PaginaInicial.monitoracaoEventosTeledermato,
        exact: true,

        path: '/apps/mapa-interativo/teledermato',
        funcionalidade: Funcionalidade.PAGINA_MAPA_EVENTOS_DERMATO,
        semPadding: true,
    },
    {
        id: '6',
        textParent: 'Dashboard',
        text: strings.PaginaInicial.dashboardItinerarioExames,
        exact: true,

        path: '/apps/dashboard/itinerario',
        funcionalidade: Funcionalidade.PAGINA_DASHBOARD_IT,
    },
    {
        id: '7',
        textParent: 'Dashboard',
        text: strings.PaginaInicial.dashboardItinerarioSolicitacoes,
        exact: true,

        path: '/apps/dashboard/itinerario-solicitacoes',
        funcionalidade: Funcionalidade.PAGINA_DASHBOARD_IT,
    },
    {
        id: '8',
        textParent: 'Dashboard',
        text: strings.PaginaInicial.dashboardItinerarioCidades,
        exact: true,

        path: '/apps/dashboard/itinerario-cidades',
        funcionalidade: Funcionalidade.PAGINA_DASHBOARD_IT,
    },
    {
        id: '9',
        textParent: 'Dashboard',
        text: strings.PaginaInicial.dashboardItinerarioPontos,
        exact: true,

        path: '/apps/dashboard/itinerario-pontos',
        funcionalidade: Funcionalidade.PAGINA_DASHBOARD_IT,
    },
    {
        id: '10',
        textParent: 'Dashboard',
        text: strings.PaginaInicial.dashboardAlofono,
        exact: true,

        path: '/apps/dashboard/alofono',
        funcionalidade: Funcionalidade.PAGINA_DASHBOARD_ALOFONO,
    },
    {
        id: '11',
        textParent: 'Dashboard',
        text: strings.PaginaInicial.dashboardAlofonoIndividual,
        exact: true,

        path: '/apps/dashboard/alofono-paciente',
        funcionalidade: Funcionalidade.PAGINA_DASHBOARD_ALOFONO,
    },
    {
        id: '14',
        text: strings.PaginaInicial.administrativo,
        exact: true,

        path: '/apps/administrativo',
        funcionalidade: Funcionalidade.PAGINA_ADMINISTRATIVO_AGENDAMENTO,
        disabledShortcut: true,
        semPadding: true,
    },
];

const IMG_MAP = {
    '/apps/tabela-de-dados': <SttTabelaDados style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%' }} width={160} height={160} />,
    '/apps/dashboard/teleconsultoria': <SttItCidades style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/alofono': <SttItExames style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/alofono-paciente': <SttItSolicitacoes style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/mapa-interativo': <SttMonitoracaoEventos style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/mapa-interativo/teledermato': <SttMonitoracaoEventos style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/itinerario': <SttItExames style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/itinerario-solicitacoes': <SttItSolicitacoes style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/itinerario-cidades': <SttItCidades style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/itinerario-pontos': <SttItPontos style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
};

const PAGES_MAP = {
    '/apps/menu-principal': Inicio,
    '/apps/tabela-de-dados': TabelaDados,
    '/apps/dashboard/teleconsultoria': TeleconsultoriaDashboard,
    '/apps/dashboard/alofono': AlofonoDashboard,
    '/apps/dashboard/alofono-paciente': AlofonoDashboardIndividual,
    '/apps/mapa-interativo': MapaInterativo,
    '/apps/mapa-interativo/teledermato': MapaInterativoTeledermato,
    '/apps/dashboard/itinerario': ItinerarioExamesDashboard,
    '/apps/dashboard/itinerario-solicitacoes': ItinerarioSolicitacoesDashboard,
    '/apps/dashboard/itinerario-cidades': ItinerarioCidadesDashboard,
    '/apps/dashboard/itinerario-pontos': ItinerarioPontosDashboard,
    '/apps/administrativo': Agendamento,
};

export default { HOME, SUBMODULOS, IMG_MAP, PAGES_MAP };
