import { gql } from '@apollo/client';

export const ADICIONAR_RELATORIO = gql`
    mutation ($_id: ID, $username: String!, $titulo: String!, $tipo: String!, $anexo: AnexoInput) {
        insertRelatorio(_id: $_id, username: $username, titulo: $titulo, tipo: $tipo, anexo: $anexo) {
            _id
        }
    }
`;

export const DELETAR_RELATORIO = gql`
    mutation ($username: String!, $titulo: String!) {
        deleteRelatorio(username: $username, titulo: $titulo)
    }
`;

export const GET_RELATORIOS = gql`
    query GetRelatorios($username: String!) {
        iRelatorios(username: $username) {
            _id
            titulo
            tipo
            bloqueado
            agendamento {
                keyQueue
            }
        }
    }
`;

export const GET_CUBES = gql`
    query GetCubes {
        iCubos {
            dataset
            rotulo
            grupo
        }
    }
`;

export const GET_AGENDAMENTO = gql`
    query GetRelatorio($idRelatorio: ID!) {
        iRelatorio(_id: $idRelatorio) {
            titulo
            anexo {
                cubo
            }
        }
        iAgendamento(idRelatorio: $idRelatorio) {
            idRelatorio
            cubo
            filtroIntervaloVariavel
            filtroIntervaloValor
            dtInicialExecucao
            cron
            emails
            ativo
        }
    }
`;

export const GET_RELATORIO = gql`
    query GetRelatorio($username: String!, $titulo: String!) {
        iRelatorio(username: $username, titulo: $titulo) {
            _id
            titulo
            tipo
            anexo {
                rows
                rowOrder
                cols
                colOrder
                atributos
                rendererName
                aggregatorList {
                    vals
                    aggTitle
                    aggregatorName
                }
                cubo
                filtros_secundarios {
                    key
                    tipoComponente
                    atributosPermitidos
                    disabled
                    valor
                    atributo
                }
                habilitarAgrupamento
            }
        }
    }
`;

export const GET_META_ATRIBUTOS_AGENDAMENTO = gql`
    query GetMetaAtributos($cubo: String!) {
        iMetaAtributosDate(cubo: $cubo) {
            key
            rotulo
            tipo
            tipoComponente
            atributo
            grupo
        }
    }
`;

export const GET_META_ATRIBUTOS = gql`
    query GetMetaAtributos($cubo: String!) {
        iMetaAtributosPivottable(cubo: $cubo) {
            key
            rotulo
            tipo
            tipoComponente
            atributo
            grupo
        }
    }
`;

export const REQUEST_FILTRO = gql`
    query GetFiltroAutocompletar($cubo: String!, $atributo: String!, $valor: String, $filtros: [Filtro]) {
        iAutocompletar(cubo: $cubo, atributo: $atributo, valor: $valor, filtros: $filtros)
    }
`;

export const GERAR_DATASET = gql`
    query GerarDataset($cubo: String!, $atributos: [String], $filtros: [Filtro], $medidas: [MedidaOrString]) {
        iScanDataset(cubo: $cubo, atributos: $atributos, filtros: $filtros, medidas: $medidas)
    }
`;

export const GET_METADADOS_ETL = gql`
    query GetMetadadosEtl($cubo: String!) {
        iMetaETL(cubo: $cubo) {
            tipo
            dtInicioProcessamento
            dtProcessado
            dtUltimoRegistro
            statusPorcentagem
        }
    }
`;

export const GET_DATE_LIMIT_BY_CUBE = gql`
    query GetDateLimitByCube($cubo: String!) {
        iTimeBoundary(cubo: $cubo) {
            minTime
            maxTime
        }
    }
`;

export const ATUALIZAR_AGENDAMENTO = gql`
    mutation (
        $idRelatorio: ID!
        $filtroIntervaloVariavel: String!
        $filtroIntervaloValor: String!
        $dtInicialExecucao: Date!
        $cron: String!
        $emails: [String]
        $ativo: Boolean!
    ) {
        atualizarAgendamento(
            idRelatorio: $idRelatorio
            filtroIntervaloVariavel: $filtroIntervaloVariavel
            filtroIntervaloValor: $filtroIntervaloValor
            dtInicialExecucao: $dtInicialExecucao
            cron: $cron
            emails: $emails
            ativo: $ativo
        )
    }
`;

export const ENVIAR_EMAIL_AGENDAMENTO = gql`
    mutation ($idRelatorio: ID!) {
        enviarEmail(idRelatorio: $idRelatorio)
    }
`;