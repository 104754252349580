import React, { useState } from 'react';
import PivotTableUI from '@stt-analise/pivottable';
import TableRenderers from '@stt-analise/pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from '@stt-analise/pivottable/PlotlyRenderers';
import MapRenderer from '../../components/Dashboard/Map/MapRenderer';
import MuiAgg from '../../components/Pivottable/MuiAgg';

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const PivottableWrapper = ({ datasetServer, metaAtributos, habilitarNumerosDeLinha, pivotState, setPivotState }) => {
    // const [pivotState, setPivotState] = useState(null);

    return (
        <PivotTableUI
            // hiddenAttributes={hiddenAttributes}
            data={datasetServer}
            meta={metaAtributos}
            enableRowNumbers={habilitarNumerosDeLinha}
            unusedOrientationCutoff={Infinity}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers, {
                Mapa: MapRenderer,
            })}
            onChange={(s) => {
                console.log('estado da tabela dinamica: ', s);
                setPivotState(s);
            }}
            {...pivotState}
            ComponentAgg={MuiAgg}
        />
    );
};

export default PivottableWrapper;
