import Permissao from './Permissao';
import TipoVinculo from './TipoVinculo';
var Funcionalidade;
(function (Funcionalidade) {
  // Acesso ao módulo de análise
  Funcionalidade["MODULO"] = "MODULO";
  // Acesso as páginas do módulo de análise
  Funcionalidade["PAGINA_DASHBOARD_TELECONSULTORIA_MUNICIPAL"] = "PAGINA_DASHBOARD_TELECONSULTORIA_MUNICIPAL";
  Funcionalidade["PAGINA_DASHBOARD_TELECONSULTORIA"] = "PAGINA_DASHBOARD_TELECONSULTORIA";
  Funcionalidade["PAGINA_DASHBOARD_IT"] = "PAGINA_DASHBOARD_IT";
  Funcionalidade["PAGINA_DASHBOARD_ALOFONO"] = "PAGINA_DASHBOARD_ALOFONO";
  Funcionalidade["PAGINA_MAPA_EVENTOS"] = "PAGINA_MAPA_EVENTOS";
  Funcionalidade["PAGINA_MAPA_EVENTOS_DERMATO"] = "PAGINA_MAPA_EVENTOS_DERMATO";
  Funcionalidade["PAGINA_TABELA_DADOS"] = "PAGINA_TABELA_DADOS";
  Funcionalidade["PAGINA_ADMINISTRATIVO_AGENDAMENTO"] = "PAGINA_ADMINISTRATIVO_AGENDAMENTO";
  // Acesso aos cubos de dados
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_ESTOMATO"] = "FUNC_TABELA_DADOS_CUBO_ESTOMATO";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_DERMATO"] = "FUNC_TABELA_DADOS_CUBO_DERMATO";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_TFD"] = "FUNC_TABELA_DADOS_CUBO_TFD";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_TFD_PASSAGENS"] = "FUNC_TABELA_DADOS_CUBO_TFD_PASSAGENS";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_ECG"] = "FUNC_TABELA_DADOS_CUBO_ECG";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_EEG"] = "FUNC_TABELA_DADOS_CUBO_EEG";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_TELEDIAGNOSTICO"] = "FUNC_TABELA_DADOS_CUBO_TELEDIAGNOSTICO";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_PACS"] = "FUNC_TABELA_DADOS_CUBO_PACS";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_PONTOS"] = "FUNC_TABELA_DADOS_CUBO_PONTOS";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_FUNCIONARIO_ATIVIDADES"] = "FUNC_TABELA_DADOS_CUBO_FUNCIONARIO_ATIVIDADES";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_FUNCIONARIO_ALUNOS_ATIVOS"] = "FUNC_TABELA_DADOS_CUBO_FUNCIONARIO_ALUNOS_ATIVOS";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_FUNCIONARIO"] = "FUNC_TABELA_DADOS_CUBO_FUNCIONARIO";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_ESPIRO"] = "FUNC_TABELA_DADOS_CUBO_ESPIRO";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_TELEATENDIMENTO"] = "FUNC_TABELA_DADOS_CUBO_TELEATENDIMENTO";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_POLISSONO"] = "FUNC_TABELA_DADOS_CUBO_POLISSONO";
  // Acesso aos cubos de teleconsultorias, alguns são inativadas, porém usadas apenas nos dashboards
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_TELECONSULTORIAS"] = "FUNC_TABELA_DADOS_CUBO_TELECONSULTORIAS";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_DASHBOARD_TELECONSULTORIA"] = "FUNC_TABELA_DADOS_CUBO_DASHBOARD_TELECONSULTORIA";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_TELECONSULTORIAS_EM_ABERTO"] = "FUNC_TABELA_DADOS_CUBO_TELECONSULTORIAS_EM_ABERTO";
  // Acesso aos cubos que pertencem ao dashboard de IT
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_ITINERARIO_EXAMES"] = "FUNC_TABELA_DADOS_CUBO_ITINERARIO_EXAMES";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_ITINERARIO_SOLICITACOES"] = "FUNC_TABELA_DADOS_CUBO_ITINERARIO_SOLICITACOES";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_ITINERARIO_CIDADES"] = "FUNC_TABELA_DADOS_CUBO_ITINERARIO_CIDADES";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_ITINERARIO_PONTOS"] = "FUNC_TABELA_DADOS_CUBO_ITINERARIO_PONTOS";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS"] = "FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS_LISTA_PRESENCA"] = "FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS_LISTA_PRESENCA";
  Funcionalidade["FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS_ACESSOS"] = "FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS_ACESSOS";
})(Funcionalidade || (Funcionalidade = {}));
const FuncionalidadesMap = {
  [Funcionalidade.MODULO]: {
    descricao: 'Acesso ao módulo análise',
    permissoes: [Permissao.ANALISE],
    tipoVinculo: [TipoVinculo.ADMIN, TipoVinculo.ESTADO, TipoVinculo.MUNICIPIO, TipoVinculo.REDE_TELEMEDICINA, TipoVinculo.REDE_TELECONSULTORIA, TipoVinculo.INSTITUICAO]
  },
  // ACESSO ÁS PÁGINAS DO MÓDULO DE ANÁLISE
  [Funcionalidade.PAGINA_DASHBOARD_TELECONSULTORIA]: {
    descricao: 'Acesso a página do painel de teleconsultoria.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_TELECONSULTORIA],
    tipoVinculo: [TipoVinculo.ADMIN, TipoVinculo.ESTADO, TipoVinculo.MUNICIPIO, TipoVinculo.REDE_TELECONSULTORIA]
  },
  [Funcionalidade.PAGINA_DASHBOARD_IT]: {
    descricao: 'Acesso a página do painel de IT.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_IT],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.PAGINA_DASHBOARD_ALOFONO]: {
    descricao: 'Acesso a página do painel de Alofono.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_ALOFONO],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.PAGINA_MAPA_EVENTOS]: {
    descricao: 'Acesso a página do mapa de eventos.',
    permissoes: [Permissao.ANALISE_ACESSAR_MAPA_EVENTOS],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.PAGINA_MAPA_EVENTOS_DERMATO]: {
    descricao: 'Acesso a página do mapa de eventos de dermatologia.',
    permissoes: [Permissao.ANALISE_ACESSAR_MAPA_EVENTOS_DERMATO],
    tipoVinculo: [TipoVinculo.ADMIN, TipoVinculo.ESTADO]
  },
  [Funcionalidade.PAGINA_TABELA_DADOS]: {
    descricao: 'Acesso a página de tabela de dados.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_ESTOMATO, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_DERMATO, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TFD, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TFD_PASSAGENS, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_ECG, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_EEG, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_EXAMES, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_PACS, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_PONTOS, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELECONSULTORIAS, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELECONSULTORIAS_EM_ABERTO, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO_ATIVIDADES, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO_ALUNOS_ATIVOS, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELEATENDIMENTO, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_ESPIRO, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_POLISSONO, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_WEBCONF, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_WEBCONF_ACESSOS, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_WEBCONF_LISTA_PRESENCA],
    tipoVinculo: [TipoVinculo.ADMIN, TipoVinculo.ESTADO, TipoVinculo.MUNICIPIO, TipoVinculo.REDE_TELEMEDICINA, TipoVinculo.REDE_TELECONSULTORIA, TipoVinculo.INSTITUICAO]
  },
  [Funcionalidade.PAGINA_ADMINISTRATIVO_AGENDAMENTO]: {
    descricao: 'Acesso a página de agendamento.',
    permissoes: [Permissao.ANALISE_ACESSAR_ADMINISTRATIVO_AGENDAMENTO],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  // ACESSO AOS CUBOS DE DADOS
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_DASHBOARD_TELECONSULTORIA]: {
    descricao: 'Acesso ao cubo dashboard teleconsultoria.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_TELECONSULTORIA],
    tipoVinculo: [TipoVinculo.ADMIN, TipoVinculo.ESTADO, TipoVinculo.REDE_TELECONSULTORIA, TipoVinculo.MUNICIPIO]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_TELECONSULTORIAS_EM_ABERTO]: {
    descricao: 'Acesso ao cubo de teleconsultoria em aberto.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_TELECONSULTORIA, Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELECONSULTORIAS_EM_ABERTO],
    tipoVinculo: [TipoVinculo.ADMIN, TipoVinculo.ESTADO, TipoVinculo.REDE_TELECONSULTORIA, TipoVinculo.MUNICIPIO]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_ESTOMATO]: {
    descricao: 'Acesso ao cubo estomato.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_ESTOMATO],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_DERMATO]: {
    descricao: 'Acesso ao cubo dermatologia.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_DERMATO],
    tipoVinculo: [TipoVinculo.ADMIN, TipoVinculo.ESTADO]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_TFD]: {
    descricao: 'Acesso ao cubo TFD.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TFD],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_TFD_PASSAGENS]: {
    descricao: 'Acesso ao cubo TFD passagens.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TFD_PASSAGENS],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_ECG]: {
    descricao: 'Acesso ao cubo ECG.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_ECG],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_EEG]: {
    descricao: 'Acesso ao cubo EEG.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_EEG],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_TELEDIAGNOSTICO]: {
    descricao: 'Acesso ao cubo exames.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_EXAMES],
    tipoVinculo: [TipoVinculo.ADMIN, TipoVinculo.ESTADO, TipoVinculo.MUNICIPIO, TipoVinculo.REDE_TELEMEDICINA, TipoVinculo.INSTITUICAO]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_PACS]: {
    descricao: 'Acesso ao cubo PACS.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_PACS],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_PONTOS]: {
    descricao: 'Acesso ao cubo pontos.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_PONTOS],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_TELECONSULTORIAS]: {
    descricao: 'Acesso ao cubo teleconsultorias.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELECONSULTORIAS],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_POLISSONO]: {
    descricao: 'Acesso ao cubo polissonografia.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_POLISSONO],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_ESPIRO]: {
    descricao: 'Acesso ao cubo espirometria.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_ESPIRO],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_TELEATENDIMENTO]: {
    descricao: 'Acesso ao cubo teleatendimento.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELEATENDIMENTO],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_FUNCIONARIO_ATIVIDADES]: {
    descricao: 'Acesso ao cubo funcionário atividades.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO_ATIVIDADES],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_FUNCIONARIO_ALUNOS_ATIVOS]: {
    descricao: 'Acesso ao cubo funcionário alunos ativos.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO_ALUNOS_ATIVOS],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_FUNCIONARIO]: {
    descricao: 'Acesso ao cubo funcionário.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_ITINERARIO_EXAMES]: {
    descricao: 'Acesso ao cubo itinerário exames.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_IT],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_ITINERARIO_SOLICITACOES]: {
    descricao: 'Acesso ao cubo itinerário solicitações.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_IT],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_ITINERARIO_CIDADES]: {
    descricao: 'Acesso ao cubo itinerário cidades.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_IT],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_ITINERARIO_PONTOS]: {
    descricao: 'Acesso ao cubo itinerário pontos.',
    permissoes: [Permissao.ANALISE_ACESSAR_DASHBOARD_IT],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS]: {
    descricao: 'Acesso ao cubo webconferências.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_WEBCONF],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS_ACESSOS]: {
    descricao: 'Acesso ao cubo webconferências: acessos.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_WEBCONF_ACESSOS],
    tipoVinculo: [TipoVinculo.ADMIN]
  },
  [Funcionalidade.FUNC_TABELA_DADOS_CUBO_WEBCONFERENCIAS_LISTA_PRESENCA]: {
    descricao: 'Acesso ao cubo webconferências: lista de presença.',
    permissoes: [Permissao.ANALISE_ACESSAR_TABELA_DADOS_CUBO_WEBCONF_LISTA_PRESENCA],
    tipoVinculo: [TipoVinculo.ADMIN]
  }
};
export { Funcionalidade, FuncionalidadesMap };