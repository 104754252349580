'use strict';

const LogUsuario = {
  tabela: 'public.log_usuario',
  inserir: async function (dbClient) {
    let transactionHandler = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let dados = arguments.length > 2 ? arguments[2] : undefined;
    const {
      idUsuario,
      evento,
      url,
      codigo,
      prioridade,
      arquivo,
      linha
    } = dados;
    let insert = dbClient.insert({
      id_usuario: idUsuario,
      evento: evento,
      url: url,
      codigo: codigo,
      prioridade: prioridade,
      arquivo: arquivo,
      linha: linha
    }).into(LogUsuario.tabela);
    if (transactionHandler) {
      insert = insert.transacting(transactionHandler);
    }
    await insert;
  }
};
module.exports = LogUsuario;