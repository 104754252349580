import React, { useRef, useEffect } from 'react';

import { ConvertGIS } from '@stt-analise/util';

// styles
import useStyles from './styles';

const MapChart = ({ datasetDruid, metaAtributos }) => {
    const classes = useStyles();
    const iFrame = useRef(null);

    useEffect(() => {
        const { data, dataTypes } = ConvertGIS.makeDatasetGeo([...datasetDruid], metaAtributos);
        iFrame.current.contentWindow.SAIKU_PAYLOAD = {
            data, dataTypes
        };
    }, []);

    return <iframe className={classes.iFrameMapContainer} allowFullScreen={true} ref={iFrame} referrerPolicy="unsafe-url" src={`/iframes/tmap/index.html?version=${global.gConfig?.modulo_versao}`} width="100%" height="100%" />;
};

export default MapChart;
