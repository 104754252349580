module.exports = {
  acessarExame: 'Acessar exame',
  acesseExame: 'Acesse seu exame',
  aluno: 'Aluno',
  anosTabagismo: 'Anos de tabagismo',
  antecedentes: 'Antecedentes',
  arcadaAfetada: 'Arcada afetada',
  arcadaAfetadaConj: {
    ambas: 'Ambas',
    inferior: 'Inferior',
    naoSeAplica: 'Não se aplica/não informada',
    superior: 'Superior'
  },
  arquivosAdicionais: 'Arquivos adicionais',
  assintomatico: 'Assintomático',
  biopsiaConj: {
    aspiracao: 'Aspiração',
    curetagem: 'Curetagem',
    excisional: 'Excisional',
    incisional: 'Incisional',
    pecaCirurgica: 'Peça cirúrgica'
  },
  BP: 'BP',
  caracteristicas: 'Características',
  caracteristicasClinicasLesao: 'Características clínicas da lesão',
  caracteristicasDor: 'Características da dor',
  caracteristicasGerais: 'Características gerais',
  caracterizacaoDor: 'Caracterização da dor torácica',
  cardiopatiaCongenita: 'Cardiopatia congênita',
  cid10: 'CID10',
  cid10Categoria: 'CID10 (categoria)',
  cid10Subcategoria: 'CID10 (subcategoria)',
  cigarrosPorDia: 'Cigarros por dia',
  cigarrosPorDia: 'Quantos cigarros/dia?',
  classificacaoDor: 'Classificação da dor',
  cnes: 'CNES',
  codigoVarredura: 'Código de varredura',
  comorbidadeFatorRisco: 'Co-morbidades e fatores de risco',
  complemento: 'Complemento',
  cor: 'Cor',
  dadosEncaminhamento: 'Dados do encaminhamento',
  dadosExame: 'Dados do exame',
  dadosInstituicao: 'Dados da instituição',
  dadosResultados: 'Resultados',
  dataExame: 'Data do exame',
  exameSemImagens: 'Este exame ainda não possui imagens',
  dataProcedimento: 'Data do procedimento',
  decs: 'DECS',
  definicao: 'Definição',
  descricaoExame: 'Descrição do exame',
  descricaoTamanho: 'Tamanho',
  descritores: 'Descritores',
  diagnosticoClinico: 'Diagnóstico clínico',
  dor: 'Dor',
  dorCardiacaIsquemica: 'Dor cardíaca isquêmica',
  dorCardiacaNaoIsquemica: 'Dor cardíaca não isquêmica',
  dorMomentoExame: 'Dor no momento do exame',
  dorNaoCardiaca: 'Dor não cardíaca',
  duracaoDorConj: {
    continua: 'Contínua',
    intermitente: 'Intermitente',
    passageira: 'Passageira'
  },
  duvidasAcessar: 'Dúvidas de como acessar?',
  encaminhamento: 'Encaminhamento',
  endereco: 'Endereço',
  especificacaoProcedencia: 'Especificação da procedência',
  espontaneidade: 'Espontaneidade',
  espontaneidadeDorConj: {
    espontanea: 'Espontânea',
    provocada: 'Provocada'
  },
  estruturaAfetada: 'Estrutura afetada',
  ex: 'Ex',
  exame: 'Exame',
  exameSemPermissaoGaleria: 'A visualização de imagens desse tipo de exame não é permitida no acesso via protocolo.',
  executor: 'Executor',
  exposicaoSolHorasDia: 'Exposição ao sol (horas por dia)',
  exposicaoSolMesesAno: 'Exposição ao sol (meses por ano)',
  fatorRisco: 'Fatores de risco',
  febreReumatica: 'Febre reumática',
  fototipo: 'Fototipo',
  frequenciaConsumo: 'Frequência de consumo',
  baixandoEstudoDicom: 'Baixando estudo DICOM. Isto poderá levar alguns minutos...',
  frequenciaDrogasConj: {
    finalSemana: 'Aos finais de semana',
    semFrequencia: 'Sem frequência definida',
    todosDias: 'Todos os dias'
  },
  frequenciaEtilismoConj: {
    finalSemana: 'Aos finais de semana',
    semFrequencia: 'Sem frequência definida',
    todosDias: 'Todos os dias'
  },
  habitos: 'Hábitos (especialmente lesões vermelhas ulceradas ou lesões brancas)',
  historiaClinica: 'História clínica',
  historiaClinica: 'História clínica',
  historiaMorbidaFamiliar: 'História mórbida familiar',
  historiaMorbidaFamiliarOutros: 'História mórbida familiar (outros)',
  historiaMorbidaFamiliarPele: 'História mórbida familiar (pele)',
  historiaMorbidaPregressa: 'História mórbida pregressa',
  historiaMorbidaPregressaOutros: 'História mórbida pregressa (outros)',
  historiaMorbidaPregressaPele: 'História mórbida pregressa (pele)',
  indicacaoCid10: 'Indicação CID10',
  indicacaoClinica: 'Indicação clínica',
  indicacaoExame: 'Indicação do exame',
  intensidadeDor: 'Intensidade da dor (1-10)',
  intensidadeDorConj: {
    intensa: 'Intensa',
    leve: 'Leve',
    moderada: 'Moderada'
  },
  labelExame: 'Exame',
  labelImagem: 'Imagens',
  labelPaciente: 'Paciente',
  labelResultado: 'Resultado',
  labelSubtituloExame: 'Dados do exame',
  labelSubtituloImagem: 'Imagens dos exames',
  labelSubtituloPaciente: 'Dados do paciente',
  labelSubtituloResultado: 'Exam results',
  ladoAfetado: 'Lado afetado',
  lesao: 'Lesão',
  lesaoAfetadoConj: {
    bilateral: 'Bilateral (direito e esquerdo)',
    linhaMedia: 'Linha média',
    unilateral: 'Unilateral (direito ou esquerdo)'
  },
  lesaoFundamental: 'Lesão fundamental',
  lesaoRaspagem: 'Lesão sai a raspagem',
  localidade: 'Localidade',
  localInfeccao: 'Locais de infecção',
  localizacaoDor: 'Localização da dor',
  localizacaoDorConj: {
    difusa: 'Difusa',
    localizada: 'Localizada'
  },
  localizacaoLesao: 'Localização da lesão',
  localLesao: 'Local da lesão',
  medicamentos: 'Medicamentos',
  medicamentosUso: 'Medicamentos em uso',
  modalidadeNaoPermitida: 'O exame que você tentou acesso ainda não está disponível na nova plataforma de acesso aos exames. Você será redirecionado ao antigo portal para visualização.',
  motivoExame: 'Motivos do exame',
  nao: 'Não',
  naoInformado: 'Não informado',
  observacao: 'Observação',
  observacaoTecnico: 'Observação do técnico',
  observacoesAdicionaisRegiaoLesao: 'Observações adicionais da região da lesão',
  ocorrenciaLesao: 'Ocorrência de lesão',
  ocorrenciaLesaoConj: {
    lesaoUnica: 'Lesão única',
    lesoesMultiplas: 'Lesões múltiplas'
  },
  outrasComorbidades: 'Outras comorbidades',
  outrasHipotesesDiagnosticas: 'Outras hipóteses diagnósticas',
  outrasInfeccoes: 'Outras infecções',
  outrasInformacoes: 'Outras informações',
  outrosExames: 'Outros exames',
  outrosMedicamentos: 'Outros medicamentos',
  pacienteAssintomatico: 'Paciente sem dores',
  pacienteBebe: 'O paciente bebe?',
  pacienteBebeTempo: 'Bebe há quanto tempo, ou bebeu por quanto tempo?',
  pacienteDrogas: 'O paciente usa drogas?',
  pacienteDrogasTempo: 'Usa drogas há quanto tempo, ou usou drogas por quanto tempo?',
  pacienteFuma: 'O paciente fuma?',
  pacienteFumaTempo: 'Fuma há quanto tempo, ou fumou por quanto tempo?',
  pacienteParouBeberTempo: 'Parou de beber há quanto tempo?',
  pacienteParouDrogasTempo: 'Parou de usar drogas há quanto tempo?',
  pacienteParouFumarTempo: 'Parou de fumar há quanto tempo?',
  palpacao: 'Palpação',
  peso: 'Peso',
  possibilidadeTraumaLocal: 'Possibilidade de trauma local',
  possibilidadeTraumaLocalConj: {
    mordedura: 'Mordedura',
    proteseMalAdaptada: 'Prótese mal adaptada',
    semTrauma: 'Sem trauma'
  },
  preOperatorio: 'Pré-operatório',
  procedenciaConj: {
    clinicaOdontologica: 'Clínica Odontológica',
    externoUFSC: 'Externo à UFSC',
    hu: 'HU'
  },
  procedenciaExame: 'Procedência do exame',
  protocolo: 'Protocolo',
  protocoloInvalido: 'O protocolo se encontra no formato inválido.',
  protocoloNaoExiste: 'O protocolo solicitado não tem relação a nenhum exame cadastrado no sistema ou ao CPF informado. Revise o protocolo, seu CPF e tente novamente.',
  queixaPrincipal: 'Queixa principal',
  quilos: 'kg(s)',
  regiaoAfetada: 'Região afetada',
  regiaoBiopsia: 'Região da biópsia',
  resultado: 'Resultado',
  resultadoBiopsia: 'Resultados da biópsia',
  resultadoExamesAnteriores: 'Resultado de exames anteriores',
  resumoHistoriaClinica: 'Resumo da história clínica',
  sair: 'Sair',
  sbc: 'SBC',
  semImagensExibicao: 'Não há imagens disponíveis para exibição.',
  semPossibilidade: 'Sem possibilidade',
  semTratamentoPrevio: 'Sem tratamento prévio',
  sintomaDor: 'Sintomas da dor',
  superficie: 'Superfície',
  surtiuEfeito: 'Surtiu efeito?',
  tempoEvolucao: 'Tempo de evolução',
  tipoBebida: 'Tipo de bebida',
  tipoBiopsia: 'Tipo de biópsia',
  tipoCrescimento: 'Tipo de crescimento',
  tipoDorConj: {
    paroxistica: 'Paroxística',
    pulsatil: 'Pulsátil',
    queimacao: 'Queimação'
  },
  tipoDrogas: 'Tipo',
  tipoExame: 'Tipo de exame',
  tipoExameConj: {
    anatomopatologico: 'Anatomopatológico',
    citologico: 'Citológico'
  },
  tipoFumo: 'Tipo de fumo',
  tipoLesao: 'Tipo de lesão',
  tipoLesaoConj: {
    intraossea: 'Intraóssea',
    subcutanea: 'Subcutânea',
    submucosa: 'Submucosa',
    superficial: 'Superficial'
  },
  titulo: 'Dúvidas sobre como acessar',
  tituloAlerta: 'Alerta',
  tituloArquivoAcessoExames: 'como_acessar_exames_STT',
  tituloErro: 'Erro',
  tituloExame: 'Exame',
  tituloExameAtual: 'Este Exame',
  tituloOk: 'Ok',
  tituloOutrosExames: 'Outros exames',
  tratamentoMedicacao: 'Tratamento/medicação',
  tratamentoPrevio: 'Tratamentos prévios',
  unidadeMedida: 'Unidade de medida',
  usoFotoprotetorFrequenciaDia: 'Frequência de uso do fotoprotetor (em dias)',
  usoFotoprotetorTempo: 'Tempo de uso do fotoprotetor',
  usoProtese: 'Paciente usuário de próteses',
  valvulopatia: 'Valvulopatia',
  definicaoSBC: 'Definição SBC',
  redirecionamentoValidacaoDados: 'Você será redirecionado para validar os seus dados de acesso ao exame.'
};