var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
const confirmationFunc = (msgAlert, emitirAlerta) => __awaiter(void 0, void 0, void 0, function* () {
  const emitMsg = new Promise((resolve, _) => {
    emitirAlerta(msgAlert, () => {
      resolve(true);
    }, () => {
      resolve(false);
    });
  });
  return emitMsg;
});
const confirmationFuncWithRule = (msgAlert, rule, emitirAlerta) => __awaiter(void 0, void 0, void 0, function* () {
  const emitMsg = new Promise((resolve, _) => {
    if (!rule) {
      emitirAlerta(msgAlert, () => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    } else {
      resolve(true);
    }
  });
  return emitMsg;
});
export default {
  confirmationFunc,
  confirmationFuncWithRule
};