import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { TimestampMSSelectType } from '../text/SelectType';

import { FiltroConstants, TipoAtributo } from '@stt-analise/util';
import IntervalMSWrapper from './IntervalMSWrapper';

const WithOp = ({ children, filtro, onChangeFilter }) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={2} sm={2} md={2}>
                <TimestampMSSelectType filtro={filtro} onChangeFilter={onChangeFilter} />
            </Grid>
            <Grid item xs={10} sm={10} md={10}>
                {children}
            </Grid>
        </Grid>
    );
};

const WithoutOp = ({ children }) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
                {children}
            </Grid>
        </Grid>
    );
};

export const DateFilter = ({ cubo, metaAtributoSelected, disabled, filtro, onChangeFilter, enableSelectOp = false }) => {
    useEffect(() => {
        onChangeFilter({ ...filtro, tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS });
    }, [metaAtributoSelected]);

    let filter;
    switch (filtro.tipoComponente) {
        case FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIME:
        case FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS:
            filter = <IntervalMSWrapper cubo={cubo} metaAtributoSelected={metaAtributoSelected} onChangeFilter={onChangeFilter} filtro={filtro} />;
            break;
        default:
            filter = '';
    }

    return enableSelectOp ? (
        <>
            <WithOp>{filter}</WithOp>
        </>
    ) : (
        <>
            <WithoutOp>{filter}</WithoutOp>
        </>
    );
};
