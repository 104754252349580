import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { ConvertCard } from '@stt-analise/util';
import { Box } from '@material-ui/core';
import { SttText } from '@stt-componentes/core';
import Description from '../Title/Description';

const useStyles = makeStyles(theme => ({
    root: {
        height: 180,
    },
    num: {
        fontSize: '2.0rem',
        marginTop: '35px',
    },
    paragraph: {
        margin: theme.spacing(0.5)
    }
}));

function CardChart({ title, children, subtitle, description }) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Box display="flex" justifyContent="center">
                <Grid container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                // justify="center"
                >
                    <Typography variant="h3" component="h3" className={classes.num} >
                        {children}
                    </Typography>
                    {subtitle !== undefined && <Typography variant="h3" component="h3" style={{ marginTop: '-15px', padding: 0 }} >
                        ({subtitle})
                    </Typography>
                    }
                    <SttText component="p" align='center' className={classes.paragraph}>
                        {title}
                        {description && <Description>{description}</Description>}
                    </SttText>
                </Grid>
            </Box>
        </Card>
    );
}

export function CardAbsoluteChart({ datasetDruid, title, subtitle, having, description }) {
    return (
        <CardChart title={title} description={description} subtitle={subtitle} >
            {ConvertCard.toValorAbsoluto([...datasetDruid], having)}
        </CardChart >
    );
}

export function CardPercentageChart({ datasetDruid, title, having, enableAbsoluteValue, description }) {
    return (
        <CardChart title={title} description={description} subtitle={enableAbsoluteValue && ConvertCard.toValorAbsoluto([...datasetDruid], having)}>
            {ConvertCard.toPorcentagem([...datasetDruid], having)}
        </CardChart >
    );
}

export function CardPercentage2Chart({ datasetDruid, title, having, description }) {
    return (
        <CardChart title={title} description={description} subtitle={ConvertCard.toValorAbsoluto([...datasetDruid], having)}>
            {ConvertCard.toPorcentagem([...datasetDruid], having)}
        </CardChart>
    );
}

export function CardAverageChart({ datasetDruid, title, description }) {
    return (
        <CardChart title={title} description={description}>
            {ConvertCard.toAverage([...datasetDruid])}
        </CardChart >
    );
}

export function CardDistinctCountChart({ datasetDruid, title, description }) {
    return (
        <CardChart title={title} description={description}>
            {ConvertCard.toDistincCount([...datasetDruid])}
        </CardChart >
    );
}