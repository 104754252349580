import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

export default function ChipsArray({ data = [] }) {
    const classes = useStyles();

    if (data.length === 0) return <></>;

    return (
        <>
            <Paper elevation={1} variant="outlined" component="ul" className={classes.root}>
                {data.map((label, indice) => {
                    return (
                        <li key={indice}>
                            <Chip
                                label={label}
                                className={classes.chip}
                            />
                        </li>
                    );
                })}
            </Paper>
        </>
    );
}