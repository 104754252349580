'use strict';

const RBAC = {
  temPerfil: async (usuario, perfil) => {
    return usuario.perfisRBAC.some(p => {
      return p.identificador === perfil;
    });
  },
  temPermissao: async (usuario, permissao) => {
    if (usuario && usuario.permissoesRBAC) {
      return usuario.permissoesRBAC.includes(permissao);
    }
  }
};
module.exports = RBAC;