import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ButtonLoading from '../../Button/ButtonLoading';
import { SttButton } from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
        backgroundColor: (props) => (props.loading ? 'rgba(0, 0, 0, 0.5)' : '#f7f7f7'),
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    sttMuiListItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function contains(a, b) {
    return a.filter((word) => word.toLowerCase().includes(b.toLowerCase()));
}

function applyFilterLeft(words, right, valueSearched) {
    const newLeftList = contains(words, valueSearched);
    return not(newLeftList, right);
}

const InFilter = ({ words, initialValueSearch, wordsSelected, onClickSearch, onClickSave, loading = false }) => {
    const classes = useStyles({ loading });
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(applyFilterLeft(words, wordsSelected, initialValueSearch));
    const [right, setRight] = React.useState(wordsSelected);
    const [value, setValue] = React.useState(initialValueSearch);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    useEffect(() => {
        setLeft(applyFilterLeft(words, wordsSelected, value));
    }, [words]);

    const customList = (items) => (
        <>
            <Paper className={classes.paper}>
                <List dense component="div" role="list">
                    {items.map((value) => {
                        const labelId = `transfer-list-item-${value}-label`;
                        return (
                            <ListItem key={value} role="listitem" button onClick={handleToggle(value)} className={classes.sttMuiListItem}>
                                <ListItemIcon>
                                    <Checkbox
                                        size={'small'}
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText size={'small'} id={labelId} primary={value} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Paper>
            <span>Total: {items.length}</span>
        </>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <TextField
                        id="outlined-basic"
                        label="Pesquisar"
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={(event) => {
                            const newValue = event.target.value;
                            const newLeftList = applyFilterLeft(words, right, newValue);
                            setValue(newValue);
                            setLeft(newLeftList);
                        }}
                    />
                    <ButtonLoading loading={loading} onClick={() => onClickSearch({ words, valueSearched: value, wordsSelected: right })}>
                        Pesquisar
                    </ButtonLoading>
                </Grid>
            </Grid>
            <Grid item>{customList(left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(right)}</Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <SttButton variant="contained" color="primary" onClick={() => onClickSave({ words, valueSearched: value, wordsSelected: right })}>
                        Confirmar
                    </SttButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

InFilter.propTypes = {
    wordsSelected: PropTypes.arrayOf(PropTypes.string),
    words: PropTypes.arrayOf(PropTypes.string),
    initialValueSearch: PropTypes.string,
    onClickSearch: PropTypes.func,
    onClickSave: PropTypes.func,
};

InFilter.defaultProps = {
    wordsSelected: [],
    words: [],
    initialValueSearch: '',
};

export default InFilter;
