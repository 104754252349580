import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { REQUEST_FILTRO } from '../../../requests/graphql';

import { makeStyles } from '@material-ui/core/styles';
import { FiltroConstants, TabelaDadosRules } from '@stt-analise/util';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import _ from 'lodash';
import { aplicarFiltrosDependentes, hasDependsOn, verificarFiltrosPreenchidos } from '../../../util/rules';

const useStyles = makeStyles(() => ({
    button: {
        margin: '8px',
    },
}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export const InAutocompleteWrapper = ({ cubo, metaAtributoSelected, onChangeFilter, filtro, multiple = true, preFiltros }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [requestAutocompletar, { variables: variablesAutocompletar, called: calledAutocompletar, loading: loadingAutocompletar, data: dataAutocompletar, previousData, refetch }] = useLazyQuery(REQUEST_FILTRO, { fetchPolicy: 'cache-and-network', });
    const [cached, setCached] = useState([]);
    const [value, setValue] = useState([]);
    const [filtrosDependentes, setFiltrosDependentes] = useState([...aplicarFiltrosDependentes({ filtro, preFiltros })]);
    const [disabled, setDisabled] = useState(false);
    const [limparCached, setLimparCached] = useState(true);

    function normalizeFiltroValor(filtro) {
        if (multiple) return filtro.valor == '' ? [] : filtro.valor.split(FiltroConstants.SPLIT_VALUES);
        else return filtro.valor;
    }

    function onRequest(valor) {
        const propsFiltroMap = TabelaDadosRules.getPropsFiltroMap(cubo);

        // Apenas para os casos onde existe um autocomplete próprio para o filtro.
        const propsFiltro = propsFiltroMap[filtro?.atributo]
        if (propsFiltro?.autocomplete) {
            setCached(propsFiltro?.autocomplete);
            return;
        }

        const filtrosDependentes_ = aplicarFiltrosDependentes({ filtro, preFiltros });
        requestAutocompletar({
            variables: {
                cubo, atributo: metaAtributoSelected.atributo, valor, filtros: filtrosDependentes_.map(f => ({
                    atributo: f.atributo,
                    tipoComponente: f.tipoComponente,
                    valor: f.valor
                }))
            }, fetchPolicy: 'cache-and-network'
        });
    }

    useEffect(() => {
        // RN - Carregar cache automático se não possuir filtro dependente.
        if (metaAtributoSelected.atributo && !hasDependsOn(filtro.dependsOn)) {
            onRequest();
        }
    }, [metaAtributoSelected]);

    useEffect(() => {
        // RN - Carregar novo valor se houver mudança no filtro dependente.
        if (!_.isEqual(filtrosDependentes, aplicarFiltrosDependentes({ filtro, preFiltros }))) {
            setValue([]);
        }
        setFiltrosDependentes([...aplicarFiltrosDependentes({ filtro, preFiltros })]);

    }, [preFiltros]);

    useEffect(() => {
        // Executar apenas uma vez
        // Regra aplicada apenas para aqueles que não possuem filtro dependente.
        if (calledAutocompletar && !hasDependsOn(filtro.dependsOn)) return;

        if (hasDependsOn(filtro.dependsOn)) {
            setDisabled(!verificarFiltrosPreenchidos({ filtro, preFiltros }));
        }

        if (filtrosDependentes.length === 0) return;

        onRequest();
    }, [filtrosDependentes]);

    useEffect(() => {
        if (dataAutocompletar && dataAutocompletar['iAutocompletar']?.length == 0) return;

        // RN - Se o conteúdo já está no cliente, não atualizar o cached.
        if (dataAutocompletar && variablesAutocompletar['valor'] && dataAutocompletar['iAutocompletar'].length == cached.filter(word => word.toUpperCase().includes(variablesAutocompletar['valor'].toUpperCase())).length) return;

        if (dataAutocompletar && dataAutocompletar['iAutocompletar']) {
            /**
             * RN1 - Se o filtro depende dos demais filtros, não existe cache acumulativo.
             */
            const valid = hasDependsOn(filtro.dependsOn);
            const newCached = valid ? [...dataAutocompletar['iAutocompletar']] : [...cached, ...dataAutocompletar['iAutocompletar']];
            setCached([...new Set(newCached)]);
        }
    }, [dataAutocompletar]);

    /**
     * Regra para os filtros do tipo vínculo (cidade e estado), caso o usuário tenha apenas um vínculo, o filtro deve ser bloqueado.
     * São 3 condições para bloquear campo:
     * 1 - Precisa habilitar o campo habilitarRegraVinculo para o filtro.
     * 2 - Precisa ser a primeira consulta feita; (previousData === undefined)
     * 3 - O tamanho dos elementos resultantes deve conter apenas 1 elemento.
     */
    useEffect(() => {
        if (filtro?.habilitarRegraVinculo && previousData === undefined && dataAutocompletar && dataAutocompletar['iAutocompletar'].length === 1) {
            setDisabled(true);
        }
    }, [loadingAutocompletar]);

    useEffect(() => {
        /**
         * RN1 - Para o caso do usuário possuir apenas uma opção para selecionar.
         * RN2 - Caso ele tiver um filtro dependente e for a primeira consulta.
         */
        const isOnlyOneOption = cached.length === 1;
        if (isOnlyOneOption) {
            onChangeFilter({ ...filtro, valor: cached.join(FiltroConstants.SPLIT_VALUES) });
        } else if (hasDependsOn(filtro.dependsOn) && limparCached) {
            onChangeFilter({ ...filtro, valor: "" });
            setLimparCached(false);
        }
    }, [cached]);

    useEffect(() => {
        const newValue = normalizeFiltroValor(filtro);
        if (value != newValue) setValue(newValue);
    }, [filtro]);

    /**
     * Regra aplicada para desabilitar para os casos na qual só existe uma opção no filtro.
     * 
     * 1. Regra aplicada apenas para os filtros que possuem disabledSelectIfSingleOption=True.
     * 2. Toda vez que altera um valor ou o cached, verificar se o cached é igual ao valor. 
     * Caso for diferente, será necessário atualizar novamente o cached.
     */
    useEffect(() => {
        if (!filtro.disabledSelectIfSingleOption) return;

        const isOnlyOneOption = cached.length === 1;
        if (isOnlyOneOption && cached[0] == value) setDisabled(true); // Caso for único, não deixar alterar.
        else if (isOnlyOneOption && cached[0] != value) onRequest(); // Aplicar novamente o onRequest, para atualizar os estados do componente.
    }, [value, cached]);

    return (
        <>
            {metaAtributoSelected.atributo && (
                <>
                    <Autocomplete
                        multiple={multiple}
                        disabled={disabled}
                        id="asynchronous-demo"
                        // style={{ width: 300 }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        onInputChange={_.debounce((e, valueSearched) => {
                            if (valueSearched === '') return;
                            onRequest(valueSearched);
                        }, 800)}
                        value={value}
                        // defaultValue={value}
                        onChange={(e, newValue) => {
                            const newValue_ = multiple ? newValue.join(FiltroConstants.SPLIT_VALUES) : newValue;
                            onChangeFilter({ ...filtro, valor: newValue_ });
                        }}
                        getOptionSelected={(option, value) => option === value}
                        getOptionLabel={(option) => option || ""}
                        // getOptionDisabled={(value.length > 0 ? true : false)}
                        options={cached}
                        loading={loadingAutocompletar}
                        noOptionsText="Sem Resultados"
                        loadingText="Procurando..."
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={filtro.rotulo || metaAtributoSelected.rotulo || metaAtributoSelected.atributo}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loadingAutocompletar ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </>
            )}
        </>
    );
};
