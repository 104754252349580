const moment = require('moment');
const FiltroConstants = require('../../../constants/FiltroConstants').default;
module.exports = {
  cubo: 'cubo_itinerario_cidades',
  primeiraConsultaAutomatica: true,
  habilitarBotaoExecutar: false,
  atributosSelecionados: ['__time', 'nome_cidade', 'id_estado', 'primeira_coluna_municipios_implantados_situacao_municipio', 'primeira_coluna_municipios_acesso_situacao_municipio', 'qnt' // Sempre colocar o último atributo como métrica.
  ],
  charts: [{
    title: 'Total de municípios',
    type: 'CardAbsoluteChart',
    selectDimensions: ['qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    transformations: []
    // Contagem do número total de municípios em Santa Catarina. Este card inclui o somatório de todos os municípios do estado.
  }, {
    title: 'Total de municípios com pontos implantados',
    type: 'CardAbsoluteChart',
    selectDimensions: ['primeira_coluna_municipios_implantados_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Implantado",
    filtersRemote: [],
    transformations: []
    // Contagem do número total de municípios em Santa Catarina que possuem ao menos 1 ponto (equipamento) ativo. Este card inclui o somatório de todos os municípios do estado com pontos ativos.
  }, {
    title: 'Municípios com ponto implantado',
    type: 'CardPercentageChart',
    selectDimensions: ['primeira_coluna_municipios_implantados_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Implantado",
    filtersRemote: [],
    transformations: []
    // Porcentagem de municípios em Santa Catarina que possuem ao menos 1 ponto (equipamento) ativo. Este card inclui a porcentagem dos municípios do estado com pontos ativos comparados ao número total de municípios.
  }, {
    title: 'Total de municípios com pontos não implantados',
    type: 'CardAbsoluteChart',
    selectDimensions: ['primeira_coluna_municipios_implantados_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Não Implantado",
    filtersRemote: [],
    transformations: []
    // Contagem do número total de municípios em Santa Catarina que não possuem pontos (equipamentos) ativos. Este card inclui o somatório de todos os municípios do estado sem pontos ativos.
  }, {
    title: 'Municípios com ponto não implantado',
    type: 'CardPercentageChart',
    selectDimensions: ['primeira_coluna_municipios_implantados_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Não Implantado",
    filtersRemote: [],
    transformations: []
    // Porcentagem de municípios em Santa Catarina que não possuem pontos (equipamentos) ativos. Este card inclui a porcentagem dos municípios do estado sem pontos ativos comparados ao número total de municípios.
  }, {
    title: 'Total de municípios com pontos ativos',
    type: 'CardAbsoluteChart',
    selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Municípios com acesso",
    filtersRemote: [],
    transformations: []
    // Total de municípios que possuem pelo menos um ponto que enviou um exame nos últimos seis meses.
  }, {
    title: 'Municípios com pontos ativos',
    type: 'CardPercentageChart',
    selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Municípios com acesso",
    filtersRemote: [],
    transformations: []
    // Porcentagem de municípios que possuem pelo menos um ponto que enviou um exame nos últimos seis meses.
  }, {
    title: 'Total de municípios sem acesso',
    type: 'CardAbsoluteChart',
    selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Municípios sem acesso",
    filtersRemote: [],
    transformations: []
    // Total de municípios que não possuem nenhum ponto que enviou um exame nos últimos seis meses.
  }, {
    title: 'Municípios sem acesso',
    type: 'CardPercentageChart',
    selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Municípios sem acesso",
    filtersRemote: [],
    transformations: []
    // Porcentagem de municípios que não possuem nenhum ponto que enviou um exame nos últimos seis meses.
  },
  // {
  //     title: 'Total de municípios com pontos inativos',
  //     type: 'CardAbsoluteChart',
  //     selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
  //     filters: [
  //         {
  //             attribute: 'id_estado',
  //             filter: (value) => value !== '1',
  //         }
  //     ],
  //     filtersRemote: [],
  //     having: "Inativo", // utilizado para esse tipo de gráfico
  //     filtersRemote: [],
  //     transformations: [],
  //     // Total de municípios que não contêm nenhum ponto ativo.
  // },
  // {
  //     title: 'Municípios com pontos inativos',
  //     type: 'CardPercentageChart',
  //     selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
  //     filters: [
  //         {
  //             attribute: 'id_estado',
  //             filter: (value) => value !== '1',
  //         }
  //     ],
  //     filtersRemote: [],
  //     having: "Inativo", // utilizado para esse tipo de gráfico
  //     filtersRemote: [],
  //     transformations: [],
  //     // Porcentagem de municípios que não contêm nenhum ponto ativo.
  // },
  {
    title: 'Total de municípios com pelo menos um ponto bloqueado',
    type: 'CardAbsoluteChart',
    selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Bloqueado",
    filtersRemote: [],
    transformations: []
    // Total de municípios que contêm pelo menos um ponto bloqueado.
  }, {
    title: 'Municípios com pelo menos um ponto bloqueado',
    type: 'CardPercentageChart',
    selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Bloqueado",
    filtersRemote: [],
    transformations: []
    // Porcentagem de municípios que contêm pelo menos um ponto bloqueado.
  }, {
    title: 'Total de municípios com pelo menos um ponto desativado',
    type: 'CardAbsoluteChart',
    selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Desativado",
    filtersRemote: [],
    transformations: []
    // Total de municípios que contêm pelo menos um ponto desativado.
  }, {
    title: 'Municípios com pelo menos um ponto desativado',
    type: 'CardPercentageChart',
    selectDimensions: ['primeira_coluna_municipios_acesso_situacao_municipio', 'qnt'],
    filters: [{
      attribute: 'id_estado',
      filter: value => value !== '1'
    }],
    filtersRemote: [],
    having: "Desativado",
    filtersRemote: [],
    transformations: []
    // Porcentagem de municípios que contêm pelo menos um ponto desativado.
  }],
  metaAtributos: [],
  filtros: []
};