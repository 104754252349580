import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField } from '@material-ui/core';
import { FiltroConstants } from '@stt-analise/util';
import { number } from 'mathjs';

const useStyles = makeStyles(() => ({
    divDataRange: {
        margin: '0px',
        '& input': {
            padding: '10.5px 10.5px!important',
        },
        '& label': {
            transform: 'translate(15px, 12px) scale(1)',
        },
        '& fieldset': {
            borderColor: '#828282',
        },
        '& p img': {
            position: 'relative',
            top: '5px',
        },
    },
    textFieldMin: {
        // width: '100%',
        marginRight: '5px'
    },
    textFieldMax: {
        marginLeft: '5px'
    }
}));

const toValue = (minLimit, maxLimit) => {
    if (minLimit == "" && maxLimit == "") return "";
    return `${minLimit}${FiltroConstants.SPLIT_VALUES}${maxLimit}`;
};

const extract = (value) => {
    const defaultValue = ["", ""];
    if (!value) return defaultValue;
    if (value === "") return defaultValue;

    if (typeof value === 'function') {
        return value();
    }

    let numbers = value;
    if (!Array.isArray(numbers)) numbers = value.split(FiltroConstants.SPLIT_VALUES);

    if (numbers.length === 2) {
        return [numbers[0], numbers[1]];
    } else {
        console.log('Valor incorreto!');
        return defaultValue;
    }
};

const extractMin = (value) => {
    const [min, _] = extract(value);
    return min;
};

const extractMax = (value) => {
    const [_, max] = extract(value);
    return max;
};

const IntegerRange = ({ onChangeFilter = () => { }, filtro, min = 0, max = 100 }) => {
    const classes = useStyles();
    const [minValue, setMinValue] = useState(extractMin(filtro && filtro.valor));
    const [maxValue, setMaxValue] = useState(extractMax(filtro && filtro.valor));

    useEffect(() => {
        onChangeFilter({ ...filtro, valor: toValue(minValue, maxValue) });
    }, [minValue, maxValue])

    return (
        <Box flexDirection="row" >
            <TextField value={minValue} onChange={e => setMinValue(e.target.value)} type="number" InputProps={{
                inputProps: { min }
            }} label="Mínimo" className={classes.textFieldMin} variant="outlined" size="small" />
            <TextField value={maxValue} onChange={e => setMaxValue(e.target.value)} type="number" InputProps={{
                inputProps: { min }
            }} label="Máximo" className={classes.textFieldMax} variant="outlined" size="small" />
        </Box>
    )
};

export default IntegerRange;
