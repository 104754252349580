const moment = require('moment');
const FiltroConstants = require('../../constants/FiltroConstants').default;
module.exports = {
  cubo: 'cubo_dashboard_alofono',
  primeiraConsultaAutomatica: false,
  atributosSelecionados: ['__time', 'data_execucao_teste', 'paciente_nome', 'paciente_cpf', "paciente_uf", "paciente_cidade", "paciente_idade", "paciente_dt_nascimento", 'paciente_sexo', 'snr', 'acertos', 'apresentacoes', 'r_acertos', 'r_apresentacoes', 'medida_contador'],
  charts: [{
    type: 'CardLabels',
    selectDimensions: ['__time', 'paciente_nome', 'paciente_cpf', "paciente_cidade", "paciente_idade", "paciente_dt_nascimento", 'paciente_sexo'],
    filters: [],
    filtersRemote: [],
    transformations: [],
    labels: {
      'paciente_nome': 'Nome',
      'paciente_cpf': 'CPF',
      'paciente_sexo': 'Sexo',
      "paciente_idade": "Idade",
      "paciente_dt_nascimento": "Data de nascimento",
      'paciente_cidade': 'Cidade'
    }
  }, {
    title: '1. Resultado representativo (ou médio) da amostra no teste de dígitos no ruído',
    type: 'Psicometrica',
    selectDimensions: ['__time', 'acertos', 'apresentacoes', 'r_acertos', 'r_apresentacoes'],
    filters: [{
      attribute: 'acertos',
      filter: value => value != 'Não preenchido'
    }],
    filtersRemote: [{
      atributo: 'acertos',
      valor: 'Não preenchido',
      tipoComponente: 'NOT_IN'
    }],
    showLegend: true,
    idRes: 'resTDRGlobal',
    transformations: [],
    agg: "sum"
  }, {
    title: '2. Resultado representativo (ou médio) da amostra ou extratos da amostra no teste de dígitos no ruído (orelha esquerda)',
    type: 'Psicometrica',
    selectDimensions: ['data_execucao_teste', 'acertos', 'apresentacoes'],
    filters: [{
      attribute: 'acertos',
      filter: value => value != 'Não preenchido'
    }],
    filtersRemote: [{
      atributo: 'acertos',
      valor: 'Não preenchido',
      tipoComponente: 'NOT_IN'
    }],
    showLegend: true,
    transformations: [{
      attributes: ['data_execucao_teste'],
      transformation: value => moment(value).format('HH:mm DD/MM/YY')
    }]
  }, {
    title: '3. Resultado representativo (ou médio) da amostra ou extratos da amostra no teste de dígitos no ruído (orelha direita)',
    type: 'Psicometrica',
    selectDimensions: ['data_execucao_teste', 'r_acertos', 'r_apresentacoes'],
    filters: [{
      attribute: 'r_acertos',
      filter: value => value != 'Não preenchido'
    }],
    filtersRemote: [{
      atributo: 'r_acertos',
      valor: 'Não preenchido',
      tipoComponente: 'NOT_IN'
    }],
    showLegend: true,
    transformations: [{
      attributes: ['data_execucao_teste'],
      transformation: value => moment(value).format('HH:mm DD/MM/YY')
    }]
  }],
  resultCharts: [{
    type: 'Resultado' // necessário idRes = ['resTDRGlobal']
  }],
  metaAtributos: [{
    atributo: '__time',
    grupo: '0.0 - Variáveis gerais',
    key: 'cubo_dashboard_alofono_variaveis_gerais___time',
    rotulo: 'Data da execução dos testes',
    tipo: 'DATA_HORA'
  }, {
    atributo: 'data_atendimento',
    grupo: '0.0 - Variáveis gerais',
    key: 'cubo_dashboard_alofono_variaveis_gerais_data_atendimento',
    rotulo: 'Data do atendimento',
    tipo: 'TIMESTAMP_MS'
  }, {
    atributo: 'paciente_nome',
    grupo: '1.0 - Paciente',
    key: 'cubo_dashboard_alofono_paciente_nome',
    rotulo: 'Paciente: nome',
    tipo: 'TEXTO'
  }, {
    atributo: 'paciente_cpf',
    grupo: '1.0 - Paciente',
    key: 'cubo_dashboard_alofono_paciente_cpf',
    rotulo: 'Paciente: CPF',
    tipo: 'TEXTO'
  }],
  filtros: [{
    key: 2,
    disabled: true,
    atributo: 'paciente_nome',
    rotulo: 'Nome',
    tipoComponente: 'IN_SELECT',
    multiple: false,
    valor: ''
  }, {
    key: 3,
    disabled: true,
    atributo: 'paciente_cpf',
    rotulo: 'CPF',
    tipoComponente: 'IN_SELECT',
    multiple: false,
    valor: ''
  }, {
    key: 1,
    disabled: false,
    atributosPermitidos: ['__time'],
    atributo: '__time',
    tipoComponente: 'BETWEEN_INCLUSIVE_TIMESTAMP_MS',
    valor: () => {
      return [null, null];
    }
  }]
};