import React, { useState, useEffect } from 'react';

// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './customStyle.css';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Button, Container, Grid, Paper, TextField, ThemeProvider } from '@material-ui/core';
import CheckboxTree from 'react-checkbox-tree';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Normalize } from '@stt-analise/util';

const useStyles = makeStyles((theme) => ({
    paper: {
        minWidth: 330,
        height: 260,
        margin: 'auto',
        padding: 10,
        overflow: 'auto',
        backgroundColor: '#f7f7f7',
    },
    button: {
        // margin: theme.spacing(0.5, 0),
    },
}));


const filterNodes = (filterText) => (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes(filterText), []);
    const len = children.length;

    const filterTextNorm = Normalize.formatTreeSelect(filterText);
    if (
        Normalize.formatTreeSelect(node.label)?.indexOf(filterTextNorm) > -1 ||
        // Node's label matches the search string
        Normalize.formatTreeSelect(node.value)?.indexOf(filterTextNorm) > -1 ||
        // Or a children has a matching node
        len
    ) {
        filtered.push({ ...node, children: len === 0 ? undefined : children });
    }

    return filtered;
};

export default function CheckboxTreePanel({ nodes = [], checked, setChecked, confirmButtonListener }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [nodesFiltered, setNodesFiltered] = useState(nodes);

    useEffect(() => {
        if (filterText === '')
            setNodesFiltered(nodes);
        else {
            setNodesFiltered(nodes.reduce(filterNodes(filterText), []));
        }
    }, [filterText])

    return (
        <Container maxWidth="md">
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1} >
                <Grid item>
                    <TextField
                        id="outlined-basic"
                        label="Pesquisar"
                        variant="outlined"
                        size="small"
                        value={filterText}
                        onChange={(e) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{ width: '100%' }}>
                    <Paper className={classes.paper} elevation={1}>
                        <CheckboxTree
                            nodes={nodesFiltered}
                            checked={checked}
                            expanded={expanded}
                            onCheck={(__, { isParent, checked: _checked, children, value }) => {
                                let listChecked = [];
                                if (isParent) {
                                    listChecked = children.map(e => e.value);
                                } else {
                                    listChecked = [value];
                                }
                                if (!_checked) {
                                    listChecked = checked.filter(e => {
                                        return !listChecked.includes(e);
                                    });
                                } else {
                                    listChecked = _.uniq([...checked, ...listChecked]);
                                }

                                setChecked(listChecked);
                            }}
                            onExpand={(expanded) => setExpanded(expanded)}
                            showNodeIcon={false}
                            icons={{
                                check: <CheckBoxIcon style={{ fontSize: 20 }} />,
                                uncheck: <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />,
                                halfCheck: <IndeterminateCheckBoxIcon style={{ fontSize: 20 }} />,
                                expandClose: <ExpandMoreIcon style={{ fontSize: 24 }} />,
                                expandOpen: <ExpandLessIcon style={{ fontSize: 24 }} />,
                                expandAll: <AddIcon style={{ fontSize: 24 }} />,
                                collapseAll: <RemoveIcon style={{ fontSize: 24 }} />,
                                // parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon="folder" />,
                                // parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon="folder-open" />,
                                leaf: <InsertDriveFileIcon />
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={confirmButtonListener}
                    >
                        Ok
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}
