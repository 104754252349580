import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    mapContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: -theme.spacing(1) * 3,
    },
    mapContainerPivottable: {
        position: 'relative',
        height: '600px',
        width: '600px',
    },
    iFrameMapContainer: {
        border: 0,
        minHeight: '29vw',
    },
}));
