var TipoVinculo;
(function (TipoVinculo) {
  TipoVinculo["ADMIN"] = "G";
  TipoVinculo["MUNICIPIO"] = "M";
  TipoVinculo["ESTADO"] = "E";
  TipoVinculo["REDE_TELECONSULTORIA"] = "D";
  TipoVinculo["REDE_TELEMEDICINA"] = "R";
  TipoVinculo["INSTITUICAO"] = "I";
  TipoVinculo["USUARIO"] = "U";
})(TipoVinculo || (TipoVinculo = {}));
export default TipoVinculo;