module.exports = {
  abrir: 'Abrir',
  abrirNovaAba: 'Abrir anexo em nova aba',
  aguardeSalaVideo: 'Aguarde enquanto configuramos a sala de atendimento...',
  anexar: 'Anexar',
  anexarArquivo: 'Anexar arquivos',
  anexos: 'Anexos',
  atendimentoEncerrado: 'Atendimento Encerrado!',
  atendimentoFinalizado: 'Atendimento finalizado com sucesso',
  atrasado: 'O tempo de acesso a este teleatendimento expirou. Por favor, entre em contato com a administração para mais informações.',
  buscarAnexo: 'Buscar',
  chamadaVideo: 'Iniciar atendimento por vídeo',
  chat: 'Teleatendimento',
  chegouCedo: 'Parece que você chegou cedo. Aguarde o horário do seu atendimento. A sala de atendimento só ficará disponível 15 minutos antes do horário agendado.',
  confirmarFinalizacao: 'Você confirma o encerramento deste atendimento? Ao encerrar o atendimento esta sala de áudio e vídeo ficará indisponível para uso posterior.',
  digiteMensagem: 'Digite sua mensagem...',
  enviandoArquivo: 'Enviando arquivo...',
  enviarMensagem: 'Enviar mensagem',
  erroEnvioArquivo: 'Ocorreu um erro no envio do anexo. Por favor, tente novamente mais tarde.',
  erroToken: 'Ocorreu um erro na validação do seu token de acesso. Por favor, verifique se o token informado está correto.',
  fecharChamadaVideo: 'Fechar atendimento por vídeo',
  finalizarAtendimento: 'Finalizar atendimento',
  formatoData: 'DD/MM/YYYY',
  historicoChat: 'Histórico do chat',
  inserirAnexo: 'Inserir anexo',
  jaOnline: 'Você já está conectado no módulo de Teleatendimento através de outra aba, navegador ou dispotivo.',
  mensagemAnexo: 'Clique no botão ao lado para inserir um novo anexo',
  mensagemEncerrado: 'Este teleatendimento foi encerrado e por isso não é mais possível realizar conversas por texto e chamadas de áudio e vídeo.',
  mensagemHistorico: 'Você ainda tem acesso ao histórico da(s) conversa(s) por texto e a lista de anexos enviados.',
  naoAutorizado: 'Não autorizado',
  nenhumAnexo: 'Nenhum anexo encontrado',
  observacao: 'Digite uma observação',
  selecionarEmoji: 'Selecionar emoji',
  sucessoEnvioAnexo: 'Anexo enviado com sucesso.',
  textoAguarde: 'Aguarde, em breve você será atendido.',
  textoAtendimentoFinalizado: 'O atendente encerrou este atendimento.',
  textoConectado: 'entrou na sala.',
  textoDesconectado: 'se desconectou da sala.',
  tituloJanela: 'Janela de teleatendimento'
};