import React, { useState, useEffect, useContext } from 'react';
import { AlofonoSpec } from '@stt-analise/util';
import { SttTranslateHook } from '@stt-componentes/core';
import Dashboard from '../../../components/Dashboard/Dashboard';

const configFiltroSecundario = AlofonoSpec;

/**
 * RN - Regra de negócio para quando habilitar o filtro.
 */
const validarFiltros = (filtros) => {
    return (
        filtros.filter(
            (filtro) =>
                filtro.valor != '' ||
                filtro.valor != undefined || // se for admin, não precisa adicionar um filtro
                (typeof filtro.valor !== 'string' && !filtro.valor.includes('null'))
        ).length > 0
    );
};

function AlofonoDashboard() {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [filtrosSecundarios, setFiltrosSecundarios] = useState(configFiltroSecundario.filtros);
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        const valid = validarFiltros(filtrosSecundarios);
        setDisabledButton(!valid);
    }, [filtrosSecundarios]);

    return (
        <Dashboard
            title={strings.PaginaInicial.dashboardAlofono}
            configFiltroSecundario={configFiltroSecundario}
            disabledButton={disabledButton}
            filtrosSecundarios={filtrosSecundarios}
            setFiltrosSecundarios={setFiltrosSecundarios}
        />
    );
}

export default AlofonoDashboard;
