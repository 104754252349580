module.exports = {
  abrir: 'Abrir',
  abrirNovaAba: 'Abrir anexo em nova aba',
  achados: 'Achados',
  adicionarNovosAnexos: 'CLIQUE AQUI para adicionar novas imagens ao exame.',
  agendado: 'Agendado',
  alteracaoAntecedente: 'Alteração de antecendente',
  anexarArquivo: 'Anexar arquivos',
  anexos: 'Anexos',
  anexosInseridosSucesso: 'Anexos inseridos com sucesso.',
  antecedente: 'Antecedente',
  antecedenteDuplicado: 'Já existe outro antecedente com a mesma descrição informada.',
  antecedenteObrigatorio: 'É obrigado a informar ao menos um antecedente',
  antecedentes: 'Antecedentes',
  antecedentesTitulo: 'Antecedentes',
  assinando: 'Aguarde - assinando digitalmente o laudo...',
  ausente: 'Ausente',
  avaliar: 'Avaliar',
  BuscaFuncionario: {},
  Cabecalho: {
    modulo: 'Eletroencefalografia'
  },
  cadastroAntecedente: 'Cadastro de novo antecedente',
  carregandoExame: 'Carregando exame',
  categoria: 'Categoria',
  cidadeProcedencia: 'Município de procedência',
  comorbidadesTitulo: 'Comorbidades',
  conclusao: 'Conclusão',
  concordoP: 'Concordo parcialmente',
  concordoT: 'Concordo totalmente',
  confirmando: 'Confirmando laudo',
  confirmarAlteracaoAntecedente: 'Confirma a alteração do antecedente?',
  confirmarInsercaoAntecedente: 'Confirma a inserção do novo antecedente?',
  controleTratamento: 'Controle de tratamento',
  dadosInvalidos: 'Dados inválidos. Verifique os dados informados e tente novamente.',
  dataHoraAtendimento: 'Data/hora do atendimento',
  dataHoraTeste: 'Data/hora do teste',
  dermatose: 'Dermatose',
  descricaoEstudo: 'Descrição do estudo',
  descricaoExame: 'Descrição do exame',
  descritores: 'Descritores',
  direcionados: 'Direcionado ao meu usuário',
  discordoP: 'Discordo parcialmente',
  discordoT: 'Discordo totalmente',
  distribuicao: 'Distribuição',
  emailInvalido: 'Email inválido',
  enviar: 'Enviar',
  erroAssinatura: 'Erro ao assinar digitalmente o laudo',
  erroGerarTermo: 'Não foi possível gerar o termo de autorização. Tente imprimir o termo através da tela de exames.',
  Erros: {
    naoAutorizado: 'Não autorizado'
  },
  erroSortearExame: 'Não foi possível sortear o próximo exame para laudo. Tente novamente e se o problema persistir, entre em contato com os administradores.',
  especificacaoTempo: 'Especificação',
  exameMaisAntigoSemLaudo: 'Data do exame mais antigo sem laudo',
  examesAgendados: 'Agendados',
  examesComImagens: 'Com imagens',
  exameSelecionado: 'Exame selecionado',
  exameSemImagens: 'Este exame não possui imagens anexadas até o momento',
  examesInvalidos: 'Inválidos',
  examesPacs: 'PACS',
  examesSelecionados: 'Exames selecionados',
  examesValidos: 'Válidos',
  exFumante: 'Ex-fumante',
  fumante: 'Fumante',
  gerandoResumo: 'Gerando resumo do laudo',
  historiaClinica: 'História clínica',
  ignorandoExame: 'Ignorando exame',
  imagemInvalida: 'O campo deve conter uma imagem do tipo JPG',
  imformacoesCompl: 'Informações complementares',
  inclusaoImagemOpcional: 'A inclusão de imagens no envio de exames de Eletroencefalogria são opcionais.',
  inclusaoNovosAnexos: 'Inclusão de novas imagens',
  indicacaoExame: 'Indicacao do exame',
  infoClinicas: 'Informações clínicas',
  instituicaoSolicitante: 'Instituição solicitante',
  invalido: 'Inválido',
  investigacaoDiagnostica: 'Investigação diagnóstica',
  laudoAssinado: 'Laudo assinado digitalmente com sucesso',
  laudoEmEmissao: 'Devido à demora na emissão do laudo, este exame foi acessado por outro profissional. O próximo exame será carregado.',
  laudosEmitidos: 'Laudos emitidos',
  laudoTextual: 'Laudo textual',
  local: 'Local',
  localizacao: 'Localização',
  logradouro: 'Logradouro',
  medicamentosEmUso: 'Medicamentos em uso',
  medicamentosUso: 'Medicamentos em uso',
  medicoExecutor: 'Médico executor',
  mensagemAlertaCancelamento: 'Você confirma o cancelamento do envio de imagens?',
  mensagemConfirmarEnvioExame: 'Deseja enviar as imagens do exame agora?',
  mensagemConfirmarTermo: 'Deseja imprimir o TCLE?',
  modelosLaudoUsuario: 'Modelo de laudo',
  motivoExame: 'Motivo do exame',
  naoFoiPosisvelCarregarExamesInvalidos: 'Não foi possível carregar os dados de exames inválidos',
  naoFoiPossivelCarregarExamesComLaudo: 'Não foi possível carregar os dados de exames laudados',
  naoFoiPossivelCarregarLaudosEmitidos: 'Não foi possível carregar os dados de laudos emitidos',
  naoFoiPossivelCarregarSemLaudo: 'Não foi possível carregar os dados de exames sem laudo',
  naoFoiPossivelCarregarSemLaudo72h: 'Não foi possível carregar os dados de exames com laudo atrasado',
  naoFoiPossivelCarregarTotais: 'Não foi possível carregar os dados de produtividade',
  nenhumAnexo: 'Nenhum anexo encontrado',
  nenhummExameParaLaudo: 'Nenhum exame disponível para laudo',
  noaExisteEquipamento: 'A instituição do exame não possui nenhum ponto cadastrado. Entre em contato com os administradores.',
  notificacaoAntecedentes: 'Cadastra um antecedente que será utilizado para especificar o(s) antecendentes(s) que o paciente possui na tela de solicitação de exames',
  notificacaoComorbidades: 'Cadastra uma comorbidade que será utilizada para especificar a(s) comorbidade(s) que o paciente possui na tela de solicitação de exames.',
  novaRede: 'Nova rede',
  numeroExame: 'Número do exame',
  ocupacional: 'Ocupacional',
  opcoes: 'Opções',
  origem: 'Origem',
  outrasInformacoes: 'Outras informações',
  outrasInfosPert: 'Outras informações pertinentes',
  pacs: 'PACS',
  pesquisar: 'Pesquisar',
  previewImagem: 'Visualização da imagem',
  proximoExame: 'Próximo exame',
  publicar: 'Publicar',
  qualquerTermo: 'Qualquer um dos termos',
  redeAlterada: 'Rede alterada',
  remover: 'Remover',
  responsavelEnvio: 'Responsável pelo envio',
  resultadoExames: 'Resultado de exames anteriores',
  resultadosExamesAnteriores: 'Resultado de exames anteriores',
  resumoHistoria: 'Resumo da história clínica',
  resumoLaudo: 'Resumo do laudo',
  salvandoAntecedente: 'Salvando antecedente',
  solicitacaoRegistrada: 'Solicitação registrada',
  subcategoria: 'Subcategoria',
  termosDescricao: 'Termos da descrição do exame',
  teste: 'Teste',
  tipo: 'Tipo',
  tipoContato: 'Tipo de contato',
  tipoExame: 'Tipo de exame',
  titulo: 'Envio de imagens',
  tituloConfirmarEnvioExame: 'Deseja enviar as imagens?',
  tituloModalCancelamento: 'Confirmação de cancelamento',
  todasImagens: 'Todas as imagens',
  todosTermos: 'Todos os termos',
  trocarRede: 'Trocar rede',
  ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
  valido: 'Válido'
};