import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { SttButton } from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -7,
    },
    wrapper: {
        margin: 0,
        display: 'inline',
        position: 'relative',
    },
}));

const ButtonLoading = ({ loading, disabled, onClick = () => { }, children }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <SttButton disabled={disabled || loading} variant="contained" color="primary" onClick={onClick}>
                {children}
            </SttButton>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
};

export default ButtonLoading;
