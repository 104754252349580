import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel } from '@material-ui/core';
import { SttTranslateHook } from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
        '& > * + *': {
            //   marginTop: theme.spacing(3),
        },
    },
}));

const ExportContainer = ({ onClickExport, possuiDados = false, pdfPermitido = false }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [value, setValue] = React.useState('xlsx');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item xs={12}>
                <Grid container direction="column" alignItems="center">
                    <FormControl component="fieldset">
                        <FormLabel style={{ zIndex: 1 }} component="legend">
                            {strings.Comuns.tipoArquivo}
                        </FormLabel>
                        <RadioGroup row aria-label="tipo" name="tipo1" value={value} onChange={handleChange}>
                            <FormControlLabel value="csv" control={<Radio />} label="csv" />
                            <FormControlLabel value="xlsx" control={<Radio />} label="xlsx" />
                            {possuiDados && pdfPermitido && <FormControlLabel value="pdf" control={<Radio />} label="pdf" />}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Button variant="contained" color="primary" onClick={() => onClickExport(value)}>
                        {strings.Comuns.exportar}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ExportContainer;
